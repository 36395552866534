import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Spin, Table } from "antd";
import moment from "moment";
import { CloseCircleOutlined, LinkOutlined } from "@ant-design/icons";
import axios from "axios";

import mbImage from "../../../assets/icons/MB.svg";
import mbWayImage from "../../../assets/icons/MB-Way.svg";
import bankImage from "../../../assets/icons/Bank.svg";
import "./QuotesModal.css";
import ModalUserCancelPayment from "../ModalUserCancelPayment/ModalUserCancelPayment";
import endpoints from "../../../utils/endoinpts";
import ModalCancelDebit from "../ModalCancelDebit/ModalCancelDebit";

const columns = [
  {
    title: "Data",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Método",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    title: "Desrição",
    dataIndex: "invoice_item_description",
    key: "invoice_item_description",
  },
  {
    title: "Valor",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Tipo",
    dataIndex: "payment_plan_type",
    key: "payment_plan_type",
  },
  {
    title: "Data de pagamento",
    dataIndex: "payment_date",
    key: "payment_date",
  },
  {
    title: "Expira",
    dataIndex: "payment_valid_date",
    key: "payment_valid_date",
  },
  {
    title: "Estado",
    dataIndex: "statusHTML",
    key: "statusHTML",
  },
  {
    title: "Fatura",
    dataIndex: "invoice_link",
    key: "invoice_link",
  },
  {
    title: "",
    dataIndex: "cancel",
    key: "cancel",
  },
];

function QuotesModal({ show, close, payments, user, handleUpdateUserInfo }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);
  const [isOpenModalCancelDebit, setIsOpenModalCancelDebit] = useState(false);
  const [paymentsTable, setPaymentsTable] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let aux = [];
    for (let i = 0; i < payments.length; i++) {
      let invoice_items_json = JSON.parse(payments[i].invoice_items);
      let auxObject = {
        date: moment(payments[i].date).format("DD-MM-YYYY"),
        payment_method: (
          <div className="payment_method">
            <img
              src={
                payments[i].method === "mb"
                  ? mbImage
                  : payments[i].method === "mbway"
                  ? mbWayImage
                  : bankImage
              }
            />
          </div>
        ),
        payment_date: payments[i].payment_date
          ? moment(payments[i].payment_date).format("YYYY-MM-DD")
          : "-",
        payment_valid_date: payments[i].payment_valid_date
          ? moment(payments[i].payment_valid_date).format("YYYY-MM-DD")
          : "-",
        statusHTML: (
          <div
            className={`status ${
              payments[i].status === "em validação"
                ? "processing"
                : payments[i].status
            }`}
          >
            {payments[i].status}
          </div>
        ),
        value: payments[i].value + "€",
        payment_plan_type: payments[i].payment_plan_type,
        invoice_item_description: invoice_items_json[0].description,
        invoice_link: payments[i].invoice_link ? (
          <Button target="_blank" href={payments[i].invoice_link}>
            <LinkOutlined />
          </Button>
        ) : (
          "-"
        ),
        description: payments[i].description,
        cancel:
          payments[i].status !== "pago" &&
          payments[i].status !== "cancelado" ? (
            <Button
              danger
              type="primary"
              onClick={() => handleOpenModalCancel(payments[i])}
            >
              <CloseCircleOutlined />
            </Button>
          ) : null,
      };

      aux.push(auxObject);
    }

    setPaymentsTable(aux);
    setIsLoading(false);
  }, [payments]);

  function handleOpenModalCancel(data) {
    setSelectedPayment(data);
    if (data.method === "debit") {
      setIsOpenModalCancelDebit(true);
    } else {
      setIsOpenModalCancel(true);
    }
  }

  function handleCloseModalCancel() {
    setSelectedPayment({});
    setIsOpenModalCancel(false);
    handleUpdatePayments();
  }

  function handleCloseModalCancelDebit() {
    setSelectedPayment({});
    setIsOpenModalCancelDebit(false);
    handleUpdatePayments();
  }

  function handleCancelPayment() {
    axios
      .post(endpoints.purchase.paymentUserCancel, {
        data: selectedPayment,
      })
      .then((res) => {
        setSelectedPayment({});
        setIsOpenModalCancel(false);
        handleUpdateUserInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Modal
        className="quotes_modal"
        open={show}
        onCancel={close}
        closable={true}
        maskClosable={false}
        footer={[]}
      >
        <ModalUserCancelPayment
          open={isOpenModalCancel}
          close={handleCloseModalCancel}
          handleSubmit={handleCancelPayment}
        />
        <ModalCancelDebit
          user={user}
          open={isOpenModalCancelDebit}
          close={handleCloseModalCancelDebit}
        />
        <Row>
          <Col span={24}>
            <Spin spinning={isLoading}>
              <p className="title">Pagamento de quotas</p>
              <p className="user_name">{user?.name}</p>
              {!isLoading && (
                <Table dataSource={paymentsTable} columns={columns} />
              )}
            </Spin>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default QuotesModal;
