import { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  notification,
  Input,
  Button,
  Divider,
  Spin,
  Tabs,
} from "antd";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";
import endpoints from "../../../utils/endoinpts";
import "./Settings.css";
import SettingsForm from "./SettingsForm";
import SettingsQuotesRegularization from "./SettingsQuotesRegularization";
import SettingsUnits from "./SettingsUnits";
import SettingsQuotes from "./SettingsQuotes";

function Settings() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);

  useEffect(() => {
    handleGetConfigs();
  }, []);

  function handleGetConfigs() {
    let auxObject = {};

    axios
      .get(endpoints.info.getConfigs)
      .then((res) => {
        setData(res.data[0]);
        setUnitsData({ ars: res.data[1], aces: res.data[2], usf: res.data[3] });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Row className="w-100 dashboard mt-20">
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Row className="settings_content">
            <Col span={24}>
              <Tabs
                tabPosition={"left"}
                items={[
                  {
                    label: "Formulário Pareceres",
                    key: "form_department",
                    children: (
                      <SettingsForm
                        data={
                          data.filter(
                            (item) => item.type === "form_department"
                          )[0]
                        }
                      />
                    ),
                  },
                  {
                    label: "Quotas",
                    key: "quotes",
                    children: <SettingsQuotes />,
                  },
                  {
                    label: "Regularização de Quotas",
                    key: "quotes_regularization",
                    children: (
                      <SettingsQuotesRegularization
                        data={
                          data.filter(
                            (item) => item.type === "quotes_regularization"
                          )[0]
                        }
                      />
                    ),
                  },
                  {
                    label: "Unidades",
                    key: "units",
                    children: <SettingsUnits data={unitsData} />,
                  },
                ]}
              />
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

export default Settings;
