import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Upload,
  Form,
  Input,
  Select,
  InputNumber,
  Divider,
  Button,
  DatePicker,
  Drawer,
  Switch,
  notification,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import axios from "axios";
import ReactQuill from "react-quill";

import { ReactComponent as IconUpload } from "../../../../assets/icons/svg-upload.svg";

import config from "../../../../utils/config";
import endpoints from "../../../../utils/endoinpts";

import "react-quill/dist/quill.snow.css";
import "./Create.css";

const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const defaultFormList = { price: [{}], options: [{}] };

export default function Create({ open, close }) {
  const [form] = Form.useForm();

  const [professions, setProfessions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileListPDF, setFileListPDF] = useState([
    { uid: "not_new", name: "Ficheiro exemplo.pdf" },
  ]);
  const [filePreview, setFilePreview] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    if (open) {
      handleGetData();
    }
  }, [open]);

  const uploadProps = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      handlePreview(file);
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: [],
  };

  /* Upload setup for pdf files */
  const props = {
    accept: "application/pdf",
    onRemove: (file) => {
      const index = fileListPDF.indexOf(file);
      const newFileListPDF = fileListPDF.slice();
      newFileListPDF.splice(index, 1);
      setFileListPDF(newFileListPDF);
    },
    beforeUpload: (file) => {
      setFileListPDF([file]);
      return false;
    },
    fileList: fileListPDF,
    defaultFileList: [],
  };

  async function handlePreview(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleGetData() {
    axios
      .get(endpoints.professions.read)
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          setProfessions(res.data);
        }
      })
      .catch((error) => console.error(error));
  }

  function handleSubmitEvent(values) {
    setIsButtonLoading(true);

    const formData = new FormData();
    values.institutional_incentives = values.institutional_incentives ? 1 : 0;
    values.is_active = values.is_active ? 1 : 0;
    values.promotion = values.promotion ? 1 : 0;

    fileList.forEach((file) => {
      formData.append("file", file);
    });

    fileListPDF.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file_pdf", file);
      }
    });

    formData.append("data", JSON.stringify(values));
    formData.append("professions", JSON.stringify(professions));

    fetch(`${config.server_ip}${endpoints.event.create}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        setTimeout(() => {
          close();
          setFileList([]);
          setFileListPDF([]);
          setFilePreview("");
          setIsButtonLoading(false);
          form.resetFields();
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
      });
  }

  function handleCloseDrawer() {
    close();
    form.resetFields();
    setFileList([]);
    setFileListPDF([]);
    setFilePreview("");
    setIsButtonLoading(false);
  }

  function handleAddRow(add) {
    let professions = form.getFieldValue("price");
    if (professions[professions.length - 1].id_profession) {
      add({ id_profession: null, price: null, quantity: null });
    } else {
      notification.info({
        message: "Falta profissão",
        description: `Para adicionar mais 1 profissão tem que selecionar a anterior`,
      });
    }
  }

  return (
    <Drawer
      title={`Adicionar evento`}
      width={1400}
      onClose={handleCloseDrawer}
      open={open}
      maskClosable={false}
    >
      <Row id="admin-create-event" className="dash_card" gutter={24}>
        <Col span={24}>
          <p className="dash_text bold">Faça upload da sua imagem</p>
          <p className="dash_text gray">Tamanho máximo de upload (2 MB)</p>
          <Row className="ml-0 mr-0 mt-20">
            <Col span={7}>
              <Dragger
                {...uploadProps}
                className={"dragger" + (filePreview ? " hasBgImage" : "")}
                style={{
                  backgroundImage: filePreview && `url(${filePreview})`,
                }}
              >
                <p className="ant-upload-drag-icon">
                  <IconUpload className="icon icon-upload" />
                </p>
                <p className="ant-upload-text text dash_text bold">
                  Upload da imagem
                </p>
                <p className="ant-upload-hint">Faça Drag and drop aqui</p>
              </Dragger>
            </Col>
            <Col span={17}>
              <Form
                form={form}
                id="form-create-event"
                onFinish={handleSubmitEvent}
                initialValues={defaultFormList}
                className="form"
                autoComplete="off"
              >
                <Row gutter={[24]}>
                  <Col span={16}>
                    <Form.Item
                      name="name"
                      labelCol={{ span: 24 }}
                      label="Título"
                      rules={[
                        {
                          required: true,
                          message: "Insira um título para o evento",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Título do Evento" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="institutional_incentives"
                      labelCol={{ span: 24 }}
                      label="Incentivos Institucionais"
                      valuePropName="checked"
                    >
                      <Switch size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      name="is_active"
                      labelCol={{ span: 24 }}
                      label="Ativo"
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Switch defaultChecked size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24]}>
                  <Col span={16}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      name="date"
                      label="Data ínicio - Data fim"
                      rules={[
                        {
                          required: true,
                          message: "Selecione um horário para o evento",
                        },
                      ]}
                    >
                      <RangePicker
                        size="large"
                        showTime={{ format: "HH:mm" }}
                        format="YYYY-MM-DD HH:mm"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      name="enroll_date_limit"
                      label="Data limite de inscrição"
                      rules={[
                        {
                          required: true,
                          message: "Selecione uma data limite de inscrição",
                        },
                      ]}
                    >
                      <DatePicker size="large" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Insira uma breve descrição para o evento",
                    },
                  ]}
                >
                  <ReactQuill theme="snow" placeholder="Descrição do evento" />
                </Form.Item>
                <Divider dashed className="bar" />
                <Form.List name="price">
                  {(fields, { add, remove }) => (
                    <Row gutter={[24]}>
                      <Col span={5}>
                        <Button size="large" onClick={() => handleAddRow(add)}>
                          <PlusOutlined /> Adicionar
                        </Button>
                      </Col>
                      <Col span={19} className="form_list_prices">
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row
                            key={key}
                            gutter={[24]}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                          >
                            <Col span={23}>
                              <Row gutter={[24]}>
                                <Col span={11}>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.price !== currentValues.price
                                    }
                                  >
                                    {({ getFieldValue }) => (
                                      <Form.Item
                                        {...restField}
                                        name={[name, "id_profession"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Selecione uma opção",
                                          },
                                        ]}
                                      >
                                        <Select
                                          size="large"
                                          showSearch
                                          placeholder="Selecione uma profissão"
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            (option?.label ?? "")
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                          options={professions?.map(
                                            (item, index) => ({
                                              value: item.id,
                                              label: item.name,
                                              disabled:
                                                getFieldValue("price").filter(
                                                  (c) =>
                                                    c.id_profession === item.id
                                                ).length > 0,
                                            })
                                          )}
                                          getPopupContainer={(trigger) =>
                                            trigger.parentNode
                                          }
                                        />
                                      </Form.Item>
                                    )}
                                  </Form.Item>
                                </Col>
                                <Col span={7} align="center" justify="center">
                                  <Form.Item
                                    {...restField}
                                    label="Preço"
                                    name={[name, "price"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Insira um preço",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      size="large"
                                      min={0}
                                      placeholder="0,00 €"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6} align="end">
                                  <Form.Item
                                    {...restField}
                                    label="Qtd"
                                    name={[name, "quantity"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Insira uma quantidade",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      size="large"
                                      min={0}
                                      placeholder="0"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1} className="icon_delete_div">
                              <DeleteOutlined
                                className={`icon icon-delete ${
                                  fields.length === 1 ? "disabled" : ""
                                }`}
                                disabled={fields.length === 1}
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )}
                </Form.List>

                <Row className="mt-20">
                  <Col span={5}></Col>
                  <Col span={19} className="form_list_prices">
                    <Row>
                      <Col span={23} className="d-flex jc-end">
                        <Form.Item
                          label="Máx. inscritos"
                          name={"total_professionals"}
                        >
                          <InputNumber size="large" min={0} placeholder="0" />
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                    </Row>
                  </Col>
                </Row>
                <Divider dashed className="bar" />
                <Form.List name="options">
                  {(fields, { add, remove }) => (
                    <Row gutter={[24]}>
                      <Col span={5}>
                        <Button size="large" onClick={() => add()}>
                          <PlusOutlined /> Adicionar
                        </Button>
                      </Col>
                      <Col span={19} className="form_list_options">
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            gutter={[24]}
                          >
                            <Col span={23}>
                              <Row gutter={[24]}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "name"]}
                                  >
                                    <Input
                                      size="large"
                                      placeholder="Coloque aqui as opções"
                                    />
                                  </Form.Item>
                                </Col>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.options !== currentValues.options
                                  }
                                >
                                  {({ getFieldValue }) => (
                                    <>
                                      <Col span={7} align="center">
                                        <Form.Item
                                          {...restField}
                                          label="Preço"
                                          name={[name, "price"]}
                                          rules={[
                                            {
                                              required:
                                                getFieldValue("options")[
                                                  name
                                                ] &&
                                                getFieldValue("options")[name]
                                                  .name &&
                                                getFieldValue("options")[name]
                                                  .name.length > 0,
                                              message: "Insira uma data",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            size="large"
                                            min={0}
                                            placeholder="0,00 €"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={6} align="end">
                                        <Form.Item
                                          {...restField}
                                          label="Qtd"
                                          name={[name, "quantity"]}
                                        >
                                          <InputNumber
                                            size="large"
                                            min={0}
                                            placeholder="0"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                </Form.Item>
                              </Row>
                            </Col>

                            <Col span={1} className="icon_delete_div">
                              <DeleteOutlined
                                className={`icon icon-delete ${
                                  fields.length === 1 ? "disabled" : ""
                                }`}
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )}
                </Form.List>
                <Divider dashed className="bar" />
                <Row gutter={[24]}>
                  <Col
                    span={5}
                    className="form_list_options d-flex justify-content-center align-items-center"
                  >
                    <Form.Item
                      label="Promoção"
                      name="promotion"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch size="large" />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.promotion !== currentValues.promotion
                    }
                  >
                    {({ getFieldValue }) => (
                      <>
                        <Col span={14} className="form_list_options">
                          <Form.Item
                            label="Data"
                            name="promotion_date"
                            rules={[
                              {
                                required: getFieldValue("promotion"),
                                message: "Insira uma data",
                              },
                            ]}
                          >
                            <DatePicker size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={5} className="form_list_options">
                          <Form.Item
                            label="Valor"
                            name="promotion_value"
                            rules={[
                              {
                                required: getFieldValue("promotion"),
                                message: "Insira um preço",
                              },
                            ]}
                          >
                            <InputNumber size="large" min={0} placeholder="0" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.Item>
                </Row>
                <Divider dashed className="bar" />
                <Row>
                  <Col span={5} className="d-flex justify-content-start">
                    <PaperClipOutlined />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Anexar ficheiro
                    </div>
                  </Col>
                  <Col span={19}>
                    <Row
                      style={{
                        display: "flex",
                      }}
                      className="form_list_options"
                    >
                      <Col span={24} align="end" className="upload_col">
                        <Form.Item name="file">
                          <Upload {...props}>
                            <Button
                              size="large"
                              icon={<UploadOutlined />}
                              className="btn-send-pdf"
                            >
                              Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row className="ml-0 mr-0 mt-20">
            <Col span={24} align="center">
              <Button
                type="primary"
                className="submit"
                onClick={form.submit}
                loading={isButtonLoading}
              >
                Criar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  );
}
