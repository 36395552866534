import { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Input, Select, InputNumber, DatePicker, Radio, Modal, notification } from 'antd'
import mbStepImage from '../../../../assets/icons/MB-Step.svg'
import './View.css';


export default function View({ data, open, close }) {

  return (
    <Modal
      title={`Pagamento`}
      open={open}
      onCancel={close}
      className="view_payment_modal"
      closable={true}
      maskClosable={false}
      footer={[]}
    >
    <Row>
      <Col span={5} className="d-flex justify-content-start align-items-center">
        <img src={mbStepImage} className='w-100 maxw-60' />
      </Col>
        <Col span={19}>
          <p className="mb-5 mt-5"><b>Entidade:</b> {data.entity}</p>
          <p className="mb-5 mt-5"><b>Referência:</b> {data.reference}</p>
          <p className="mb-5 mt-5"><b>Montante:</b> {data.value}€</p>
          <p className="mb-5 mt-5 date_expiry"><b>Data limite de pagamento:</b> {data.payment_expiry_date}</p>
        </Col>
      </Row>
    </Modal>
  )
}