import { Switch, Route, Redirect, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Input, DatePicker, Button, Select } from "antd";
import { Steps } from 'antd';
import axios from 'axios';
import moment from 'moment';
import logoUSF from '../../../assets/images/USF-AN.svg';
import logoUSFBackground from '../../../assets/images/logo-usg-login-criar-conta.svg';
import config from '../../../utils/config';
import endpoints from '../../../utils/endoinpts';
import axiosInit from '../../../utils/axiosInit';

import './ResetPasswordEmail.css';

function ResetPassword({ handleLogin }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [formPasswordEmail] = Form.useForm();
  const navigate = useNavigate();

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    });
  }
  function handleSubmit() {
    setIsButtonLoading(true);
    let values = formPasswordEmail.getFieldsValue(true);

    let data = {
      email: values.email,
    }

    axios.post(`${config.server_ip}${endpoints.auth.resetPasswordEmail}`, {
      data
    }).then((res) => {
      setIsButtonLoading(false);
      if (res.data.email_exists) {
        notification.success({
          message: 'E-mail enviado',
          description:
            'Foi enviado um e-mail com um link para a colocação de uma nova password. Se não receber um link num espaço de 10 minutos tente novamente por favor!',
        });
        navigate('/login');
      } else {
        notification.error({
          message: 'Erro',
          description:
            'Este e-mail não existe na nossa plataforma. Verifique se está a colocar o e-mail correto.',
        });
      }
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
    })
  }

  return (
    <Row className="d-flex jc-center ai-center">
      <Col xs={24} sm={24} md={13} lg={13} xl={13}>
        <Row className="form-register-content">
          <Col span={24}>
            <h1 className="text-center mb-0" style={{ color: '#4A4C4D' }}>Recuperação Password</h1>
            <p className="text-center" style={{ marginTop: '5px', color: '#BDBCBC' }}>Coloque abaixo o seu email para a recuperação da sua password</p>
            <Form
              form={formPasswordEmail}
              id="login-form-step-one"
              className="form-login"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={handleWarningMissingFields}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                  >
                    <Input type="email" size="large" placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="form-login-buttons mt-0">
          <Col span={24} className={"d-flex flex-column align-items-center justify-content-center"}>
            <Button
              loading={isButtonLoading}
              className="button-login"
              onClick={() => formPasswordEmail.submit()}
            >
              Recuperar Password
            </Button>
            <p className="register-text">Ainda não tenho conta. <a onClick={() => navigate('/register')}>Registe-se aqui</a></p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ResetPassword;
