import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Input, Button, Select, Spin, Upload, Radio, TimePicker } from "antd";
import {
  PaperClipOutlined,
  UploadOutlined
} from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import UserBar from "../../../layout/UserBar/UserBar";
import addPhoto from '../../../assets/icons/Add-a-photo.svg'
import endpoints from "../../../utils/endoinpts";
import config from "../../../utils/config";
import './FormDepartment.css';

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY/MM/DD';

function FormDepartment() {

  const { user } = useOutletContext();

  const [userData, setUserData] = useState({});
  const [configData, setConfigData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [ars, setArs] = useState([]);
  const [aces, setAces] = useState([]);
  const [usf, setUsf] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [formDepartment] = Form.useForm();

  const navigate = useNavigate();

  const uploadProps = {
    accept: '.pdf',
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let aux = fileList;
      aux.push(file);
      setFileList(aux);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      handleGetInfo();
    }
  }, [user]);

  function handleGetInfo() {
    let userData = user;

    axios.get(endpoints.info.getInfo).then((result) => {
      let allARS = result.data[0];
      let allACES = result.data[1];
      let allUSF = result.data[2];
      let allProfessions = result.data[3];
      let allRoles = result.data[4];
      let configDataServer = result.data[5];

      let formDepartmentText = configDataServer.filter((item) => item.type === "form_department")[0];

      let userARS = allARS.filter((value, index) => value.id === userData.id_ars);
      let userACES = allACES.filter((value, index) => value.id === userData.id_aces);
      let userUSF = allUSF.filter((value, index) => value.id === userData.id_usf);
      let userProfession = allProfessions.filter((value, index) => value.id === userData.id_profession);

      setArs(userARS);
      setAces(userACES);
      setUsf(userUSF);
      setProfessions(userProfession);
      setUserData(userData);
      setConfigData(formDepartmentText)
      formDepartment.setFieldsValue(userData);
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    })
  }

  function handleSubmit(values) {
    if (user.payment_valid) {
      setIsSending(true);

      let formData = new FormData();

      fileList.forEach((file) => {
        formData.append('file', file);
      });


      let aux = {
        id_user: userData.id,
        labor: values.labor,
        comments: values.comments,
        contact_time: dayjs(values.contact_time).format('HH:mm'),
        theme: values.theme,
        message: values.message
      }

      values.ars_name = ars.length > 0 ? ars[0].name : 'Sem ARS'
      values.aces_name = aces.length > 0 ? aces[0].name : 'Sem ACeS';
      values.usf_name = usf.length > 0 ? usf[0].name : 'Sem Unidade';
      values.profession_name = professions.length > 0 ? professions[0].name : 'Sem Profissão';
      values.formData = aux;

      formData.append('data', JSON.stringify(values));

      fetch(`${config.server_ip}${endpoints.app.postFormDepartment}`, {
        method: "POST",
        body: formData
      }).then((res) => {
        setTimeout(() => {
          setIsSending(false);
          notification.success({
            message: 'Dados enviados',
            description:
              'Os dados foram submetidos com sucesso!',
          });
          navigate('/');
          formDepartment.resetFields();
        }, 2000);
      }).catch((err) => {
        console.log(err);
        notification.error({
          message: 'Erro',
          description:
            'Aconteceu algum erro, por favor tente novamente mais tarde.',
        });
        setIsSending(false);
      })
    } else {
      notification.error({
        message: 'Erro',
        description:
          'Não tem as quotas em dia. Por favor verifique o pagamento das suas quotas.',
      });
    }
  }

  return (
    <Row className="w-100 dashboard">
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Row className="form_department_content">
            <Col span={24} className="d-flex flex-column ai-center">
              <p className="title" dangerouslySetInnerHTML={{ __html: configData.title }}></p>
              <Row gutter={24} className="d-flex jc-center ai-center">
                <Col span={12}>
                  <div className="form_department_text" dangerouslySetInnerHTML={{ __html: configData.text }}></div>
                </Col>
              </Row>
              <Form
                form={formDepartment}
                id="parecer-form"
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Nome completo"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Input size="large" placeholder="Nome" readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Input size="large" placeholder="E-mail" readOnly={userData.phone ? true : false} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label="Telemóvel"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Input size="large" placeholder="Nome" readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="n_member"
                      label="Nº Sócio"
                    >
                      <Input size="large" placeholder="Nº Sócio" readOnly />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name="id_ars"
                      label="ARS"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Select
                        disabled
                        size="large"
                        showSearch
                        placeholder="ARS"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                      >
                        {ars.map((value, index) => {
                          return (<Option value={value.id}>{value.name}</Option>)
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="id_aces"
                      label="ACeS"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Select
                        disabled
                        size="large"
                        showSearch
                        placeholder="ACeS"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                      >
                        {aces.map((value, index) => {
                          return (<Option value={value.id}>{value.name}</Option>)
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name="id_usf"
                      label="Unidade"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Select
                        disabled
                        size="large"
                        showSearch
                        placeholder="Unidade"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                      >
                        {usf.map((value, index) => {
                          return (<Option value={value.id}>{value.name}</Option>)
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="id_profession"
                      label="Profissão"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Select
                        disabled
                        size="large"
                        showSearch
                        placeholder="Profissão"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                      >
                        {professions.map((value, index) => {
                          return (<Option value={value.id}>{value.name}</Option>)
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      name="contact_time"
                      label="Melhor hora para estebelecer contacto"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <TimePicker size="large" placeholder="10:00" format={'HH:mm'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      className="labor_form_item"
                      name="labor"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <p className="labor_label">A sua dúvida é relacionada com questões laborais?</p>
                      <Radio.Group name="labor" onChange={(e) => formDepartment.setFieldValue("labor", e.target.value)}>
                        <Radio value={'yes'}>Sim</Radio>
                        <Radio value={'no'}>Não</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.labor !== currentValues.labor}
                    >
                      {({ getFieldValue }) =>
                        getFieldValue('labor') === 'yes' ? (
                          <Form.Item
                            name="comments"
                            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                          >
                            <Input.TextArea rows={4} size="large" placeholder="Por favor diga-nos a natureza do seu contrato de trabalho e respetiva categoria profissional" />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      name="theme"
                      label="Tema"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Input size="large" placeholder="Tema" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      name="message"
                      label="Mensagem"
                      rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    >
                      <Input.TextArea rows={4} size="large" placeholder="Escreva aqui a sua mensagem" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      name="files"
                    >
                      <p><PaperClipOutlined /> <b>Anexos</b> (mais do que um ficheiro em formato PDF)</p>
                      <Upload {...uploadProps}>
                        <Button className="icon_edit" icon={<UploadOutlined />}>Upload</Button>
                        <div
                          className="pic"
                          style={{ backgroundImage: previewImage ? `url(${previewImage})` : userData.img ? `url(${userData.img})` : `url(${addPhoto})` }}
                        >
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} className="buttons_edit_profile">
                  <Col span={12} className="d-flex flex-column ai-end">
                    <Button className="button_voltar" onClick={() => navigate('/')}>Voltar</Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={formDepartment.submit}
                      type="primary"
                      className="minw-175"
                      size="large"
                      loading={isSending}
                    >
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

export default FormDepartment;
