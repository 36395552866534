import React, { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  useLocation,
  Link,
  useNavigate,
} from "react-router-dom";
import "./MainLayout.css";
import { Layout, Row, Col, Badge, message } from "antd";
import DrawerShoppingCart from "../../components/UserComponents/DrawerShoppingCart/DrawerShoppingCart";
import logoUSF from "../../assets/images/USF-AN.svg";
import svgProfile from "../../assets/icons/svg-perfil.svg";
import svgLogout from "../../assets/icons/svg-logout.svg";
import svgSettings from "../../assets/icons/svg-settings.svg";
import svgSite from "../../assets/icons/svg-site.svg";
import svgCart from "../../assets/icons/svg-carrinho.svg";
import axios from "axios";
import axiosApi from "../../utils/axiosInit";
import endpoints from "../../utils/endoinpts";
import Loading from "../Loading/Loading";
import UserBar from "../UserBar/UserBar";
import dayjs from "dayjs";
import config from "../../utils/config";
import { HomeOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
const { Header, Sider, Content, Footer } = Layout;

export default function MainLayout({ handleStopLoading }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenShoppingCartDrawer, setIsOpenShoppingCartDrawer] =
    useState(false);
  const [shoppingCart, setShoppingCart] = useState({ voucher: {}, items: [] });
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let auxShoppingCart = localStorage.getItem("shopping_cart");
    setShoppingCart(JSON.parse(auxShoppingCart));
    handleVerifyUser();
  }, []);

  function handleVerifyUser() {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get(endpoints.auth.verifyToken, {
          params: { token },
        })
        .then(async (res) => {
          if (res.data.token_valid) {
            await axiosApi.axiosInit(token);
            setUser(res.data.user_data);
            handleStopLoading();
          } else {
            message.error({ content: "O seu token não é válido" });
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }

  function handleStopLoading() {
    setIsLoading(false);
  }

  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("shopping_cart");
    window.location.assign("/login");
  }

  function handleOpenShoppingCartDrawer() {
    setIsOpenShoppingCartDrawer(true);
  }

  function handleCloseShoppingCartDrawer(data) {
    localStorage.setItem("shopping_cart", JSON.stringify(data));
    setIsOpenShoppingCartDrawer(false);
  }

  function handleUpdateShoppingCart(data) {
    localStorage.setItem("shopping_cart", JSON.stringify(data));
    setShoppingCart(data);
  }

  function handleUpdateUser(data) {
    setUser(data);
  }

  return (
    <>
      {isLoading && <Loading isLoading={isLoading} />}
      <Layout className="d-flex flex-column jc-center ai-center main_layout">
        <DrawerShoppingCart
          user={user}
          open={isOpenShoppingCartDrawer}
          close={handleCloseShoppingCartDrawer}
          handleUpdateShoppingCart={handleUpdateShoppingCart}
        />
        <Header className="d-flex jc-center ai-center w-100">
          <Row className="d-flex ai-center container pb-0">
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="d-flex ai-center mb-10"
            >
              <img
                src={logoUSF}
                className="logo_header"
                onClick={() =>
                  navigate(user.is_admin || user.is_viewer ? "/admin" : "/")
                }
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="d-flex jc-end ai-center mb-10"
            >
              <Row className="icons d-flex justify-content-end align-items-center">
                <div
                  className="icon_website mr-10"
                  onClick={() => navigate(`/`)}
                >
                  <HomeOutlined />
                </div>
                {(user?.is_admin === 0 ||
                  user?.is_viewer === 0 ||
                  user?.id === "guest") && (
                  <div
                    className="icon_website mr-10"
                    onClick={() => window.open("https://usf-an.pt")}
                  >
                    <img src={svgSite} />
                  </div>
                )}
                {user?.is_admin === 1 && (
                  <div
                    className="icon_settings mr-10"
                    onClick={() => navigate(`/settings`)}
                  >
                    <img src={svgSettings} />
                  </div>
                )}
                {user?.is_admin === 0 && user?.is_viewer === 0 && (
                  <Badge count={shoppingCart ? shoppingCart.items.length : 0}>
                    <div
                      className={`icon_settings ${
                        user.id === "guest" ? "" : "mr-10"
                      }`}
                      onClick={handleOpenShoppingCartDrawer}
                    >
                      <img src={svgCart} />
                    </div>
                  </Badge>
                )}
                {user?.id !== "guest" && (
                  <div
                    className="icon_profile mr-10"
                    onClick={() =>
                      navigate(
                        `/${
                          user?.is_admin || user?.is_viewer
                            ? "adminProfile"
                            : "profile"
                        }`
                      )
                    }
                  >
                    <img src={svgProfile} />
                  </div>
                )}
                {user?.id !== "guest" && (
                  <div className="icon_logout" onClick={() => handleLogout()}>
                    <img src={svgLogout} />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Header>
        <Content className={`container d-flex flex-column jc-start ai-center`}>
          <UserBar user={user} />
          <Outlet
            context={{
              user,
              shoppingCart,
              handleUpdateShoppingCart,
              handleUpdateUser,
            }}
          />
        </Content>
        <Footer>
          <Row className="w-100">
            <Col span={24} className="text-center footer">
              <p>
                © Copyright 2024. USF-AN. Todos os direitos reservados |{" "}
                <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                  Termos e condições
                </a>{" "}
                |{" "}
                <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                  Política de privacidade
                </a>
              </p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </>
  );
}
