import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Form,
  Select,
  Upload,
  Input,
} from 'antd';

import columns from "./Columns";

function ChooseColumns({ form, data, handleSubmit }) {

  const [checkAll, setCheckAll] = useState(false)
  const [indeterminate, setInderterminate] = useState(false);
  const [checkOptions, setCheckOptions] = useState([]);

  useEffect(() => {
    console.log(data);
    if (data && data.length > 0) {
      setCheckAll(false);
      setInderterminate(false);

      let auxArray = [];

      for (let z = 0; z < data.length; z++) {
        let auxColumns = Object.keys(data[z]);
        for (let i = 0; i < auxColumns.length; i++) {
          if (auxColumns[i] !== 'image' && auxColumns[i] !== 'img' && !auxColumns[i].includes('id_') && auxColumns[i] !== 'invoice_items') {
            if (auxArray.filter((c) => c.dataIndex === auxColumns[i]).length === 0) {
              if (columns.filter((c) => c.dataIndex === auxColumns[i]).length > 0) {
                auxArray.push(columns.filter((c) => c.dataIndex === auxColumns[i])[0]);
              } else {
                auxArray.push({ dataIndex: auxColumns[i], title: auxColumns[i] });
              }
            }
          }
        }
      }

      setCheckOptions(auxArray);
    }
  }, [data]);

  function handleCheckAll(e) {
    if (e.target.checked) {
      let aux = checkOptions;
      form.setFieldValue('columns', aux)
      setCheckAll(true);
      setInderterminate(false);
    } else {
      form.setFieldValue('columns', []);
      setCheckAll(false);
      setInderterminate(false);
    }
  }

  function handleChangeCheckbox(e) {
    form.setFieldValue('columns', e);
    if (form.getFieldValue('columns').length > 0 && form.getFieldValue('columns').length === checkOptions.length) {
      setInderterminate(false)
    } else if (form.getFieldValue('columns').length > 0 && form.getFieldValue('columns').length < checkOptions.length) {
      setInderterminate(true);
    } else {
      setInderterminate(false);
    }
  }

  return (
    <div className="d-flex flex-column jc-center ai-center p-20">
      <p className="bold blue f-20 mb-30 mt-30">Escolha as colunas que deseja exportar</p>
      <Form form={form} onFinish={handleSubmit} className="export_form">
        <Checkbox onChange={handleCheckAll} checked={checkAll} indeterminate={indeterminate}>Selecionar todos</Checkbox>
        <Form.Item name="columns">
          <Checkbox.Group onChange={handleChangeCheckbox}>
            {checkOptions.map((item) =>
              <Checkbox value={item}>
                {item.title}
              </Checkbox>
            )}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChooseColumns;