import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Spin } from "antd";
import axios from 'axios';
import moment from 'moment';

import GuestBar from "../../../layout/GuestBar/GuestBar";
import GuestListEvents from "../../../components/GuestComponents/GuestListEvents/GuestListEvents";
import './NotFound.css';

function NotFound() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)
    }, []);

    return (
        <Row className="w-100 notfound">
            <Col span={24}>
                <Spin spinning={isLoading}>
                    <Col span={24}>
                        <Row className="ml-0 mr-0 content">
                            <Col span={24} className="d-flex flex-column justity-content-center align-items-center">
                                <h1>ERROR 404</h1>
                                <p>page not found</p>
                            </Col>
                        </Row>
                    </Col>
                </Spin>
            </Col>
        </Row>
    );
}

export default NotFound;
