import { Col, Form, Row, Input, DatePicker, Radio } from "antd";
import moment from 'moment';

import './Register.css';

export default function RegisterStepOne({ form, handleSubmit, handleSubmitFailed }) {

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
    >
      <Row className="d-flex jc-center ai-center">
        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
          <Form.Item
            name="name"
            label="Nome completo"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input size="large" placeholder="Nome" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email', message: 'O e-mail tem de ser válido' }]}
          >
            <Input type="email" size="large" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
            hasFeedback
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirmar Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As passwords não correspondem'));
                },
              }),
            ]}
          >
            <Input.Password size="large" placeholder="Confirmar Password" />
          </Form.Item>
          <Form.Item
            name="birth_date"
            label="Data de nascimento"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  let today = moment(new Date());
                  let birthDate = moment(new Date(getFieldValue('birth_date')));
                  let duration = today.diff(birthDate, 'years');
                  if (!value || duration > 17) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Tem a certeza que a sua data de nascimento está correta?'));
                },
              }),
            ]}
          >
            <DatePicker size="large" placeholder="Data de nascimento" />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Sexo"
            rules={[
              {
                required: true,
                message: 'Este campo é obrigatório',
              }]}
          >
            <Radio.Group>
              <Radio value={'feminino'}>Feminino</Radio>
              <Radio value={'masculino'}>Masculino</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="phone"
            label="Telefone"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input size="large" placeholder="Telefone" />
          </Form.Item>
          <Form.Item
            name="cc"
            label="BI/CC"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input size="large" placeholder="BI/CC" />
          </Form.Item>
          <Form.Item
            name="nif"
            label="NIF"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input size="large" placeholder="NIF" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
