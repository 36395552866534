import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  notification,
  Input,
  Button,
  Divider,
  Spin,
  InputNumber,
  Switch,
} from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  FilePdfOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import axios, { all } from "axios";
import endpoints from "../../../utils/endoinpts";
import UserBar from "../../../layout/UserBar/UserBar";
import "./GuestEventDetails.css";
import config from "../../../utils/config";
import GuestBar from "../../../layout/GuestBar/GuestBar";
import TextArea from "antd/es/input/TextArea";

function GuestEventDetails() {
  const { shoppingCart } = useOutletContext();
  const { handleUpdateShoppingCart } = useOutletContext();

  const [event, setEvent] = useState({});
  const [eventTotalData, setEventTotalData] = useState({});
  const [eventOptions, setEventOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    handleGetEvent();
  }, [shoppingCart]);

  function handleGetEvent() {
    axios
      .get(`${config.server_ip}${endpoints.guest.readById}`, {
        params: { id },
      })
      .then((res) => {
        let eventData = res.data.event[0];
        let eventPricesData = res.data.event_prices;
        let priceData = eventPricesData.filter(
          (value) => value.id_profession === 6
        );
        priceData[0].profession_name = "Não Sócio(a)";
        let optionsData = res.data.event_options;

        let auxFormData = {
          id: eventData.id,
          name: eventData.name,
          image: eventData.image,
          id_file: eventData.id_file,
          file_name: eventData.file_name,
          description: eventData.description,
          date_start: eventData.date_start,
          date_end: eventData.date_end,
          total_professionals: eventData.total_professionals,
          total_professionals_left: eventData.total_professionals_left,
          institutional_incentives: eventData.institutional_incentives,
          options: optionsData,
          price: priceData,
          promotion: eventData.promotion,
          promotion_date: dayjs(eventData.promotion_date).format("YYYY-MM-DD"),
          promotion_value: eventData.promotion_value,
        };

        let shoppingCartObject = JSON.parse(JSON.stringify(shoppingCart));
        let shoppingCartArray = shoppingCartObject.items;
        if (shoppingCartArray.length > 0) {
          let index = shoppingCartArray.findIndex(
            (value) => value.id === Number(id)
          );
          if (index >= 0) {
            form.setFieldsValue(shoppingCartArray[index]);
            setEventTotalData(shoppingCartArray[index]);
            handleChangeFormInfo(null, shoppingCartArray[index]);
            setIsEditing(true);
          } else {
            if (
              auxFormData.total_professionals_left === 0 ||
              auxFormData.price[0].quantity_left === 0
            )
              setIsOutOfStock(true);
            form.setFieldsValue(auxFormData);
            setEventTotalData(auxFormData);
            setIsEditing(false);
          }
        } else {
          if (
            auxFormData.total_professionals_left === 0 ||
            auxFormData.price[0].quantity_left === 0
          )
            setIsOutOfStock(true);
          form.setFieldsValue(auxFormData);
          setEventTotalData(auxFormData);
          setIsEditing(false);
        }

        setEvent(auxFormData);
        setEventOptions(optionsData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangeFormInfo(d, allValues) {
    let data = allValues;
    let total = 0;

    total += Number(data.price[0].qtd) * Number(data.price[0].price);

    allValues.price[0].qtd = data.price[0].qtd ? 1 : 0;

    if (data.options) {
      for (let i = 0; i < data.options.length; i++) {
        if (data.options[i].qtd) {
          total += Number(data.options[i].qtd) * Number(data.options[i].price);
          data.options[i].qtd = 1;
        } else {
          data.options[i].qtd = 0;
        }
      }
    }

    if (event.promotion) {
      let promotion_date = dayjs(event.promotion_date);
      let today = dayjs();
      let valid = today.diff(promotion_date, "days");
      if (valid <= 0) {
        total -= event.promotion_value;
      }
    }

    setEventTotalData(allValues);
    form.setFieldValue("total", total);
    setTotal(total);
  }

  function handleAddToShoppingCart(values) {
    if (!isOutOfStock) {
      let shoppingCartObject = JSON.parse(JSON.stringify(shoppingCart));
      let shoppingCartArray = shoppingCartObject.items;
      let existsIndex = shoppingCartArray.findIndex(
        (value) => value.id === values.id
      );

      values.promotion = event.promotion;
      values.promotion_date = event.promotion_date;
      values.promotion_value = event.promotion_value;

      /*if (event.promotion && ((dayjs()).diff(dayjs(event.promotion_date), 'days')) <= 0) {
        values.price[0].old_price = values.price[0].price;
        values.price[0].price = Number(values.price[0].old_price) - Number(event.promotion_value);
      }*/

      if (existsIndex >= 0) {
        let copyShoppingCartArray = shoppingCartArray.slice(existsIndex, 0);
        copyShoppingCartArray.push(values);
        shoppingCartObject.items = copyShoppingCartArray;
        handleUpdateShoppingCart(shoppingCartObject);
      } else {
        let event_data = JSON.parse(JSON.stringify(values));
        let event_options = [];
        for (let z = 0; z < event_data.options.length; z++) {
          if (event_data.options[z].qtd >= 0) {
            event_options.push(event_data.options[z]);
          }
        }

        axios
          .post(`${config.server_ip}${endpoints.guest.updateStock}`, {
            data: {
              event_data: event_data,
              event_options: event_options,
              guest_data: { id_profession: 6 },
              type: "remove_quantity",
            },
          })
          .then((res) => {
            shoppingCartArray.push(values);
            shoppingCartObject.items = shoppingCartArray;
            handleUpdateShoppingCart(shoppingCartObject);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      notification.success({
        message: `Evento adicionado`,
        description:
          "O evento foi adicionar ao carrinho! Continue a comprar e finalize a sua compra abrindo o carrinho",
      });
    } else {
      notification.error({
        message: `Evento sem lugares disponíveis`,
        description:
          "Este evento já se encontra esgotado. Já não é possível aceitar mais inscrições.",
      });
    }
  }

  return (
    <Row className="w-100 dashboard">
      <Col span={24}>
        <Spin spinning={isLoading} className="w-100">
          <Row className="event_details_content ml-0 mr-0 w-100" gutter={[24]}>
            <Col span={24}>
              <h2 className="mt-0">Evento</h2>
              <Row className="d-flex jc-sb flex-wrap-reverse">
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                  <h1 className="mobile-f-24">{event.name}</h1>
                  <div className="event_date">
                    <CalendarOutlined />{" "}
                    {dayjs(event.date_start).format("DD/MM/YYYY")}
                  </div>
                  <p
                    className="event_description"
                    dangerouslySetInnerHTML={{ __html: event.description }}
                  ></p>
                  <div className="mt-20">
                    <span
                      className="event_file_name"
                      onClick={() =>
                        window.open(
                          `${config.server_ip}files/events/${event.file_name}`
                        )
                      }
                    >
                      <FilePdfOutlined />
                      <span className="ml-10">{event.name}</span>
                    </span>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <img
                    src={`${config.server_ip}media/${event.image}`}
                    className="event_img"
                  />
                </Col>
              </Row>
              <Divider />
              <Row
                className="prices_options mobile-pl-10 mobile-pr-10"
                gutter={[24]}
                justify="space-between"
              >
                {isOutOfStock && (
                  <div className="enrolled_warning">
                    {" "}
                    Já não existem lugares{" "}
                    <InfoCircleOutlined className="icon_enrolled" />{" "}
                  </div>
                )}
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={14}
                    xl={14}
                    style={{ filter: isOutOfStock ? "blur(4px)" : "" }}
                  >
                    <Form
                      form={form}
                      id="form-add-to-cart-event"
                      onFinish={handleAddToShoppingCart}
                      onValuesChange={handleChangeFormInfo}
                      initialValues={{
                        price: [{}],
                        options: [{}],
                      }}
                      className="form"
                      autoComplete="off"
                    >
                      <Form.Item key="id" name="id" hidden>
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item key="name" name="name" hidden>
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item key="image" name="image" hidden>
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        key="institutional_incentives"
                        name="institutional_incentives"
                        hidden
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item key="description" name="description" hidden>
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item key="total" name="total" hidden>
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        key="total_professionals"
                        name="total_professionals"
                        hidden
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        key="total_professionals_left"
                        name="total_professionals_left"
                        hidden
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.List name="price" key="price">
                        {(fields, { add, remove }) => {
                          return fields.map(
                            ({ key, name, ...restField }, index) => {
                              return (
                                <Row className="row_price_profession d-flex jc-sb pr-20 mobile-pr-0">
                                  <Form.Item
                                    className="not_input_price mobile-w-100 d-flex jc-center ai-center w-40"
                                    key={`${name}_profession_name`}
                                    {...restField}
                                    name={[name, "profession_name"]}
                                  >
                                    <TextArea
                                      size="large"
                                      style={{ width: 500 }}
                                      readOnly
                                      className="mobile-text-center"
                                      autoSize
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    className="not_input_price mobile-w-100 d-flex jc-center ai-center"
                                    key={`${name}_price`}
                                    {...restField}
                                    label="Preço (€)"
                                    name={[name, "price"]}
                                  >
                                    <Input
                                      readOnly
                                      size="large"
                                      placeholder="0,00 €"
                                      className="input_price mobile-w-100 d-flex jc-center ai-center mobile-text-center"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    className="mobile-w-100 d-flex jc-center ai-center"
                                    key={`${name}_qtd`}
                                    {...restField}
                                    name={[name, "qtd"]}
                                    valuePropName="checked"
                                  >
                                    <Switch size="large" />
                                  </Form.Item>
                                </Row>
                              );
                            }
                          );
                        }}
                      </Form.List>
                      {eventOptions.length > 0 && (
                        <>
                          <p
                            className="mb-0 mt-40"
                            style={{ fontSize: "18px" }}
                          >
                            Opções
                          </p>
                          <Divider dashed className="mt-10" />
                        </>
                      )}

                      <Row
                        className={`row_options ${
                          eventOptions.length > 0 ? "" : "hidden"
                        }`}
                      >
                        <Col span={24}>
                          <Form.List name="options" key="options">
                            {(fields, { add, remove }) => {
                              return fields.map(
                                ({ key, name, ...restField }, index) => {
                                  return (
                                    <Row
                                      className={`d-flex jc-sb ${
                                        index === 0 ? "" : "mt-20"
                                      }`}
                                    >
                                      <Form.Item
                                        className="not_input mobile-w-100 d-flex jc-center ai-center w-40"
                                        key={`${name}_name`}
                                        {...restField}
                                        name={[name, "name"]}
                                      >
                                        <TextArea
                                          size="large"
                                          style={{ width: 500 }}
                                          readOnly
                                          className="mobile-text-center"
                                          autoSize
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        className="not_input mobile-w-100 d-flex jc-center ai-center"
                                        key={`${name}_price`}
                                        {...restField}
                                        label="Preço(€)"
                                        name={[name, "price"]}
                                      >
                                        <Input
                                          readOnly
                                          size="large"
                                          placeholder="0,00 €"
                                          className="input_price mobile-w-100 d-flex jc-center ai-center mobile-text-center"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        className="mobile-w-100 d-flex jc-center ai-center"
                                        key={`${name}_qtd`}
                                        {...restField}
                                        name={[name, "qtd"]}
                                        valuePropName="checked"
                                      >
                                        <Switch size="large" />
                                      </Form.Item>
                                    </Row>
                                  );
                                }
                              );
                            }}
                          </Form.List>
                        </Col>
                      </Row>
                      {event.promotion > 0 &&
                        dayjs().diff(dayjs(event.promotion_date), "days") <=
                          0 && (
                          <Row className={`row_promotion mt-20`}>
                            <Col
                              span={20}
                              className="d-flex align-items-center"
                            >
                              <span className="promotion_title">
                                Promoção válida até{" "}
                                {dayjs(event.promotion_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </Col>
                            <Col
                              span={4}
                              className="d-flex justify-content-center"
                            >
                              <span className="promotion_value">
                                {event.promotion_value}€
                              </span>
                            </Col>
                          </Row>
                        )}
                    </Form>
                  </Col>
                  {Object.keys(eventTotalData).length > 0 &&
                    eventTotalData.price[0].qtd > 0 && (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={9}
                        xl={9}
                        className="receipt_example mt-20"
                      >
                        <Row align="top">
                          <Col span={24}>
                            <h1 className="mt-0 text-center mobile-f-24">
                              Total
                            </h1>
                            <Row>
                              <Col span={17}>
                                <b>Nome</b>
                              </Col>
                              <Col span={3} align="center">
                                <b>Qtd</b>
                              </Col>
                              <Col span={4} align="end">
                                <b>Valor</b>
                              </Col>
                            </Row>
                            <Divider className="mt-10 mb-10" />
                            {eventTotalData.price[0].qtd > 0 && (
                              <Row className="mt-10">
                                <Col span={17}>
                                  {eventTotalData.price[0].profession_name}
                                </Col>
                                <Col span={3} align="center">
                                  {eventTotalData.price[0].qtd}
                                </Col>
                                <Col span={4} align="end">
                                  {eventTotalData.price[0].price}€
                                </Col>
                              </Row>
                            )}
                            {eventTotalData.options.length > 0 &&
                              eventTotalData.options[0].qtd > 0 && (
                                <Divider dashed className="mt-10 mb-10" />
                              )}
                            {eventTotalData.options.map((value) => (
                              <>
                                {value.qtd > 0 && (
                                  <Row className="mt-10">
                                    <Col span={17}>{value.name}</Col>
                                    <Col span={3} align="center">
                                      {value.qtd}
                                    </Col>
                                    <Col span={4} align="end">
                                      {value.price}€
                                    </Col>
                                  </Row>
                                )}
                              </>
                            ))}
                            {event.promotion > 0 &&
                              dayjs().diff(
                                dayjs(event.promotion_date),
                                "days"
                              ) <= 0 && (
                                <>
                                  <Divider className="mt-10 mb-10" />
                                  <Row className="mt-10">
                                    <Col span={20}>
                                      <b>
                                        Promoção válida até{" "}
                                        {dayjs(event.promotion_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </b>
                                    </Col>
                                    <Col span={4} align="end">
                                      -{event.promotion_value}€
                                    </Col>
                                  </Row>
                                </>
                              )}
                            <Divider className="mt-10 mb-10" />
                            <Row className="mt-10" align="end">
                              <Col span={24} align="end">
                                Total: {total}€
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row align="bottom" className="mt-20">
                          <Col span={24} align="center">
                            <Button
                              size="large"
                              type="primary"
                              className="button-register mobile-f-14"
                              onClick={form.submit}
                            >
                              {isEditing
                                ? "Atualizar o carrinho"
                                : "Adicionar ao carrinho"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                </>
              </Row>
              <Row className="mt-40 d-flex jc-center">
                <Button
                  className="payment_button back"
                  type="primary"
                  onClick={() => navigate("/guest")}
                >
                  Voltar
                </Button>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

export default GuestEventDetails;
