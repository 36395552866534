import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

import userDefault from '../../assets/images/user-default.svg'
import './GuestBar.css';

function GuestBar({ user }) {

  const navigate = useNavigate();

  return (
    <Row className="user_bar">
      <Col xs={24} sm={24} md={24} lg={5} xl={4} className="mb-20">
        <img src={userDefault} className="w-100 maxw-150" />
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={16} className="info_user">
        <Row className="welcome_text">
          <p>Bem-vindo à USF</p>
        </Row>
        <Row className="user_name">
          <p><b>Eventos</b> não sócios</p>
        </Row>
        <Row className="user_profession">
          <p>Fique a conhecer todos os nossos eventos</p>
        </Row>
      </Col>
    </Row>
  );
}

export default GuestBar;
