import { Col, Row, Tabs } from "antd";

import UserBar from "../../../layout/UserBar/UserBar";


import Dashboard from "../../../components/AdminComponents/Dashboard/Dashboard";
import ListUsers from "../../../components/AdminComponents/Users/List/List";
import ListEvents from "../../../components/AdminComponents/Events/List/List";
import ListVouchers from "../../../components/AdminComponents/Vouchers/List/List";
import ListPayments from "../../../components/AdminComponents/Payments/List/List";
import ListForms from "../../../components/AdminComponents/Form/List/List";

import './Admin.css';
import { useOutletContext } from "react-router-dom";

function Admin() {

  const { user } = useOutletContext();

  const itemsTabAdmin = [
    {
      label: `Dashboard`,
      key: 1,
      children: <Dashboard user={user} />,
    },
    {
      label: `Sócios`,
      key: 2,
      children: <ListUsers user={user} />,
    },
    {
      label: `Eventos`,
      key: 3,
      children: <ListEvents user={user} />,
    },
    {
      label: `Vouchers`,
      key: 4,
      children: <ListVouchers user={user} />,
    },
    {
      label: `Pagamentos`,
      key: 5,
      children: <ListPayments user={user} />,
    },
    {
      label: `Formulário Departamento`,
      key: 6,
      children: <ListForms user={user} />,
    }
  ];

  const itemsTabViewer = [
    {
      label: `Dashboard`,
      key: 1,
      children: <Dashboard user={user} />,
    },
    {
      label: `Sócios`,
      key: 2,
      children: <ListUsers user={user} />,
    },
    {
      label: `Pagamentos`,
      key: 5,
      children: <ListPayments user={user} />,
    }
  ];

  return (
    <Row className="w-100 dashboard">
      <Col span={24}>
        <Row className="content mt-10">
          <Col span={24}>
            <Tabs
              id="tabs_admin"
              defaultActiveKey="1"
              items={user.is_admin ? itemsTabAdmin : itemsTabViewer}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Admin;
