import { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Input, Select, InputNumber, DatePicker, Radio, Modal, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import axios from 'axios';
import dayjs from 'dayjs';

import endpoints from '../../../../utils/endoinpts';

import './Delete.css';

const { TextArea } = Input


export default function Delete({ data, open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [voucher, setVoucher] = useState({});

  useEffect(() => {
    setVoucher(data);
  }, [data])

  function handleDeleteVoucher() {
    setIsButtonLoading(true);
    axios.post(endpoints.voucher.deleteVoucher, {
      data: voucher
    }).then((res) => {
      setTimeout(() => {
        close();
        setIsButtonLoading(false);
      }, 1500)
    }).catch((err) => {
      console.log(err)
      setIsButtonLoading(false);
    })
  }

  return (
    <Modal
      title={`Apagar voucher`}
      open={open}
      onCancel={close}
      className="delete_vouchers_modal"
      closable={true}
      maskClosable={false}
      footer={[
        <Button
          className="payment_button"
          key="submit"
          type="primary"
          onClick={handleDeleteVoucher}
          loading={isButtonLoading}
        >
          Sim
        </Button>,
        <Button
          className="payment_button back"
          key="submit"
          type="primary"
          onClick={() => close()}
        >
          Não
        </Button>

      ]}
    >
      <Row>
        <Col span={24}>
          <Row>
            Deseja mesmo apagar o seguinte voucher?
          </Row>
          <Row>
            <span className='voucher_name'>{voucher.name}</span>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}