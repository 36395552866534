import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Drawer, Row, Button, Spin, Table } from "antd";
import moment from 'moment';
import axios from 'axios';
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";

import './DrawerEventsUserList.css';
import config from "../../../utils/config";
import endpoints from "../../../utils/endoinpts";
import Cancel from "./Cancel";

const columns = [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Data',
    dataIndex: 'date_start',
    key: 'date_start',
  },
  {
    title: 'Data de inscrição',
    dataIndex: 'enroll_date',
    key: 'enroll_date',
  },
  {
    title: 'Estado',
    dataIndex: 'statusHTML',
    key: 'statusHTML',
  },
  {
    title: 'Data de pagamento',
    dataIndex: 'payment_date',
    key: 'payment_date',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: 'Fatura',
    dataIndex: 'invoice_link',
    key: 'invoice_link',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];


function DrawerEventsUserList({ show, close, events, user }) {

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [eventsTable, setEventsTable] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let aux = [];
    for (let i = 0; i < events.length; i++) {
      let auxObject = {
        key: i,
        image: <img className="img_events_list" src={`${config.server_ip}media/${events[i].image}`} />,
        name: <a className="events_list_name" onClick={() => navigate(`/eventDetails/${events[i].id}`)}>{events[i].name}</a>,
        date_start: moment(events[i].date_start).format('DD-MM-YYYY'),
        enroll_date: moment(events[i].enroll_date).format('DD-MM-YYYY'),
        payment_date: moment(events[i].payment_date).format('DD-MM-YYYY'),
        statusHTML: handlePaymentStatus(events[i].payment_status),
        invoice_link: events[i].invoice_link ? (<Button target="_blank" href={events[i].invoice_link}><LinkOutlined /></Button>) : '-',
        invoice_items: events[i].invoice_items,
        total: `${handleCalcTotal(events[i].name, JSON.parse(events[i].invoice_items))}€`,
        actions: events[i].payment_status === "em validação" ?
          <Button
            danger
            onClick={() => handleOpenCancel(events[i])}
          >
            <DeleteOutlined />
          </Button>
          :
          null,
      }

      aux.push(auxObject);
    }

    setEventsTable(aux);
    setIsLoading(false);
  }, [show === true]);

  function handlePaymentStatus(status) {
    if (status === "pago") {
      return (<div className="events_payment payed">Pago</div>);
    } else if (status === "cancelado") {
      return (<div className="events_payment canceled">Cancelado</div>);
    } else {
      return (<div className="events_payment waiting_payment">Aguarda pagamento</div>);
    }
  }

  function handleCalcTotal(eventName, invoiceItems) {
    let total = 0;
    for (let i = 0; i < invoiceItems.length; i++) {
      if (invoiceItems[i].description.includes(eventName) && invoiceItems[i].quantity > 0) {
        if (invoiceItems[i].discount) {
          let percentageValue = (invoiceItems[i].price * (invoiceItems[i].discount / 100));
          total += invoiceItems[i].price - percentageValue;
        } else {
          total += invoiceItems[i].price;
        }
      }
    }
    return parseFloat(total).toFixed(1);
  }


  const expandedRowRender = (e) => {
    const columnsExpanded = [
      {
        title: 'Item',
        dataIndex: 'item',
        key: 'item',
      },
      {
        title: 'Qtd',
        dataIndex: 'qtd',
        key: 'qtd',
      },
      {
        title: 'Preço',
        dataIndex: 'price',
        key: 'price',
      },
    ];

    const dataExpanded = [];
    let aux = JSON.parse(e.invoice_items);

    for (let z = 0; z < aux.length; z++) {
      if (aux[z].description.includes(e.name.props.children)) {
        let description = aux[z].description.split(`${e.name.props.children} - `)[1];
        if (aux[z].quantity > 0) {
          dataExpanded.push({
            key: e.key.toString() + z.toString(),
            item: description,
            qtd: aux[z].quantity,
            price: `${aux[z].price}€`,
          });
        }

        if (z === aux.length - 1 && aux[z].discount) {
          dataExpanded.push({
            key: e.key.toString() + z.toString() + `d`,
            item: 'Desconto',
            qtd: null,
            price: aux[z].discount + '%',
          });
        }
      }
    }
    return <Table className="expanded_table" columns={columnsExpanded} dataSource={dataExpanded} pagination={false} />;
  };

  function handleCancelPayment() {
    console.log(selectedData);
    axios.post(endpoints.purchase.paymentUserCancel, {
      data: {...selectedData, user_name: user.name, user_email: user.email, type: 'events' }
    }).then((res) => {
      setSelectedData({});
      setIsOpenCancel(false);
      close();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleOpenCancel(item) {
    setSelectedData(item);
    setIsOpenCancel(true);
  }

  function handleCloseCancel() {
    setIsOpenCancel(false);
    close()
  }

  return (
    <>
      <Drawer
        className="quotes_drawer"
        title={`Os meus eventos`}
        width={1400}
        onClose={close}
        open={show}
        maskClosable={false}
      >
        <Cancel open={isOpenCancel} close={handleCloseCancel} submit={handleCancelPayment} />
        <Row>
          <Col span={24}>
            <p className="title">As minhas inscrições nos eventos</p>
            <p className="user_name">Aqui está apresentada a listagem de todos os eventos que se inscreveu e participou.</p>
            <Table
              className="mt-40"
              loading={isLoading}
              dataSource={eventsTable}
              columns={columns}
              expandable={{
                expandedRowRender,
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default DrawerEventsUserList;
