import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Row, Form, Drawer, Divider, Input, Radio, Checkbox, Button, notification, Select, Spin } from "antd";
import { Steps } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";

import mbImage from "../../../assets/icons/MB.svg";
import mbStepImage from "../../../assets/icons/MB-Step.svg";
import mbWayImage from "../../../assets/icons/MB-Way.svg";
import shoppingCartEmpty from "../../../assets/icons/svg-carrinho-vazio.svg";
import endpoints from "../../../utils/endoinpts";
import "./DrawerShoppingCart.css";
import config from "../../../utils/config";

function DrawerShoppingCart({ user, open, close, handleUpdateShoppingCart }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [shoppingCart, setShoppingCart] = useState({ voucher: {}, items: [] });
  const [currentStep, setCurrentStep] = useState(0);
  const [paymentData, setPaymentData] = useState({});
  const [ars, setARS] = useState([]);
  const [aces, setACES] = useState([]);
  const [acesFiltered, setACESFiltered] = useState([]);
  const [usf, setUSF] = useState([]);
  const [usfFiltered, setUSFFiltered] = useState([]);
  const [professions, setProfessions] = useState([]);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    console.log(shoppingCart);
    let shopping_cart = null;
    let shoppingCartArray = [];

    if (localStorage.getItem("shopping_cart")) {
      shopping_cart = localStorage.getItem("shopping_cart");
      shoppingCartArray = JSON.parse(shopping_cart);
    } else {
      shopping_cart = { voucher: {}, items: [] };
      shoppingCartArray = shopping_cart;
    }

    axios
      .get(endpoints.info.getInfo)
      .then((result) => {
        setARS(result.data[0]);
        setACES(result.data[1]);
        setUSF(result.data[2]);
        setProfessions(result.data[3]);
        setShoppingCart(shoppingCartArray);

        if (shoppingCartArray.voucher && shoppingCartArray.voucher.valid) {
          handleVerifyExistingVoucher(shoppingCartArray);
        }

        if (shoppingCartArray.items.filter((item) => item.institutional_incentives === 0).length > 0) {
          handleChangePaymentBy(1);
        }

        if (user.id_ars) {
          handleChangeSelectARS(user.id_ars);
        }
        if (user.id_aces) {
          handleChangeSelectACES(user.id_aces);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [open]);

  function handleCalcTotal() {
    let total = 0;
    let itemsShoppingCart = shoppingCart.items;
    for (let i = 0; i < itemsShoppingCart.length; i++) {
      total += itemsShoppingCart[i].newTotal ? Number(itemsShoppingCart[i].newTotal) : Number(itemsShoppingCart[i].total);
    }

    return parseFloat(total).toFixed(2);
  }

  function handleRemoveItem(data) {
    let shoppingCartObject = JSON.parse(JSON.stringify(shoppingCart));
    let shoppingCartArray = shoppingCartObject.items;
    let index = shoppingCartArray.findIndex((value) => value.id === data.id);
    if (shoppingCartArray.length === 0) {
      form.setFieldsValue({});
    }

    let event_data = JSON.parse(JSON.stringify(shoppingCartArray[index]));
    let event_options = [];
    for (let z = 0; z < event_data.options.length; z++) {
      if (event_data.options[z].qtd >= 0) {
        event_options.push(event_data.options[z]);
      }
    }

    axios
      .post(endpoints.event.updateStock, {
        data: {
          event_data: event_data,
          event_options: event_options,
          user_data: user,
          type: "add_quantity",
        },
      })
      .then((res) => {
        shoppingCartArray.splice(index, 1);
        shoppingCartObject.items = shoppingCartArray;

        let products_with_voucher = false;

        for (let i = 0; i < shoppingCartObject.items.length; i++) {
          if (shoppingCartObject.items[i].discount) {
            products_with_voucher = true;
          }
        }

        let update_stock_voucher = false;
        if (shoppingCartObject.items.length === 0 || !products_with_voucher) {
          shoppingCartObject.voucher = {};
          update_stock_voucher = true;
        }

        if (update_stock_voucher && shoppingCart.voucher.valid) {
          axios
            .post(endpoints.voucher.updateStockVoucher, {
              data: { voucher_data: shoppingCart.voucher },
            })
            .then((res) => {
              setShoppingCart(shoppingCartObject);
              handleUpdateShoppingCart(shoppingCartObject);
              notification.success({
                message: `Evento removido do carrinho`,
                description: "O evento foi removido do carrinho com sucesso!",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setShoppingCart(shoppingCartObject);
          handleUpdateShoppingCart(shoppingCartObject);
          notification.success({
            message: `Evento removido do carrinho`,
            description: "O evento foi removido do carrinho com sucesso!",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: `Erro`,
          description: "Aconteceu algum erro, por favor tente novamente",
        });
      });
  }

  function handleEditItem(data) {
    close(shoppingCart);
    navigate(`/eventDetails/${data.id}`);
  }

  function handleNavigateToEvents() {
    close(shoppingCart);
    navigate(`/#eventos`);
  }

  function handleNavigateToProfile() {
    close(shoppingCart);
    navigate(`/profile`);
  }

  function handleCloseDrawer() {
    if (currentStep === 1) {
      close();
      form.resetFields();
      setCurrentStep(0);
      handleUpdateShoppingCart({ voucher: {}, items: [] });
      navigate("/");
    } else {
      close(shoppingCart);
    }
    setIsLoadingButton(false);
  }

  function handleGeneratePayment(values, order_id) {
    return new Promise((resolve, reject) => {
      let value_price = handleCalcTotal();

      let data = {
        type: "events",
        userId: user.id,
        orderId: order_id,
        amount: value_price,
        description: "Pagamento de Eventos",
        phone: values.payment_phone,
        userName: values.name,
        userEmail: values.email,
        expiryDays: "3",
      };

      let url = values.payment_method === "mb" ? endpoints.purchase.purchaseMB : endpoints.purchase.purchaseMBWay;

      axios
        .post(url, {
          data: data,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  function handleSubmitShoppingCart(values) {
    setIsLoadingButton(true);
    let userId = user.id;

    do {
      userId = `0${userId}`;
    } while (userId.length < 4);

    let order_id = `E${shoppingCart.items[0].id}${userId}`;

    let invoice_items = [];
    let eventsIds = [];
    let eventsOptionsIds = [];

    let shoppingCartItems = shoppingCart.items;

    for (let i = 0; i < shoppingCartItems.length; i++) {
      eventsIds.push(shoppingCartItems[i].id);
      let auxObject = JSON.parse(shoppingCartItems[i].price[0].invoice_item);
      auxObject.quantity = shoppingCartItems[i].price[0].qtd;
      if (shoppingCartItems[i].discount) {
        auxObject.discount = shoppingCartItems[i].discount_percentage;
      }
      invoice_items.push(auxObject);
      for (let z = 0; z < shoppingCartItems[i].options.length; z++) {
        if (shoppingCartItems[i].options[z].qtd > 0) {
          eventsOptionsIds.push(shoppingCartItems[i].options[z].id);
          let auxOptionObject = JSON.parse(shoppingCartItems[i].options[z].invoice_item);
          auxOptionObject.quantity = shoppingCartItems[i].options[z].qtd;
          if (shoppingCartItems[i].discount) {
            auxOptionObject.discount = shoppingCartItems[i].discount_percentage;
          }
          invoice_items.push(auxOptionObject);
        }
      }
    }

    if (values.payment_by === 1 && parseInt(handleCalcTotal()) > 0) {
      console.log(handleCalcTotal());
      handleGeneratePayment(values, order_id)
        .then((res) => {
          setPaymentData(res);
          let auxData = {
            id_user: user.id,
            date: moment().format("YYYY-MM-DD"),
            type: "events",
            payment_by: "O(a) próprio(a)",
            id_event: JSON.stringify(eventsIds),
            method: values.payment_method,
            entity: values.payment_method === "mb" ? res.Entity : null,
            reference: values.payment_method === "mb" ? res.Reference : null,
            phone_number: values.payment_method === "mbway" ? values.payment_phone : null,
            value: res.Amount,
            process_id: order_id,
            payment_id: res.RequestId,
            payment_expiry_date: values.payment_method === "mb" ? res.ExpiryDate : null,
            status: "em validação",
            invoice_items: JSON.stringify(invoice_items),
            shopping_cart: JSON.stringify(shoppingCart),
          };

          axios
            .post(endpoints.purchase.postPurchase, {
              data: {
                userData: user,
                paymentData: auxData,
                type: "user",
                voucherData: shoppingCart.voucher.valid ? shoppingCart.voucher : null,
              },
            })
            .then((res) => {
              axios
                .post(endpoints.event.createEventUser, {
                  data: {
                    user_data: user,
                    event_data: auxData,
                    payment_data: res.data,
                  },
                })
                .then((res) => {
                  notification.success({
                    message: `Compra efetuada`,
                    description: "Os dados do pagamento irão aparecer no próximo passo! Para depois consultar estará presente na sua listagem de eventos.",
                  });
                  setCurrentStep(1);
                  setIsLoadingButton(false);
                  localStorage.removeItem("shopping_cart");
                });
            });
        })
        .catch((err) => {
          console.log(err);
          notification.error({
            message: `Erro!`,
            description: "Algo de errado aconteceu! Por favor, tente novamente! Se o erro persistir contacte-nos a partir do nosso website.",
          });
          setIsLoadingButton(false);
        });
    } else {
      console.log("ELSE");
      console.log(handleCalcTotal());
      let auxData = {
        id_user: user.id,
        date: moment().format("YYYY-MM-DD"),
        type: "events",
        payment_by: values.payment_by === 1 ? "O(a) próprio(a)" : "Icentivos instituicionais",
        id_ars: values.id_ars,
        id_aces: values.id_aces,
        id_usf: values.id_usf,
        id_event: JSON.stringify(eventsIds),
        process_id: order_id,
        value: handleCalcTotal(),
        status: "pago",
        invoice_items: JSON.stringify(invoice_items),
        shopping_cart: JSON.stringify(shoppingCart),
      };
      let auxDataUser = { ...auxData, user };

      axios
        .post(endpoints.purchase.postPurchase, {
          data: {
            userData: user,
            paymentData: auxData,
            type: "user",
            voucherData: shoppingCart.voucher.valid ? shoppingCart.voucher : null,
          },
        })
        .then((res) => {
          let payment_inserted = res.data;
          axios
            .post(endpoints.event.createEventUser, {
              data: {
                user_data: user,
                event_data: auxData,
                payment_data: payment_inserted,
              },
            })
            .then((result) => {
              if (parseInt(handleCalcTotal()) === 0 && values.payment_by === 1) {
                axios
                  .post(endpoints.invoice.createInvoice, {
                    data: { data: auxDataUser, paymentData: payment_inserted },
                  })
                  .then((res) => {
                    notification.success({
                      message: `Compra efetuada`,
                      description: "A compra foi efetuado com sucesso. A fatura já se encontra na sua lista de eventos!",
                    });
                    setCurrentStep(1);
                    setIsLoadingButton(false);
                    localStorage.removeItem("shopping_cart");
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsLoadingButton(false);
                  });
              } else {
                notification.success({
                  message: `Compra efetuada`,
                  description: "Os dados do pagamento irão aparecer no próximo passo! Para depois consultar estará presente na sua listagem de eventos.",
                });
                setCurrentStep(1);
                setIsLoadingButton(false);
              }
            });
        });
    }
  }

  function handleChangeVoucher(e) {
    let shoppingCartAux = JSON.parse(JSON.stringify(shoppingCart));
    shoppingCartAux.voucher.name = e.target.value;
    setShoppingCart(shoppingCartAux);
  }

  function handleVerifyExistingVoucher(shoppingCartAux) {
    axios
      .get(endpoints.voucher.getSingleVoucher, {
        params: { id: shoppingCartAux.voucher.id },
      })
      .then((res) => {
        let voucher_data = res.data[0][0];

        let newTotal = 0;
        let allEvents = [];
        for (let i = 0; i < shoppingCartAux.items.length; i++) {
          allEvents.push(shoppingCartAux.items[i].id);
        }

        let eventsIds = voucher_data.events ? JSON.parse(voucher_data.events) : allEvents;

        for (let z = 0; z < eventsIds.length; z++) {
          let index = shoppingCartAux.items.findIndex((item) => item.id === eventsIds[z]);
          if (index >= 0) {
            if (!shoppingCartAux.items[index].discount) {
              if (voucher_data.voucher_type === "Percentagem") {
                let percentageToValue = (Number(shoppingCartAux.items[index].total) / 100) * Number(voucher_data.value);
                newTotal = Number(shoppingCartAux.items[index].total) - parseFloat(percentageToValue).toFixed(2);
                shoppingCartAux.items[index].newTotal = parseFloat(newTotal).toFixed(2);
                shoppingCartAux.items[index].discount = true;
                shoppingCartAux.items[index].discount_value = percentageToValue;
                shoppingCartAux.items[index].discount_percentage = voucher_data.value;
              } else {
                newTotal = Number(shoppingCartAux.items[index].total) - Number(voucher_data.value);
                let percentage = 100 - (newTotal * 100) / shoppingCartAux.items[index].total;
                shoppingCartAux.items[index].newTotal = newTotal >= 0 ? newTotal : 0;
                shoppingCartAux.items[index].discount = true;
                shoppingCartAux.items[index].discount_value = voucher_data.value;
                shoppingCartAux.items[index].discount_percentage = newTotal >= 0 ? parseFloat(percentage).toFixed(2) : 100;
              }
            }
          }
        }

        setShoppingCart(shoppingCartAux);
        handleUpdateShoppingCart(shoppingCartAux);
        handleCalcTotal();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleVerifyVoucher() {
    let shoppingCartAux = JSON.parse(JSON.stringify(shoppingCart));

    axios
      .post(endpoints.voucher.verifyVoucher, {
        data: {
          type: "events",
          name: shoppingCartAux.voucher.name,
          items: shoppingCartAux.items,
          user_id: user.id,
          user_id_profession: user.id_profession,
        },
      })
      .then((res) => {
        let voucherVerificationData = res.data;
        if (voucherVerificationData.voucher_exists && voucherVerificationData.voucher_valid) {
          let voucher_data = voucherVerificationData.data.voucherData;

          shoppingCartAux.voucher.valid = true;
          shoppingCartAux.voucher.id = voucher_data.id;
          shoppingCartAux.voucher.type = voucher_data.voucher_type;
          shoppingCartAux.voucher.value = voucher_data.value;

          let newTotal = 0;
          let allEvents = [];
          for (let i = 0; i < shoppingCart.items.length; i++) {
            allEvents.push(shoppingCart.items[i].id);
          }

          let eventsIds = voucher_data.events ? JSON.parse(voucher_data.events) : allEvents;

          for (let z = 0; z < eventsIds.length; z++) {
            let index = shoppingCartAux.items.findIndex((item) => item.id === eventsIds[z]);
            if (index >= 0) {
              if (voucher_data.voucher_type === "Percentagem") {
                let percentageToValue = (Number(shoppingCartAux.items[index].total) / 100) * Number(voucher_data.value);
                newTotal = Number(shoppingCartAux.items[index].total) - parseFloat(percentageToValue).toFixed(2);
                shoppingCartAux.items[index].newTotal = parseFloat(newTotal).toFixed(2);
                shoppingCartAux.items[index].discount = true;
                shoppingCartAux.items[index].discount_value = percentageToValue;
                shoppingCartAux.items[index].discount_percentage = voucher_data.value;
              } else {
                newTotal = Number(shoppingCartAux.items[index].total) - Number(voucher_data.value);
                let percentage = 100 - (newTotal * 100) / shoppingCartAux.items[index].total;
                shoppingCartAux.items[index].newTotal = newTotal >= 0 ? newTotal : 0;
                shoppingCartAux.items[index].discount = true;
                shoppingCartAux.items[index].discount_value = voucher_data.value;
                shoppingCartAux.items[index].discount_percentage = newTotal >= 0 ? parseFloat(percentage).toFixed(2) : 100;
              }
            }
          }

          notification.success({
            message: "Verificação do Voucher",
            description: "Este voucher foi aceite com sucesso",
          });

          setShoppingCart(shoppingCartAux);
          handleUpdateShoppingCart(shoppingCartAux);
          handleCalcTotal();
        } else {
          notification.error({
            message: "Verificação do Voucher",
            description: voucherVerificationData.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangePaymentBy(e) {
    form.setFieldsValue({
      name: user.name,
      phone: user.phone,
      email: user.email,
      nif: user.nif,
      id_ars: user.id_ars,
      id_aces: user.id_aces,
      id_usf: user.id_usf,
      id_profession: user.id_profession,
      payment_by: e.target ? e.target.value : e,
    });
  }

  function handleChangeSelectARS(value) {
    form.setFieldsValue({ aces: null });
    form.setFieldsValue({ usf: null });
    let aux = [];
    for (let i = 0; i < aces.length; i++) {
      if (aces[i].id_ars === value) {
        aux.push(aces[i]);
      }
    }

    setACESFiltered(aux);
  }

  function handleChangeSelectACES(value) {
    form.setFieldsValue({ usf: null });
    let aux = [];
    for (let i = 0; i < usf.length; i++) {
      if (usf[i].id_aces === value) {
        aux.push(usf[i]);
      }
    }

    setUSFFiltered(aux);
  }

  function handleValidateNIF(value) {
    return new Promise((resolve, reject) => {
      if (!value || value.length !== 9) resolve(false);

      let sumAux = 0;
      for (let i = 9; i >= 2; i--) {
        sumAux += i * (parseInt(value[value.length - i]) || 0);
      }

      const module = sumAux % 11;

      const NIFwithoutLastDigit = value.slice(0, value.length - 1);

      if (module === 0 || module === 1) {
        resolve(`${NIFwithoutLastDigit}0` === value);
      } else {
        resolve(`${NIFwithoutLastDigit}${11 - module}` === value);
      }
    });
  }

  return (
    <Drawer title={`Carrinho`} width={window.innerWidth > 900 ? 900 : "100%"} onClose={handleCloseDrawer} open={open} bodyStyle={{ paddingBottom: 80 }} maskClosable={false}>
      <Spin spinning={isLoading}>
        <Steps
          type="navigation"
          size="small"
          current={currentStep}
          className="shopping-cart-steps"
          items={[
            {
              title: "1",
            },
            {
              title: "2",
            },
          ]}
        />
        {currentStep === 0 ? (
          <>
            {shoppingCart.items.length === 0 ? (
              <div className="empty-shopping-cart">
                <img src={shoppingCartEmpty} />
                <div className="title text-center">O seu carrinho está vazio</div>
                <div className="text text-center">Conheça os nossos eventos e adicione às suas compras</div>
                <div className="button" onClick={() => handleNavigateToEvents()}>
                  Ver eventos
                </div>
              </div>
            ) : (
              <>
                {shoppingCart.items.map((value, index) => {
                  return (
                    <>
                      <Row className={`shopping-cart-item ${index > 0 ? "mt-20" : ""}`}>
                        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="image-info">
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url('${config.server_ip}media/${value.image}')`,
                            }}
                          ></div>
                          <div className="info">
                            <div className="name">{value.name}</div>
                            <div className="price">
                              <div className="price-item">
                                {value.price[0].qtd}x {value.price[0].profession_name} -{" "}
                                {value.price[0].old_price && <span className="old_price">{value.price[0].old_price}€</span>} {value.price[0].price}€
                              </div>
                            </div>
                            <div className="option">
                              {value.options.map((v) => {
                                return (
                                  <>
                                    {v.qtd > 0 && (
                                      <div className="option-item">
                                        {v.qtd}x {v.name} - {v.price}€
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                            {value.promotion && dayjs().diff(dayjs(value.promotion_date), "days") <= 0 ? (
                              <div className="promotion">
                                Promoção válida até {dayjs(value.promotion_date).format("DD/MM/YYYY")}: {value.promotion_value}€
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} className="total-info flex-wrap">
                          <div className="total mobile-w-100 tablet-w-100 mt-10 mb-10">
                            <span className={`price_without_voucher ${value.discount ? "overline" : ""}`}>{value.total}€</span>
                            {value.discount && <span className={"price_with_voucher"}>{value.newTotal}€</span>}
                          </div>
                          <div className="edit-remove mobile-w-100 tablet-w-100 mt-10 mb-10">
                            <div className="edit">
                              <EditFilled className="edit-icon" onClick={() => handleEditItem(value)} />
                            </div>
                            <div className="remove">
                              <DeleteOutlined className="remove-icon" onClick={() => handleRemoveItem(value)} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                })}
                <Divider />
                <Row className="voucher w-100">
                  <div className="voucher_div w-100 d-flex jc-sb">
                    <span className="title w-30 tablet-w-100 mt-10">Aplique aqui o seu voucher</span>
                    <Input
                      className="w-40 tablet-w-100 mt-10"
                      size="large"
                      disabled={shoppingCart.voucher.valid}
                      value={shoppingCart.voucher.name}
                      placeholder="Voucher"
                      onChange={(e) => handleChangeVoucher(e)}
                    />
                    <Button disabled={shoppingCart.voucher.valid} className="submit_voucher w-28 tablet-w-100 mt-10" onClick={() => handleVerifyVoucher()}>
                      Aplicar voucher
                    </Button>
                  </div>
                </Row>
                <Divider />
                <div className="total-all-values w-100">Total: {handleCalcTotal()}€</div>
                <Divider />
                <p className="dados-faturacao-title">Dados de faturação</p>
                <Form form={form} layout="vertical" onFinish={handleSubmitShoppingCart} className="form-shopping-cart-faturacao">
                  <Row gutter={[24]}>
                    <Col span={12}>
                      <Form.Item
                        name="payment_by"
                        label="Quem irá proceder ao pagamento?"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Radio.Group onChange={handleChangePaymentBy}>
                          <Radio value={1}>Pago pelo(a) próprio(a)</Radio>
                          {shoppingCart.items.filter((item) => item.institutional_incentives === 1).length > 0 && <Radio value={2}>Pago pelos incentivos institucionais</Radio>}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_by !== currentValues.payment_by}>
                    {({ getFieldValue }) =>
                      getFieldValue("payment_by") === 1 || shoppingCart.items.filter((item) => item.institutional_incentives === 0).length > 0 ? (
                        <>
                          <Row gutter={[24]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Form.Item
                                name="name"
                                label="Nome"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Input type="text" size="large" placeholder="Nome" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Form.Item
                                name="nif"
                                label="NIF"
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                  {
                                    message: "Tem que colocar 1 NIF válido",
                                    validator: async (_, value) => {
                                      let correctNIF = await handleValidateNIF(value);

                                      if (correctNIF) {
                                        return Promise.resolve();
                                      } else {
                                        return Promise.reject("Tem que colocar 1 NIF válido");
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input type="text" size="large" placeholder="500 000 000" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Input type="text" size="large" placeholder="E-mail" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Form.Item name="phone" label="Telemóvel">
                                <Input type="text" size="large" placeholder="910 000 000" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : getFieldValue("payment_by") === 2 ? (
                        <>
                          <Row gutter={[24]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item
                                name="id_ars"
                                label="ARS"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Select
                                  className="readOnly"
                                  size="large"
                                  showSearch
                                  placeholder="ARS"
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                  {ars.map((value, index) => {
                                    return <Option value={value.id}>{value.name}</Option>;
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item
                                name="id_aces"
                                label="ACeS"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Select
                                  className="readOnly"
                                  size="large"
                                  showSearch
                                  placeholder="ACeS"
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                  {acesFiltered.map((value, index) => {
                                    return <Option value={value.id}>{value.name}</Option>;
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item
                                name="id_usf"
                                label="Unidade"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Select
                                  className="readOnly"
                                  size="large"
                                  showSearch
                                  placeholder="Unidade"
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                  {usfFiltered.map((value, index) => {
                                    return <Option value={value.id}>{value.name}</Option>;
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item
                                name="id_profession"
                                label="Profissão"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Select
                                  className="readOnly"
                                  size="large"
                                  showSearch
                                  placeholder="Profissão"
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                  {professions.map((value, index) => {
                                    return <Option value={value.id}>{value.name}</Option>;
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <p>
                              Os seus dados não estão corretos? Então edite o seu perfil <a onClick={handleNavigateToProfile}>aqui</a> para que coloque os dados da instituição.
                            </p>
                          </Row>
                        </>
                      ) : null
                    }
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_by !== currentValues.payment_by}>
                    {({ getFieldValue }) =>
                      getFieldValue("payment_by") === 1 && (
                        <>
                          <Divider />
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item
                                name="payment_method"
                                label="Selecione o seu método de pagamento"
                                rules={[
                                  {
                                    required: parseInt(handleCalcTotal()) === 0 ? false : true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Radio.Group className="radio-payment-method" disabled={parseInt(handleCalcTotal()) === 0}>
                                  <Radio.Button value={"mb"}>
                                    <img src={mbImage} />
                                  </Radio.Button>
                                  <Radio.Button value={"mbway"}>
                                    <img src={mbWayImage} />
                                  </Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )
                    }
                  </Form.Item>
                  <Row gutter={[24]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}>
                        {({ getFieldValue }) =>
                          getFieldValue("payment_method") === "mbway" && getFieldValue("payment_by") === 1 && parseInt(handleCalcTotal()) !== 0 ? (
                            <Form.Item
                              name="payment_phone"
                              label="Telemóvel pagamento"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="phone" size="large" placeholder="Telemóvel" />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={[24]}>
                    <Col span={24}>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório",
                          },
                        ]}
                      >
                        <Checkbox size="large">
                          Concordar com os{" "}
                          <a target="_blank" href={`https://usf-an.pt/termos-e-condicoes-eventos`}>
                            termos e condições
                          </a>{" "}
                          de venda*
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="col-end mobile-jc-center mt-10">
                      <Button className="payment_button back" key="submit" type="primary" onClick={handleCloseDrawer} disabled={isLoadingButton}>
                        Continuar a comprar
                      </Button>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="mobile-jc-center mt-10">
                      <Button className="payment_button" key="submit" type="primary" onClick={form.submit} loading={isLoadingButton} disabled={isLoadingButton}>
                        Comprar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </>
        ) : (
          <>
            {shoppingCart.items.map((value) => {
              return (
                <>
                  <div className="shopping-cart-item">
                    <div className="image-info">
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${config.server_ip}media/${value.image})`,
                        }}
                      ></div>
                      <div className="info">
                        <div className="name">{value.name}</div>
                        <div className="price">
                          <div className="price-item">
                            {value.price[0].qtd}x {value.price[0].profession_name} - {value.price[0].price}€
                          </div>
                        </div>
                        <div className="option">
                          {value.options.map((v) => {
                            return (
                              <>
                                {v.qtd > 0 && (
                                  <div className="option-item">
                                    {v.qtd}x {v.name} - {v.price}€
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="total-info">
                      <div className="total">
                        <span className={`price_without_voucher ${value.discount ? "overline" : ""}`}>{value.total}€</span>
                        {value.discount && <span className={"price_with_voucher"}>{value.newTotal}€</span>}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <Divider />
            <div className="total-all-values-payment">Total: {handleCalcTotal()}€</div>
            <Divider />
            <p className="dados-faturacao-title">Dados de faturação</p>
            <div className="invoice-data">
              <div className="name">
                <b>Nome:</b> {form.getFieldValue("name")}
              </div>
              <div className="email">
                <b>E-mail:</b> {form.getFieldValue("email")}
              </div>
              <div className="nif">
                <b>NIF:</b> {form.getFieldValue("nif")}
              </div>
              <div className="phone">
                <b>Telemóvel:</b> {form.getFieldValue("payment_phone")}
              </div>
            </div>
            {parseInt(handleCalcTotal()) > 0 && (
              <>
                <p className="payment-title">Dados de pagamento</p>
                {form.getFieldValue("payment_by") === 1 ? (
                  <div className="payment_method_info">
                    {form.getFieldValue("payment_method") === "mb" ? (
                      <>
                        <div className="img">
                          <img src={mbStepImage} />
                        </div>
                        <div className="payment_info_div">
                          <div>
                            <b>Entidade:</b> {paymentData.Entity}
                          </div>
                          <div>
                            <b>Referência:</b> {paymentData.Reference}
                          </div>
                          <div>
                            <b>Montante:</b> {handleCalcTotal()}€
                          </div>
                          <div className="mt-20">
                            <b>Data de expiração:</b> {paymentData.ExpiryDate}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="img">
                          <img src={mbWayImage} />
                        </div>
                        <div className="payment_info_div">
                          <div>
                            <b>Telemóvel:</b> {form.getFieldValue("phone")}
                          </div>
                          <div>
                            <b>Montante:</b> {handleCalcTotal()}€
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="payment_method_info">
                    <div>A informação de pagamento vai ser gerada e enviada para a entidade institucional que apresentou no formulário:</div>
                    <div>{form.getFieldValue("name")}</div>
                    <div>{form.getFieldValue("email")}</div>
                    <div>{form.getFieldValue("nif")}</div>
                    <div>{form.getFieldValue("phone")}</div>
                  </div>
                )}
              </>
            )}
            <Row gutter={[24]} className="button-close-payment">
              <Col span={24} align="center">
                <Button className="payment_button back" key="submit" type="primary" onClick={handleCloseDrawer}>
                  Fechar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Spin>
    </Drawer>
  );
}

export default DrawerShoppingCart;
