import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Input, Button, Divider, List, Radio } from "antd";
import axios from 'axios';
import { useForm } from "antd/es/form/Form";
import endpoints from "../../../utils/endoinpts";
import './Settings.css';

export default function SettingsUnits({ data }) {

  const [isButtonLoadingARS, setIsButtonLoadingARS] = useState(false);
  const [isButtonLoadingACES, setIsButtonLoadingACES] = useState(false);
  const [isButtonLoadingUSF, setIsButtonLoadingUSF] = useState(false);

  const [ars, setARS] = useState([]);
  const [aces, setACES] = useState([]);
  const [usf, setUSF] = useState([]);

  const [filteredARS, setFilteredARS] = useState([]);
  const [filteredACES, setFilteredACES] = useState([]);
  const [filteredUSF, setFilteredUSF] = useState([]);

  const [addARS, setAddARS] = useState(false);
  const [addACES, setAddACES] = useState(false);
  const [addUSF, setAddUSF] = useState(false);

  const [formARS] = Form.useForm();
  const [formACES] = Form.useForm();
  const [formUSF] = Form.useForm();

  useEffect(() => {
    if (data) {
      handleGetData();
    }
  }, [data]);

  function handleGetData() {
    axios.get(endpoints.info.getConfigs).then((res) => {
      setARS(res.data[1]);
      setACES(res.data[2]);
      setUSF(res.data[3]);
      setFilteredARS(res.data[1]);
      handleFilterACES(res.data[2])
      handleFilterUSF(res.data[3]);

      setAddACES(false);
      setAddARS(false);
      setAddUSF(false);

      formARS.setFieldValue('new_name', null)
      formACES.setFieldValue('new_name', null)
      formUSF.setFieldValue('new_name', null)

      setTimeout(() => {
        setIsButtonLoadingARS(false);
        setIsButtonLoadingACES(false);
        setIsButtonLoadingUSF(false);
      }, 1000);
    }).catch((error) => {
      console.log(error);
    })
  }

  function handleSelectARS(e) {
    let arsObject = ars.filter((item) => item.id === e.target.value)[0]
    formARS.setFieldValue('id', e.target.value);
    formARS.setFieldValue('name', arsObject.name);

    formACES.setFieldValue('id', null);
    formUSF.setFieldValue('id', null);

    handleFilterACES(aces);
  }

  function handleSelectACES(e) {
    let acesObject = aces.filter((item) => item.id === e.target.value)[0]
    formACES.setFieldValue('id', e.target.value);
    formACES.setFieldValue('name', acesObject.name);

    formUSF.setFieldValue('id', null);

    handleFilterUSF(usf);
  }

  function handleSelectUSF(e) {
    let usfObject = usf.filter((item) => item.id === e.target.value)[0]
    formUSF.setFieldValue('id', e.target.value);
    formUSF.setFieldValue('name', usfObject.name);
  }

  function handleClearARS(e) {
    formARS.setFieldValue('id', null)
    formARS.setFieldValue('name', null)
    formACES.setFieldValue('id', null)
    formACES.setFieldValue('name', null)
    formUSF.setFieldValue('id', null)
    formUSF.setFieldValue('name', null)
    setFilteredACES([])
    setFilteredUSF([])
    setAddARS(false);
  }

  function handleClearACES(e) {
    formACES.setFieldValue('id', null)
    formACES.setFieldValue('name', null)
    formUSF.setFieldValue('id', null)
    formUSF.setFieldValue('name', null)
    setFilteredUSF([])
    setAddACES(false);
  }

  function handleClearUSF(e) {
    formUSF.setFieldValue('id', null)
    formUSF.setFieldValue('name', null)
    setAddACES(false);
  }

  function handleFilterACES(auxACeS) {
    let arsID = formARS.getFieldValue('id');
    if (arsID) {
      let auxFilteredACES = auxACeS.filter((item) => item.id_ars === arsID);
      setFilteredACES(auxFilteredACES);
    }
  }

  function handleFilterUSF(auxUSF) {
    let acesID = formACES.getFieldValue('id');
    if (acesID) {
      let auxFilteredUSF = auxUSF.filter((item) => item.id_aces === acesID);
      setFilteredUSF(auxFilteredUSF);
    }
  }

  function handleSubmitARS(e) {
    setIsButtonLoadingARS(true);
    let url = addARS ? endpoints.info.createARS : endpoints.info.updateARS;

    let aux = {
      name: addARS ? e.new_name : e.name
    }

    if (!addARS) {
      aux = { ...aux, id: formARS.getFieldValue('id') }
    }

    axios.post(url, {
      data: aux
    }).then((res) => {
      handleGetData();
    }).catch((err) => {
      console.log(err);
      setIsButtonLoadingARS(false);
    })
  }

  function handleSubmitACES(e) {
    setIsButtonLoadingACES(true);
    let url = addACES ? endpoints.info.createACES : endpoints.info.updateACES

    let aux = {
      name: e.new_name,
      id_ars: formARS.getFieldValue('id'),
    }

    if (!addACES) {
      aux = {
        name: e.name,
        id: formACES.getFieldValue('id')
      }
    }

    console.log(aux);

    axios.post(url, {
      data: aux
    }).then((res) => {
      handleGetData()
      setIsButtonLoadingACES(false);
    }).catch((err) => {
      console.log(err);
      setIsButtonLoadingACES(false);
    })
  }

  function handleSubmitUSF(e) {
    setIsButtonLoadingUSF(true);

    let url = addUSF ? endpoints.info.createUSF : endpoints.info.updateUSF

    let aux = {
      name: e.new_name,
      id_aces: formACES.getFieldValue('id')
    }

    if (!addUSF) {
      aux = {
        name: e.name,
        id: formUSF.getFieldValue('id')
      }
    }

    axios.post(url, {
      data: aux
    }).then((res) => {
      handleGetData()
      setIsButtonLoadingUSF(false);
    }).catch((err) => {
      console.log(err);
      setIsButtonLoadingUSF(false);
    })
  }

  return (
    <Row className="dash_card w-100">
      <p className="settings_title">ARS/ACeS/Unidades</p>
      <Divider className="settings_divider" />
      <Row gutter={24} className="w-100">
        <Col span={8}>
          <Form
            form={formARS}
            layout="vertical"
            onFinish={handleSubmitARS}
          >

            <div className="d-flex jc-sb ai-center">
              <p className="bold">ARS</p>
              <span className="hover-cursor" onClick={handleClearARS}>Clear selection</span>
            </div>
            <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflow: 'auto',
                border: '1px solid rgba(140, 140, 140, 0.1)',
              }}
            >
              {filteredARS.length === 0 ?
                <div className="empty p-20 text-center">No data to show</div>
                :
                <Form.Item name="id">
                  <Radio.Group onChange={handleSelectARS} size="large" buttonStyle="solid" className="d-flex j-center ai-center flex-wrap units_radio">
                    {filteredARS.map((item) =>
                      <Radio.Button className="w-100 border-radius-0" value={item.id}>
                        {item.name}
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </Form.Item>
              }
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.id !== currentValues.id}
            >
              {({ getFieldValue }) =>
                (getFieldValue('id')) && (
                  <>
                    <Form.Item
                      className="mt-20"
                      name="name"
                      label="Nome"
                    >
                      <Input size="large" placeholder='Nome' />
                    </Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      className="w-100"
                      disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                      loading={isButtonLoadingARS}
                      onClick={formARS.submit}
                    >
                      Guardar alterações
                    </Button>
                  </>
                )}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.id !== currentValues.id}
            >
              {({ getFieldValue }) =>
                (!getFieldValue('id')) && (
                  <>
                    <Button
                      size="large"
                      type="dashed"
                      className="w-100 mt-20"
                      disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                      onClick={() => setAddARS(!addARS)}
                    >
                      {!addARS ? 'Adicionar' : 'Cancelar'}
                    </Button>
                    {addARS &&
                      <>
                        <Form.Item
                          className="mt-20"
                          name="new_name"
                          label="Nome"
                        >
                          <Input size="large" placeholder='Nome' />
                        </Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          className="w-100"
                          disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                          loading={isButtonLoadingARS}
                          onClick={formARS.submit}
                        >
                          Adicionar
                        </Button>
                      </>
                    }
                  </>
                )}
            </Form.Item>
          </Form>
        </Col>






        <Col span={8}>
          <Form
            form={formACES}
            layout="vertical"
            onFinish={handleSubmitACES}
          >
            <div className="d-flex jc-sb ai-center">
              <p className="bold">ACeS</p>
              <span className="hover-cursor" onClick={handleClearACES}>Clear selection</span>
            </div>
            <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflow: 'auto',
                border: '1px solid rgba(140, 140, 140, 0.1)',
              }}
            >
              {filteredACES.length === 0 ?
                <div className="empty p-20 text-center light-grey">No data to show</div>
                :
                <Form.Item name="id">
                  <Radio.Group onChange={handleSelectACES} size="large" buttonStyle="solid" className="d-flex j-center ai-center flex-wrap units_radio">
                    {filteredACES.map((item) =>
                      <Radio.Button className="w-100 border-radius-0" value={item.id}>
                        {item.name}
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </Form.Item>
              }
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.id !== currentValues.id}
            >
              {({ getFieldValue }) =>
                (getFieldValue('id')) && (
                  <>
                    <Form.Item
                      className="mt-20"
                      name="name"
                      label="Nome"
                    >
                      <Input size="large" placeholder='Nome' />
                    </Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      className="w-100"
                      disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                      loading={isButtonLoadingACES}
                      onClick={formACES.submit}
                    >
                      Guardar alterações
                    </Button>
                  </>
                )}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.id !== currentValues.id}
            >
              {({ getFieldValue }) =>
                (!getFieldValue('id')) && (formARS.getFieldValue('id')) && (
                  <>
                    <Button
                      size="large"
                      type="dashed"
                      className="w-100 mt-20"
                      disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                      onClick={() => setAddACES(!addACES)}
                    >
                      {!addACES ? 'Adicionar' : 'Cancelar'}
                    </Button>
                    {addACES &&
                      <>
                        <Form.Item
                          className="mt-20"
                          name="new_name"
                          label="Nome"
                        >
                          <Input size="large" placeholder='Nome' />
                        </Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          className="w-100"
                          disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                          loading={isButtonLoadingACES}
                          onClick={formACES.submit}
                        >
                          Adicionar
                        </Button>
                      </>
                    }
                  </>
                )}
            </Form.Item>
          </Form>
        </Col>



        <Col span={8}>
          <Form
            form={formUSF}
            layout="vertical"
            onFinish={handleSubmitUSF}
          >

            <div className="d-flex jc-sb ai-center">
              <p className="bold">Unidades</p>
              <span className="hover-cursor" onClick={handleClearUSF}>Clear selection</span>
            </div>
            <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflow: 'auto',
                border: '1px solid rgba(140, 140, 140, 0.1)',
              }}
            >
              {filteredUSF.length === 0 ?
                <div className="empty p-20 text-center light-grey">No data to show</div>
                :
                <Form.Item name="id">
                  <Radio.Group onChange={handleSelectUSF} size="large" buttonStyle="solid" className="d-flex j-center ai-center flex-wrap units_radio">
                    {filteredUSF.map((item) =>
                      <Radio.Button className="w-100 border-radius-0" value={item.id}>
                        {item.name}
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </Form.Item>
              }
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.id !== currentValues.id}
            >
              {({ getFieldValue }) =>
                (getFieldValue('id')) && (
                  <>
                    <Form.Item
                      className="mt-20"
                      name="name"
                      label="Nome"
                    >
                      <Input size="large" placeholder='Nome' />
                    </Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      className="w-100"
                      disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                      loading={isButtonLoadingUSF}
                      onClick={formUSF.submit}
                    >
                      Guardar alterações
                    </Button>
                  </>
                )}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.id !== currentValues.id}
            >
              {({ getFieldValue }) =>
                (!getFieldValue('id')) && (formACES.getFieldValue('id')) &&
                <>
                  <Button
                    size="large"
                    type="dashed"
                    className="w-100 mt-20"
                    disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                    onClick={() => setAddUSF(!addUSF)}
                  >
                    {!addUSF ? 'Adicionar' : 'Cancelar'}
                  </Button>
                  {addUSF &&
                    <>
                      <Form.Item
                        className="mt-20"
                        name="new_name"
                        label="Nome"
                      >
                        <Input size="large" placeholder='Nome' />
                      </Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        className="w-100"
                        disabled={isButtonLoadingARS || isButtonLoadingACES || isButtonLoadingUSF}
                        loading={isButtonLoadingUSF}
                        onClick={formUSF.submit}
                      >
                        Adicionar
                      </Button>
                    </>
                  }
                </>
              }
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Row >
  );
}
