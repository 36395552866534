import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, notification, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import mbImage from "../../../../assets/icons/MB.svg";
import mbWayImage from "../../../../assets/icons/MB-Way.svg";
import bankImage from "../../../../assets/icons/Bank.svg";
import endpoints from "../../../../utils/endoinpts";
import "./Update.css";

const columns = [
  {
    title: "Data",
    dataIndex: "date",
    key: "date",
    width: 120,
  },
  {
    title: "Método",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    title: "Desrição",
    dataIndex: "invoice_item_description",
    key: "invoice_item_description",
  },
  {
    title: "Valor",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Pagamento por",
    dataIndex: "payment_by",
    key: "payment_by",
  },
  {
    title: "Data de pagamento",
    dataIndex: "payment_date",
    key: "payment_date",
    width: 120,
  },
  {
    title: "Expira",
    dataIndex: "payment_valid_date",
    key: "payment_valid_date",
    width: 120,
  },
  {
    title: "Estado",
    dataIndex: "statusHTML",
    key: "statusHTML",
    width: 150,
  },
  {
    title: "Fatura",
    dataIndex: "invoice_link",
    key: "invoice_link",
  },
  {
    title: "",
    dataIndex: "edit_payment",
    key: "edit_payment",
    width: 140,
  },
];

export default function PaymentEventsData({ admin, activeKey, user_id }) {
  const [paymentData, setPaymentData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isOpenModalPay, setIsOpenModalPay] = useState(false);
  const [isOpenModalCancelPay, setIsOpenModalCancelPay] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedPaymentData, setSelectedPaymentData] = useState({});

  useEffect(() => {
    if (user_id && activeKey === 3) {
      handleGetUserPaymentData();
    }
  }, [activeKey, user_id]);

  function handleGetUserPaymentData() {
    setIsTableLoading(true);
    axios
      .get(endpoints.purchase.getPurchasesEvents, {
        params: { id_user: user_id },
      })
      .then((res) => {
        let paymentServerData = res.data;
        let aux = [];
        for (let i = 0; i < paymentServerData.length; i++) {
          let invoice_items_json = paymentServerData[i].invoice_items
            ? JSON.parse(paymentServerData[i].invoice_items)
            : null;

          let next_payment_date = null;
          let daysAccess = null;
          if (paymentServerData[i].method === "debit") {
            next_payment_date = dayjs(paymentServerData[i].date);
            let today = dayjs().subtract(3, "day");
            daysAccess = today.diff(next_payment_date, "day");
          }

          let auxObject = {
            date: dayjs(paymentServerData[i].date).format("DD-MM-YYYY"),
            payment_method: (
              <div className="payment_method">
                <img
                  src={
                    paymentServerData[i].method === "mb"
                      ? mbImage
                      : paymentServerData[i].method === "mbway"
                      ? mbWayImage
                      : null
                  }
                />
              </div>
            ),
            payment_date: paymentServerData[i].payment_date
              ? dayjs(paymentServerData[i].payment_date).format("YYYY-MM-DD")
              : "-",
            payment_valid_date: paymentServerData[i].payment_valid_date
              ? dayjs(paymentServerData[i].payment_valid_date).format(
                  "YYYY-MM-DD"
                )
              : "-",
            statusHTML: (
              <div
                className={`status ${
                  paymentServerData[i].status === "pago" ? "pago" : "processing"
                }`}
              >
                {paymentServerData[i].status}
              </div>
            ),
            value: paymentServerData[i].value + "€",
            payment_by: paymentServerData[i].payment_by,
            invoice_item_description: invoice_items_json
              ? invoice_items_json[0].description
              : "-",
            invoice_link: paymentServerData[i].invoice_link ? (
              <Button target="_blank" href={paymentServerData[i].invoice_link}>
                <LinkOutlined />
              </Button>
            ) : (
              "-"
            ),
            description: paymentServerData[i].description,
            edit_payment: !admin.is_viewer ? (
              paymentServerData[i].payment_by ===
              "Icentivos instituicionais" ? (
                <Button
                  className="mr-10"
                  type="primary"
                  disabled={
                    paymentServerData[i].status === "pago" ||
                    paymentServerData[i].status === "cancelado"
                  }
                  onClick={() => handleOpenModalPay(paymentServerData[i])}
                >
                  <CheckCircleOutlined />
                </Button>
              ) : paymentServerData[i].status === "em validação" ? (
                <Button
                  danger
                  disabled={
                    paymentServerData[i].status === "pago" ||
                    paymentServerData[i].status === "cancelado"
                  }
                  onClick={() => handleOpenModalCancelPay(paymentServerData[i])}
                >
                  <DeleteOutlined />
                </Button>
              ) : null
            ) : null,
          };
          aux.push(auxObject);
        }
        setPaymentData(paymentServerData);
        setTableData(aux);
        setIsTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUserPay() {
    setIsTableLoading(true);
    setIsButtonLoading(true);
    axios
      .post(endpoints.purchase.verifyPurchaseDebitManual, {
        data: selectedPaymentData,
      })
      .then((res) => {
        if (res.data.errors) {
          let message = "";
          let errors = res.data.errors;
          for (let i = 0; i < errors.length; i++) {
            if (i === errors.length - 1) {
              message += errors[i].error;
            } else {
              message += errors[i].error + ", ";
            }
          }

          notification.error({
            message: "Erro",
            description: `Aconteceram os seguintes erros: "${message}". Verifique se a informação está toda correta `,
            placement: "top",
          });
          setIsOpenModalPay(false);
          setIsTableLoading(false);
          setIsButtonLoading(false);
        } else {
          setIsOpenModalPay(false);
          handleGetUserPaymentData();
          setIsTableLoading(false);
          setIsButtonLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUserCancelPay() {
    setIsTableLoading(true);
    axios
      .post(endpoints.purchase.paymentUserCancel, {
        data: selectedPaymentData,
      })
      .then((res) => {
        setIsOpenModalCancelPay(false);
        handleGetUserPaymentData();
        setIsTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOpenModalPay(data) {
    setSelectedPaymentData(data);
    setIsOpenModalPay(true);
  }

  function handleOpenModalCancelPay(data) {
    setSelectedPaymentData(data);
    setIsOpenModalCancelPay(true);
  }

  return (
    <>
      <Row className="edit_profile_content drawer">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={tableData}
            loading={isTableLoading}
          />
        </Col>
      </Row>
      <Modal
        className="user-payment-modal"
        open={isOpenModalPay}
        onCancel={() => setIsOpenModalPay(false)}
        title="Validar pagamento"
        footer={[
          <Button
            className="payment_button"
            key="submit"
            type="primary"
            onClick={handleUserPay}
            loading={isButtonLoading}
          >
            Sim
          </Button>,
          <Button
            className="payment_button back"
            key="submit"
            type="primary"
            onClick={() => setIsOpenModalPay(false)}
          >
            Não
          </Button>,
        ]}
        maskClosable={false}
      >
        <Row>
          <Col span={24}>Tem a certeza que deseja validar este pagamento?</Col>
        </Row>
      </Modal>

      <Modal
        className="user-payment-modal"
        open={isOpenModalCancelPay}
        onCancel={() => setIsOpenModalCancelPay(false)}
        title="Cancelar pagamento"
        footer={[
          <Button
            className="payment_button"
            key="submit"
            type="primary"
            onClick={handleUserCancelPay}
          >
            Sim
          </Button>,
          <Button
            className="payment_button back"
            key="submit"
            type="primary"
            onClick={() => setIsOpenModalCancelPay(false)}
          >
            Não
          </Button>,
        ]}
        maskClosable={false}
      >
        <Row>
          <Col span={24}>Tem a certeza que deseja cancelar este pagamento?</Col>
        </Row>
      </Modal>
    </>
  );
}
