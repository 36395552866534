import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Spin } from "antd";
import axios from 'axios';
import moment from 'moment';

import GuestBar from "../../../layout/GuestBar/GuestBar";
import GuestListEvents from "../../../components/GuestComponents/GuestListEvents/GuestListEvents";
import './Guest.css';

function Guest() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)
    }, []);

    return (
        <Row className="w-100 dashboard">
            <Col span={24}>
                <Spin spinning={isLoading}>
                    <Col span={24}>
                        <GuestBar />
                        <Row className="ml-0 mr-0 content">
                            <Col span={24}>
                                <GuestListEvents />
                            </Col>
                        </Row>
                    </Col>
                </Spin>
            </Col>
        </Row>
    );
}

export default Guest;
