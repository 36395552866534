import { useState, useEffect } from "react";
import { Col, Row, Button, Modal } from "antd";
import axios from "axios";

import endpoints from "../../../../utils/endoinpts";

import "./Delete.css";

export default function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [event, setEvent] = useState({});

  useEffect(() => {
    setEvent(data);
  }, [data]);

  function handleDeleteEvent() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.event.delete, {
        data: event,
      })
      .then((res) => {
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  return (
    <Modal
      title={`Apagar evento`}
      open={open}
      onCancel={close}
      className="delete_events_modal"
      closable={true}
      maskClosable={false}
      footer={[
        <Button
          className="payment_button"
          type="primary"
          onClick={handleDeleteEvent}
          loading={isButtonLoading}
        >
          Sim
        </Button>,
        <Button
          className="payment_button back"
          type="primary"
          onClick={() => close()}
        >
          Não
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Row>Deseja mesmo apagar o seguinte evento?</Row>
          <Row>
            <span className="event_name">{event ? event.name : ""}</span>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
