import { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Modal, Tabs } from "antd";
import axios from 'axios';
import './Activate.css';
import endpoints from "../../../../utils/endoinpts";


export default function Activate({ user, open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldValue("id", user.id);
    }
  }, [user])

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.app.activateUser, {
      data: values
    }).then((res) => {
      console.log(res);
      setIsButtonLoading(false);
      close();
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
    })
  }

  return (
    <Modal
      title={`Ativar utilizador`}
      open={open}
      onCancel={close}
      className="view_payment_modal"
      closable={true}
      maskClosable={false}
      footer={[
        <Button disabled={isButtonLoading} size="large" onClick={close}>
          Cancelar
        </Button>,
        <Button loading={isButtonLoading} size="large" type="primary" onClick={form.submit}>
          Ativar
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
      <p className="mt-0">Tem a certeza que deseja <b>ativar</b> o seguinte associado?</p>
      <p className="m-0">Nome: {user.name}</p>
      <p className="m-0">E-mail: {user.email}</p>
      <p className="mt-0">Sócio: {user.n_member}</p>
    </Modal>
  );
}
