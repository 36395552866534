import { Col, Modal, Row } from "antd";
import mbStepImage from "../../../assets/icons/MB-Step.svg";
import mbWayImage from "../../../assets/icons/MB-Way.svg";
import bankImage from "../../../assets/icons/Bank.svg";
import "./ModalPaymentInfo.css";

function ModalPaymentInfo({ show, cancel, payment }) {
  return (
    <>
      <Modal
        className="payment_options"
        open={show}
        onCancel={cancel}
        closable={true}
        footer={[]}
      >
        {payment && (
          <>
            <Row>
              <Col span={24}>
                <p className="title mt-0">Dados para pagamento:</p>
                <Row className="step_reference" gutter={[24]}>
                  <Col
                    span={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <img
                      src={
                        payment.method === "mb"
                          ? mbStepImage
                          : payment.method === "mbway"
                          ? mbWayImage
                          : bankImage
                      }
                    />
                  </Col>
                  {payment.method === "mb" && (
                    <Col span={20}>
                      <p className="payment_info">
                        <b>Entidade:</b> {payment.entity}
                      </p>
                      <p className="payment_info">
                        <b>Referência:</b> {payment.reference}
                      </p>
                      <p className="payment_info">
                        <b>Montante:</b> {payment.value}€
                      </p>
                      <p className="payment_info date_expiry">
                        <b>Data limite de pagamento:</b>{" "}
                        {payment.payment_expiry_date}
                      </p>
                    </Col>
                  )}
                  {payment.method === "mbway" && (
                    <Col span={20}>
                      <p className="payment_info">
                        <b>Nº Telemóvel:</b> {payment.phone_number}
                      </p>
                      <p className="payment_info">
                        <b>Montante:</b> {payment.value}€
                      </p>
                    </Col>
                  )}
                  {payment.method === "debit" && (
                    <Col span={20}>
                      <p className="payment_info">
                        <b>IBAN:</b> {payment.iban}
                      </p>
                      <p className="payment_info">
                        <b>Nome do banco:</b> {payment.bank_name}
                      </p>
                      <p className="payment_info">
                        <b>Nome do/a Associado/a:</b> {payment.bank_user_name}
                      </p>
                      <p className="payment_info">
                        <b>Montante:</b> {payment.value}€/
                        {payment.payment_plan_type}
                      </p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
}

export default ModalPaymentInfo;
