import { useEffect, useState } from "react";
import { Drawer, Tabs } from "antd";

import PersonalData from "./PersonalData";
import PaymentData from "./PaymentData";
import PaymentEventsData from "./PaymentEventsData";
import './Update.css';


export default function Update({ admin, user, open, close }) {

  const [activeKey, setActiveKey] = useState(1);

  function handleChangeActiveKey(e) {
    setActiveKey(e);
  }

  function handleCloseDrawer() {
    close();
    setActiveKey(1);
  }

  const itemsTabDrawer = [
    {
      label: `Dados do Sócio`,
      key: 1,
      children: <PersonalData admin={admin} activeKey={activeKey} user_id={user.id} close={handleCloseDrawer} />,
    },
    {
      label: `Pagamento de Quotas`,
      key: 2,
      children: <PaymentData admin={admin} activeKey={activeKey} user_id={user.id} close={handleCloseDrawer} />,
    },
    {
      label: `Pagamento de Eventos`,
      key: 3,
      children: <PaymentEventsData admin={admin} activeKey={activeKey} user_id={user.id} close={handleCloseDrawer} />,
    }
  ];

  return (
    <Drawer
      title={`Editar sócio`}
      width={1400}
      onClose={handleCloseDrawer}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      maskClosable={false}
    >
      <Tabs
        activeKey={activeKey}
        onChange={handleChangeActiveKey}
        items={itemsTabDrawer}
      />
    </Drawer>
  );
}
