import React, { useState, useEffect } from 'react'
import { Col, Row, Space, Input, Table, Avatar, Tooltip, Modal, Button } from 'antd'
import { DeleteOutlined, SearchOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from 'axios';
import moment from 'moment';
import Create from '../Create/Create';
import Update from '../Update/Update';
import Delete from '../Delete/Delete';

import endpoints from '../../../../utils/endoinpts'
import userDefault from '../../../../assets/images/user-default.svg'


import internSVG from "../../../../assets/icons/Intern.svg";
import mgfSVG from "../../../../assets/icons/MGF.svg";
import nurseSVG from "../../../../assets/icons/Nurse.svg";
import othersSVG from "../../../../assets/icons/Others.svg";
import clinicalSVG from "../../../../assets/icons/Clinical-Secretary.svg";
import nonAssociatedSVG from "../../../../assets/icons/non-associated.svg";
import config from '../../../../utils/config';

const { Search } = Input

/* table config */
const columns = [
  {
    title: 'Voucher',
    dataIndex: 'name',
    sorter: (a, b) => a.name.toString().localeCompare(b.name),
  },
  {
    title: 'Data limite',
    dataIndex: 'date_limit',
  },
  {
    title: 'Eventos',
    dataIndex: 'events',
  },
  {
    title: 'Profissões',
    dataIndex: 'professions',
  },
  {
    title: 'Utilizadores',
    dataIndex: 'users',
  },
  {
    title: 'Tipo de voucher',
    dataIndex: 'type',
  },
  {
    title: 'Tipo de valor',
    dataIndex: 'voucher_type',
  },
  {
    title: 'Valor',
    dataIndex: 'value',
  },
  {
    title: 'Qtd',
    dataIndex: 'quantity',
  },
  {
    title: 'Qtd/sócio',
    dataIndex: 'quantity_user',
  },
  {
    title: '',
    dataIndex: 'action',
    width: 120,
  },
]
const imagesProfessions = [mgfSVG, internSVG, nurseSVG, clinicalSVG, othersSVG, nonAssociatedSVG]

export default function List({ user }) {

  const [isLoading, setIsLoading] = useState(true);
  const [vouchers, setVouchers] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isOpenDrawerAddVoucher, setIsOpenDrawerAddVoucher] = useState(false);
  const [isOpenDrawerEditVoucher, setIsOpenDrawerEditVoucher] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [isOpenModalDeleteVoucher, setIsOpenModalDeleteVoucher] = useState(false);

  useEffect(() => {
    handleGetVouchers()
  }, []);

  function handleGetVouchers() {
    setIsLoading(true);
    axios.get(endpoints.voucher.getVouchers).then((res) => {
      let vouchersData = res.data[0];
      let eventsData = res.data[1];
      let professionsData = res.data[2];
      let usersData = res.data[3];

      handlePrepareData(vouchersData, eventsData, usersData, professionsData);
    }).catch((error) => {
      console.log(error)
    });
  }

  /* very important */
  function handlePrepareData(data, eventsData, usersData, professionsData) {

    let newArrayItens = [];

    data.map((item, index) => {
      let eventsArray = JSON.parse(item.events);
      let eventsFiltered = eventsArray ? eventsData.filter((e) => eventsArray.includes(e.id)) : [];
      let usersArray = JSON.parse(item.users);
      let usersFiltered = usersArray ? usersData.filter((e) => usersArray.includes(e.id)) : [];
      let professionsArray = JSON.parse(item.professions);
      let professionsFiltered = professionsArray ? professionsData.filter((e) => professionsArray.includes(e.id)) : [];

      newArrayItens.push({
        key: item.id,
        name: item.name,
        date_limit: moment(item.date_limit).format('DD-MM-YYYY'),
        events: <span>
          <Avatar.Group
            maxCount={2}
            size="large"
            maxPopoverTrigger={''}
            maxStyle={{
              color: '#4A4C4D',
              backgroundColor: '#F3F6F9',
            }}
          >
            {eventsFiltered.map((i) => (
              <Tooltip title={i.name}>
                <Avatar src={`${config.server_ip}media/${i.image}`} />
              </Tooltip>
            ))}
          </Avatar.Group>
        </span>,
        professions: <span>
          <Avatar.Group
            maxCount={2}
            size="large"
            maxPopoverTrigger={''}
            maxStyle={{
              color: '#4A4C4D',
              backgroundColor: '#F3F6F9',
            }}
          >
            {professionsFiltered.map((i, index) => (
              <Tooltip title={i.name}>
                <Avatar src={imagesProfessions[i.id - 1]} />
              </Tooltip>
            ))}
          </Avatar.Group>
        </span>,
        users: <span>
          <Avatar.Group
            maxCount={2}
            size="large"
            maxPopoverTrigger={''}
            maxStyle={{
              color: '#4A4C4D',
              backgroundColor: '#F3F6F9',
            }}
          >
            {usersFiltered.map((i) => (
              <Tooltip title={i.name}>
                <Avatar src={i.img ? `${config.server_ip}media/${i.img}` : userDefault} />
              </Tooltip>
            ))}
          </Avatar.Group>
        </span>,
        voucher_type: item.voucher_type,
        type: item.type === "events" ? 'Eventos' : 'Quotas',
        value: item.voucher_type === 'Percentagem' ? `${item.value}%` : `${item.value}€`,
        quantity: item.quantity,
        quantity_user: item.quantity_user,
        action: <>
          <EditOutlined className='icon icon-edit mr-20' onClick={() => handleSelectVoucher(item)} />
          <DeleteOutlined className='icon icon-delete' onClick={() => handleSelectToDeleteVoucher(item)} />
        </>,
        full_data: item,
      })
    })


    setEvents(eventsData);
    setProfessions(professionsData);
    setUsers(usersData);
    setVouchers(data);
    setTableData(newArrayItens);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  /* search bar action */
  function handleSearch(e) {
    setIsLoading(true);
    setSearchText(e.target.value);

    let vouchersFiltered = vouchers.filter((value, index) => value.name.toLowerCase().includes(e.target.value.toLowerCase()));

    handlePrepareData(vouchersFiltered, events, users, professions);
    setIsLoading(false);
  }

  function handleSelectVoucher(data) {
    setSelectedVoucher(data);
    setIsOpenDrawerEditVoucher(true);
  }

  function handleSelectToDeleteVoucher(data) {
    setSelectedVoucher(data);
    setIsOpenModalDeleteVoucher(true);
  }

  function handleCloseModal() {
    setIsOpenModalDeleteVoucher(false);
    handleGetVouchers();
  }

  function handleCloseDrawer() {
    setIsOpenDrawerAddVoucher(false);
    setIsOpenDrawerEditVoucher(false);
    handleGetVouchers();
  }


  return (
    <Row id='admin-list-events' className='dash_card'>
      <Create open={isOpenDrawerAddVoucher} close={handleCloseDrawer} />
      <Update user={user} voucher={selectedVoucher} open={isOpenDrawerEditVoucher} close={handleCloseDrawer} />
      <Delete data={selectedVoucher} open={isOpenModalDeleteVoucher} close={handleCloseModal} />
      <Col span={24} className='p-20'>
        <Row className='ml-0 mr-0'>
          <Col span={16} className='d-flex align-items-center'>
            <p className='title bold m-0'>Vouchers</p>
          </Col>
          <Col span={8} className='d-flex jc-end'>
            <Row className="d-flex flex-nowrap w-100">
              <Button size='large' className='mr-10' onClick={handleGetVouchers}>
                <ReloadOutlined />
              </Button>
              {!user.is_viewer ?
                <Button className="mr-10" size="large" onClick={() => setIsOpenDrawerAddVoucher(true)}>Adicionar</Button>
                :
                null
              }
              <Input
                allowClear
                type="text"
                size="large"
                onChange={handleSearch}
                className="search_users"
                placeholder="Procurar vouchers"
                suffix={<SearchOutlined />}
                value={searchText}
              />
            </Row>
          </Col>
        </Row>
        <Row className='ml-0 mr-0 mt-20'>
          <Col span={24}>
            <Table
              loading={isLoading}
              className='table'
              dataSource={tableData}
              columns={columns}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
