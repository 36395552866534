import { Outlet, useNavigate } from "react-router-dom";
import { Alert, Col, Layout, Row, message } from "antd";

const { Content, Footer } = Layout;

import logoUSF from "../../assets/images/USF-AN.svg";
import logoAuth from "../../assets/images/logo-usg-login-criar-conta.svg";

import "./AuthLayout.css";
import { useEffect, useState } from "react";
import axios from "axios";
import endpoints from "../../utils/endoinpts";
import Loading from "../Loading/Loading";

export default function AuthLayout() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, []);

  function handleVerifyUser() {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get(endpoints.auth.verifyToken, {
          params: { token },
        })
        .then(async (res) => {
          if (res.data.token_valid) {
            message.success({ content: "Já se encontra logado!" });
            if (res.data.user_data.is_admin) {
              navigate("/admin");
            } else {
              navigate("/");
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <Loading isLoading={isLoading} />}
      <Layout className="auth-layout">
        <Content className="d-flex flex-column jc-center ai-center w-100">
          <Row className="d-flex justify-content-center align-items-center w-100 container">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Row className="d-flex jc-center ai-center">
                <img src={logoUSF} />
              </Row>
              <Row className="d-flex jc-center ai-center w-100">
                <Col span={24}>
                  <Outlet />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="d-flex flex-column jc-center ai-center"
            >
              <img src={logoAuth} style={{ maxWidth: 250 }} />
            </Col>
          </Row>
        </Content>
        <Footer>
          <Row className="w-100">
            <Col span={24} className="text-center footer">
              <p>
                © Copyright 2024. USF-AN. Todos os direitos reservados |{" "}
                <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                  Termos e condições
                </a>{" "}
                |{" "}
                <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                  Política de privacidade
                </a>
              </p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </>
  );
}
