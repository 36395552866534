const districts = [
	{
		"level": 1,
		"code": 1,
		"name": "Aveiro",
		"county": [
			{
				"level": 2,
				"code": 101,
				"name": "Águeda"
			},
			{
				"level": 2,
				"code": 102,
				"name": "Albergaria-a-Velha"
			},
			{
				"level": 2,
				"code": 103,
				"name": "Anadia"
			},
			{
				"level": 2,
				"code": 104,
				"name": "Arouca"
			},
			{
				"level": 2,
				"code": 105,
				"name": "Aveiro"
			},
			{
				"level": 2,
				"code": 106,
				"name": "Castelo de Paiva"
			},
			{
				"level": 2,
				"code": 107,
				"name": "Espinho"
			},
			{
				"level": 2,
				"code": 108,
				"name": "Estarreja"
			},
			{
				"level": 2,
				"code": 109,
				"name": "Santa Maria da Feira"
			},
			{
				"level": 2,
				"code": 110,
				"name": "Ílhavo"
			},
			{
				"level": 2,
				"code": 111,
				"name": "Mealhada"
			},
			{
				"level": 2,
				"code": 112,
				"name": "Murtosa"
			},
			{
				"level": 2,
				"code": 113,
				"name": "Oliveira de Azeméis"
			},
			{
				"level": 2,
				"code": 114,
				"name": "Oliveira do Bairro"
			},
			{
				"level": 2,
				"code": 115,
				"name": "Ovar"
			},
			{
				"level": 2,
				"code": 116,
				"name": "São João da Madeira"
			},
			{
				"level": 2,
				"code": 117,
				"name": "Sever do Vouga"
			},
			{
				"level": 2,
				"code": 118,
				"name": "Vagos"
			},
			{
				"level": 2,
				"code": 119,
				"name": "Vale de Cambra"
			}
		]
	},
	{
		"level": 1,
		"code": 2,
		"name": "Beja",
		"county": [
			{
				"level": 2,
				"code": 201,
				"name": "Aljustrel"
			},
			{
				"level": 2,
				"code": 202,
				"name": "Almodôvar"
			},
			{
				"level": 2,
				"code": 203,
				"name": "Alvito"
			},
			{
				"level": 2,
				"code": 204,
				"name": "Barrancos"
			},
			{
				"level": 2,
				"code": 205,
				"name": "Beja"
			},
			{
				"level": 2,
				"code": 206,
				"name": "Castro Verde"
			},
			{
				"level": 2,
				"code": 207,
				"name": "Cuba"
			},
			{
				"level": 2,
				"code": 208,
				"name": "Ferreira do Alentejo"
			},
			{
				"level": 2,
				"code": 209,
				"name": "Mértola"
			},
			{
				"level": 2,
				"code": 210,
				"name": "Moura"
			},
			{
				"level": 2,
				"code": 211,
				"name": "Odemira"
			},
			{
				"level": 2,
				"code": 212,
				"name": "Ourique"
			},
			{
				"level": 2,
				"code": 213,
				"name": "Serpa"
			},
			{
				"level": 2,
				"code": 214,
				"name": "Vidigueira"
			}
		]
	},
	{
		"level": 1,
		"code": 3,
		"name": "Braga",
		"county": [
			{
				"level": 2,
				"code": 301,
				"name": "Amares"
			},
			{
				"level": 2,
				"code": 302,
				"name": "Barcelos"
			},
			{
				"level": 2,
				"code": 303,
				"name": "Braga"
			},
			{
				"level": 2,
				"code": 304,
				"name": "Cabeceiras de Basto"
			},
			{
				"level": 2,
				"code": 305,
				"name": "Celorico de Basto"
			},
			{
				"level": 2,
				"code": 306,
				"name": "Esposende"
			},
			{
				"level": 2,
				"code": 307,
				"name": "Fafe"
			},
			{
				"level": 2,
				"code": 308,
				"name": "Guimarães"
			},
			{
				"level": 2,
				"code": 309,
				"name": "Póvoa de Lanhoso"
			},
			{
				"level": 2,
				"code": 310,
				"name": "Terras de Bouro"
			},
			{
				"level": 2,
				"code": 311,
				"name": "Vieira do Minho"
			},
			{
				"level": 2,
				"code": 312,
				"name": "Vila Nova de Famalicão"
			},
			{
				"level": 2,
				"code": 313,
				"name": "Vila Verde"
			},
			{
				"level": 2,
				"code": 314,
				"name": "Vizela"
			}
		]
	},
	{
		"level": 1,
		"code": 4,
		"name": "Bragança",
		"county": [
			{
				"level": 2,
				"code": 401,
				"name": "Alfândega da Fé"
			},
			{
				"level": 2,
				"code": 402,
				"name": "Bragança"
			},
			{
				"level": 2,
				"code": 403,
				"name": "Carrazeda de Ansiães"
			},
			{
				"level": 2,
				"code": 404,
				"name": "Freixo de Espada à Cinta"
			},
			{
				"level": 2,
				"code": 405,
				"name": "Macedo de Cavaleiros"
			},
			{
				"level": 2,
				"code": 406,
				"name": "Miranda do Douro"
			},
			{
				"level": 2,
				"code": 407,
				"name": "Mirandela"
			},
			{
				"level": 2,
				"code": 408,
				"name": "Mogadouro"
			},
			{
				"level": 2,
				"code": 409,
				"name": "Torre de Moncorvo"
			},
			{
				"level": 2,
				"code": 410,
				"name": "Vila Flor"
			},
			{
				"level": 2,
				"code": 411,
				"name": "Vimioso"
			},
			{
				"level": 2,
				"code": 412,
				"name": "Vinhais"
			}
		]
	},
	{
		"level": 1,
		"code": 5,
		"name": "Castelo Branco",
		"county": [
			{
				"level": 2,
				"code": 501,
				"name": "Belmonte"
			},
			{
				"level": 2,
				"code": 502,
				"name": "Castelo Branco"
			},
			{
				"level": 2,
				"code": 503,
				"name": "Covilhã"
			},
			{
				"level": 2,
				"code": 504,
				"name": "Fundão"
			},
			{
				"level": 2,
				"code": 505,
				"name": "Idanha-a-Nova"
			},
			{
				"level": 2,
				"code": 506,
				"name": "Oleiros"
			},
			{
				"level": 2,
				"code": 507,
				"name": "Penamacor"
			},
			{
				"level": 2,
				"code": 508,
				"name": "Proença-a-Nova"
			},
			{
				"level": 2,
				"code": 509,
				"name": "Sertã"
			},
			{
				"level": 2,
				"code": 510,
				"name": "Vila de Rei"
			},
			{
				"level": 2,
				"code": 511,
				"name": "Vila Velha de Ródão"
			}
		]
	},
	{
		"level": 1,
		"code": 6,
		"name": "Coimbra",
		"county": [
			{
				"level": 2,
				"code": 601,
				"name": "Arganil"
			},
			{
				"level": 2,
				"code": 602,
				"name": "Cantanhede"
			},
			{
				"level": 2,
				"code": 603,
				"name": "Coimbra"
			},
			{
				"level": 2,
				"code": 604,
				"name": "Condeixa-a-Nova"
			},
			{
				"level": 2,
				"code": 605,
				"name": "Figueira da Foz"
			},
			{
				"level": 2,
				"code": 606,
				"name": "Góis"
			},
			{
				"level": 2,
				"code": 607,
				"name": "Lousã"
			},
			{
				"level": 2,
				"code": 608,
				"name": "Mira"
			},
			{
				"level": 2,
				"code": 609,
				"name": "Miranda do Corvo"
			},
			{
				"level": 2,
				"code": 610,
				"name": "Montemor-o-Velho"
			},
			{
				"level": 2,
				"code": 611,
				"name": "Oliveira do Hospital"
			},
			{
				"level": 2,
				"code": 612,
				"name": "Pampilhosa da Serra"
			},
			{
				"level": 2,
				"code": 613,
				"name": "Penacova"
			},
			{
				"level": 2,
				"code": 614,
				"name": "Penela"
			},
			{
				"level": 2,
				"code": 615,
				"name": "Soure"
			},
			{
				"level": 2,
				"code": 616,
				"name": "Tábua"
			},
			{
				"level": 2,
				"code": 617,
				"name": "Vila Nova de Poiares"
			}
		]
	},
	{
		"level": 1,
		"code": 7,
		"name": "Évora",
		"county": [
			{
				"level": 2,
				"code": 701,
				"name": "Alandroal"
			},
			{
				"level": 2,
				"code": 702,
				"name": "Arraiolos"
			},
			{
				"level": 2,
				"code": 703,
				"name": "Borba"
			},
			{
				"level": 2,
				"code": 704,
				"name": "Estremoz"
			},
			{
				"level": 2,
				"code": 705,
				"name": "Évora"
			},
			{
				"level": 2,
				"code": 706,
				"name": "Montemor-o-Novo"
			},
			{
				"level": 2,
				"code": 707,
				"name": "Mora"
			},
			{
				"level": 2,
				"code": 708,
				"name": "Mourão"
			},
			{
				"level": 2,
				"code": 709,
				"name": "Portel"
			},
			{
				"level": 2,
				"code": 710,
				"name": "Redondo"
			},
			{
				"level": 2,
				"code": 711,
				"name": "Reguengos de Monsaraz"
			},
			{
				"level": 2,
				"code": 712,
				"name": "Vendas Novas"
			},
			{
				"level": 2,
				"code": 713,
				"name": "Viana do Alentejo"
			},
			{
				"level": 2,
				"code": 714,
				"name": "Vila Viçosa"
			}
		]
	},
	{
		"level": 1,
		"code": 8,
		"name": "Faro",
		"county": [
			{
				"level": 2,
				"code": 801,
				"name": "Albufeira"
			},
			{
				"level": 2,
				"code": 802,
				"name": "Alcoutim"
			},
			{
				"level": 2,
				"code": 803,
				"name": "Aljezur"
			},
			{
				"level": 2,
				"code": 804,
				"name": "Castro Marim"
			},
			{
				"level": 2,
				"code": 805,
				"name": "Faro"
			},
			{
				"level": 2,
				"code": 806,
				"name": "Lagoa"
			},
			{
				"level": 2,
				"code": 807,
				"name": "Lagos"
			},
			{
				"level": 2,
				"code": 808,
				"name": "Loulé"
			},
			{
				"level": 2,
				"code": 809,
				"name": "Monchique"
			},
			{
				"level": 2,
				"code": 810,
				"name": "Olhão"
			},
			{
				"level": 2,
				"code": 811,
				"name": "Portimão"
			},
			{
				"level": 2,
				"code": 812,
				"name": "São Brás de Alportel"
			},
			{
				"level": 2,
				"code": 813,
				"name": "Silves"
			},
			{
				"level": 2,
				"code": 814,
				"name": "Tavira"
			},
			{
				"level": 2,
				"code": 815,
				"name": "Vila do Bispo"
			},
			{
				"level": 2,
				"code": 816,
				"name": "Vila Real de Santo António"
			}
		]
	},
	{
		"level": 1,
		"code": 9,
		"name": "Guarda",
		"county": [
			{
				"level": 2,
				"code": 901,
				"name": "Aguiar da Beira"
			},
			{
				"level": 2,
				"code": 902,
				"name": "Almeida"
			},
			{
				"level": 2,
				"code": 903,
				"name": "Celorico da Beira"
			},
			{
				"level": 2,
				"code": 904,
				"name": "Figueira de Castelo Rodrigo"
			},
			{
				"level": 2,
				"code": 905,
				"name": "Fornos de Algodres"
			},
			{
				"level": 2,
				"code": 906,
				"name": "Gouveia"
			},
			{
				"level": 2,
				"code": 907,
				"name": "Guarda"
			},
			{
				"level": 2,
				"code": 908,
				"name": "Manteigas"
			},
			{
				"level": 2,
				"code": 909,
				"name": "Mêda"
			},
			{
				"level": 2,
				"code": 910,
				"name": "Pinhel"
			},
			{
				"level": 2,
				"code": 911,
				"name": "Sabugal"
			},
			{
				"level": 2,
				"code": 912,
				"name": "Seia"
			},
			{
				"level": 2,
				"code": 913,
				"name": "Trancoso"
			},
			{
				"level": 2,
				"code": 914,
				"name": "Vila Nova de Foz Côa"
			}
		]
	},
	{
		"level": 1,
		"code": 10,
		"name": "Leiria",
		"county": [
			{
				"level": 2,
				"code": 1001,
				"name": "Alcobaça"
			},
			{
				"level": 2,
				"code": 1002,
				"name": "Alvaiázere"
			},
			{
				"level": 2,
				"code": 1003,
				"name": "Ansião"
			},
			{
				"level": 2,
				"code": 1004,
				"name": "Batalha"
			},
			{
				"level": 2,
				"code": 1005,
				"name": "Bombarral"
			},
			{
				"level": 2,
				"code": 1006,
				"name": "Caldas da Rainha"
			},
			{
				"level": 2,
				"code": 1007,
				"name": "Castanheira de Pêra"
			},
			{
				"level": 2,
				"code": 1008,
				"name": "Figueiró dos Vinhos"
			},
			{
				"level": 2,
				"code": 1009,
				"name": "Leiria"
			},
			{
				"level": 2,
				"code": 1010,
				"name": "Marinha Grande"
			},
			{
				"level": 2,
				"code": 1011,
				"name": "Nazaré"
			},
			{
				"level": 2,
				"code": 1012,
				"name": "Óbidos"
			},
			{
				"level": 2,
				"code": 1013,
				"name": "Pedrógão Grande"
			},
			{
				"level": 2,
				"code": 1014,
				"name": "Peniche"
			},
			{
				"level": 2,
				"code": 1015,
				"name": "Pombal"
			},
			{
				"level": 2,
				"code": 1016,
				"name": "Porto de Mós"
			}
		]
	},
	{
		"level": 1,
		"code": 11,
		"name": "Lisboa",
		"county": [
			{
				"level": 2,
				"code": 1101,
				"name": "Alenquer"
			},
			{
				"level": 2,
				"code": 1102,
				"name": "Arruda dos Vinhos"
			},
			{
				"level": 2,
				"code": 1103,
				"name": "Azambuja"
			},
			{
				"level": 2,
				"code": 1104,
				"name": "Cadaval"
			},
			{
				"level": 2,
				"code": 1105,
				"name": "Cascais"
			},
			{
				"level": 2,
				"code": 1106,
				"name": "Lisboa"
			},
			{
				"level": 2,
				"code": 1107,
				"name": "Loures"
			},
			{
				"level": 2,
				"code": 1108,
				"name": "Lourinhã"
			},
			{
				"level": 2,
				"code": 1109,
				"name": "Mafra"
			},
			{
				"level": 2,
				"code": 1110,
				"name": "Oeiras"
			},
			{
				"level": 2,
				"code": 1111,
				"name": "Sintra"
			},
			{
				"level": 2,
				"code": 1112,
				"name": "Sobral de Monte Agraço"
			},
			{
				"level": 2,
				"code": 1113,
				"name": "Torres Vedras"
			},
			{
				"level": 2,
				"code": 1114,
				"name": "Vila Franca de Xira"
			},
			{
				"level": 2,
				"code": 1115,
				"name": "Amadora"
			},
			{
				"level": 2,
				"code": 1116,
				"name": "Odivelas"
			}
		]
	},
	{
		"level": 1,
		"code": 12,
		"name": "Portalegre",
		"county": [
			{
				"level": 2,
				"code": 1201,
				"name": "Alter do Chão"
			},
			{
				"level": 2,
				"code": 1202,
				"name": "Arronches"
			},
			{
				"level": 2,
				"code": 1203,
				"name": "Avis"
			},
			{
				"level": 2,
				"code": 1204,
				"name": "Campo Maior"
			},
			{
				"level": 2,
				"code": 1205,
				"name": "Castelo de Vide"
			},
			{
				"level": 2,
				"code": 1206,
				"name": "Crato"
			},
			{
				"level": 2,
				"code": 1207,
				"name": "Elvas"
			},
			{
				"level": 2,
				"code": 1208,
				"name": "Fronteira"
			},
			{
				"level": 2,
				"code": 1209,
				"name": "Gavião"
			},
			{
				"level": 2,
				"code": 1210,
				"name": "Marvão"
			},
			{
				"level": 2,
				"code": 1211,
				"name": "Monforte"
			},
			{
				"level": 2,
				"code": 1212,
				"name": "Nisa"
			},
			{
				"level": 2,
				"code": 1213,
				"name": "Ponte de Sor"
			},
			{
				"level": 2,
				"code": 1214,
				"name": "Portalegre"
			},
			{
				"level": 2,
				"code": 1215,
				"name": "Sousel"
			}
		]
	},
	{
		"level": 1,
		"code": 13,
		"name": "Porto",
		"county": [
			{
				"level": 2,
				"code": 1301,
				"name": "Amarante"
			},
			{
				"level": 2,
				"code": 1302,
				"name": "Baião"
			},
			{
				"level": 2,
				"code": 1303,
				"name": "Felgueiras"
			},
			{
				"level": 2,
				"code": 1304,
				"name": "Gondomar"
			},
			{
				"level": 2,
				"code": 1305,
				"name": "Lousada"
			},
			{
				"level": 2,
				"code": 1306,
				"name": "Maia"
			},
			{
				"level": 2,
				"code": 1307,
				"name": "Marco de Canaveses"
			},
			{
				"level": 2,
				"code": 1308,
				"name": "Matosinhos"
			},
			{
				"level": 2,
				"code": 1309,
				"name": "Paços de Ferreira"
			},
			{
				"level": 2,
				"code": 1310,
				"name": "Paredes"
			},
			{
				"level": 2,
				"code": 1311,
				"name": "Penafiel"
			},
			{
				"level": 2,
				"code": 1312,
				"name": "Porto"
			},
			{
				"level": 2,
				"code": 1313,
				"name": "Póvoa de Varzim"
			},
			{
				"level": 2,
				"code": 1314,
				"name": "Santo Tirso"
			},
			{
				"level": 2,
				"code": 1315,
				"name": "Valongo"
			},
			{
				"level": 2,
				"code": 1316,
				"name": "Vila do Conde"
			},
			{
				"level": 2,
				"code": 1317,
				"name": "Vila Nova de Gaia"
			},
			{
				"level": 2,
				"code": 1318,
				"name": "Trofa"
			}
		]
	},
	{
		"level": 1,
		"code": 14,
		"name": "Santarém",
		"county": [
			{
				"level": 2,
				"code": 1401,
				"name": "Abrantes"
			},
			{
				"level": 2,
				"code": 1402,
				"name": "Alcanena"
			},
			{
				"level": 2,
				"code": 1403,
				"name": "Almeirim"
			},
			{
				"level": 2,
				"code": 1404,
				"name": "Alpiarça"
			},
			{
				"level": 2,
				"code": 1405,
				"name": "Benavente"
			},
			{
				"level": 2,
				"code": 1406,
				"name": "Cartaxo"
			},
			{
				"level": 2,
				"code": 1407,
				"name": "Chamusca"
			},
			{
				"level": 2,
				"code": 1408,
				"name": "Constância"
			},
			{
				"level": 2,
				"code": 1409,
				"name": "Coruche"
			},
			{
				"level": 2,
				"code": 1410,
				"name": "Entroncamento"
			},
			{
				"level": 2,
				"code": 1411,
				"name": "Ferreira do Zêzere"
			},
			{
				"level": 2,
				"code": 1412,
				"name": "Golegã"
			},
			{
				"level": 2,
				"code": 1413,
				"name": "Mação"
			},
			{
				"level": 2,
				"code": 1414,
				"name": "Rio Maior"
			},
			{
				"level": 2,
				"code": 1415,
				"name": "Salvaterra de Magos"
			},
			{
				"level": 2,
				"code": 1416,
				"name": "Santarém"
			},
			{
				"level": 2,
				"code": 1417,
				"name": "Sardoal"
			},
			{
				"level": 2,
				"code": 1418,
				"name": "Tomar"
			},
			{
				"level": 2,
				"code": 1419,
				"name": "Torres Novas"
			},
			{
				"level": 2,
				"code": 1420,
				"name": "Vila Nova da Barquinha"
			},
			{
				"level": 2,
				"code": 1421,
				"name": "Ourém"
			}
		]
	},
	{
		"level": 1,
		"code": 15,
		"name": "Setúbal",
		"county": [
			{
				"level": 2,
				"code": 1501,
				"name": "Alcácer do Sal"
			},
			{
				"level": 2,
				"code": 1502,
				"name": "Alcochete"
			},
			{
				"level": 2,
				"code": 1503,
				"name": "Almada"
			},
			{
				"level": 2,
				"code": 1504,
				"name": "Barreiro"
			},
			{
				"level": 2,
				"code": 1505,
				"name": "Grândola"
			},
			{
				"level": 2,
				"code": 1506,
				"name": "Moita"
			},
			{
				"level": 2,
				"code": 1507,
				"name": "Montijo"
			},
			{
				"level": 2,
				"code": 1508,
				"name": "Palmela"
			},
			{
				"level": 2,
				"code": 1509,
				"name": "Santiago do Cacém"
			},
			{
				"level": 2,
				"code": 1510,
				"name": "Seixal"
			},
			{
				"level": 2,
				"code": 1511,
				"name": "Sesimbra"
			},
			{
				"level": 2,
				"code": 1512,
				"name": "Setúbal"
			},
			{
				"level": 2,
				"code": 1513,
				"name": "Sines"
			}
		]
	},
	{
		"level": 1,
		"code": 16,
		"name": "Viana do Castelo",
		"county": [
			{
				"level": 2,
				"code": 1601,
				"name": "Arcos de Valdevez"
			},
			{
				"level": 2,
				"code": 1602,
				"name": "Caminha"
			},
			{
				"level": 2,
				"code": 1603,
				"name": "Melgaço"
			},
			{
				"level": 2,
				"code": 1604,
				"name": "Monção"
			},
			{
				"level": 2,
				"code": 1605,
				"name": "Paredes de Coura"
			},
			{
				"level": 2,
				"code": 1606,
				"name": "Ponte da Barca"
			},
			{
				"level": 2,
				"code": 1607,
				"name": "Ponte de Lima"
			},
			{
				"level": 2,
				"code": 1608,
				"name": "Valença"
			},
			{
				"level": 2,
				"code": 1609,
				"name": "Viana do Castelo"
			},
			{
				"level": 2,
				"code": 1610,
				"name": "Vila Nova de Cerveira"
			}
		]
	},
	{
		"level": 1,
		"code": 17,
		"name": "Vila Real",
		"county": [
			{
				"level": 2,
				"code": 1701,
				"name": "Alijó"
			},
			{
				"level": 2,
				"code": 1702,
				"name": "Boticas"
			},
			{
				"level": 2,
				"code": 1703,
				"name": "Chaves"
			},
			{
				"level": 2,
				"code": 1704,
				"name": "Mesão Frio"
			},
			{
				"level": 2,
				"code": 1705,
				"name": "Mondim de Basto"
			},
			{
				"level": 2,
				"code": 1706,
				"name": "Montalegre"
			},
			{
				"level": 2,
				"code": 1707,
				"name": "Murça"
			},
			{
				"level": 2,
				"code": 1708,
				"name": "Peso da Régua"
			},
			{
				"level": 2,
				"code": 1709,
				"name": "Ribeira de Pena"
			},
			{
				"level": 2,
				"code": 1710,
				"name": "Sabrosa"
			},
			{
				"level": 2,
				"code": 1711,
				"name": "Santa Marta de Penaguião"
			},
			{
				"level": 2,
				"code": 1712,
				"name": "Valpaços"
			},
			{
				"level": 2,
				"code": 1713,
				"name": "Vila Pouca de Aguiar"
			},
			{
				"level": 2,
				"code": 1714,
				"name": "Vila Real"
			}
		]
	},
	{
		"level": 1,
		"code": 18,
		"name": "Viseu",
		"county": [
			{
				"level": 2,
				"code": 1801,
				"name": "Armamar"
			},
			{
				"level": 2,
				"code": 1802,
				"name": "Carregal do Sal"
			},
			{
				"level": 2,
				"code": 1803,
				"name": "Castro Daire"
			},
			{
				"level": 2,
				"code": 1804,
				"name": "Cinfães"
			},
			{
				"level": 2,
				"code": 1805,
				"name": "Lamego"
			},
			{
				"level": 2,
				"code": 1806,
				"name": "Mangualde"
			},
			{
				"level": 2,
				"code": 1807,
				"name": "Moimenta da Beira"
			},
			{
				"level": 2,
				"code": 1808,
				"name": "Mortágua"
			},
			{
				"level": 2,
				"code": 1809,
				"name": "Nelas"
			},
			{
				"level": 2,
				"code": 1810,
				"name": "Oliveira de Frades"
			},
			{
				"level": 2,
				"code": 1811,
				"name": "Penalva do Castelo"
			},
			{
				"level": 2,
				"code": 1812,
				"name": "Penedono"
			},
			{
				"level": 2,
				"code": 1813,
				"name": "Resende"
			},
			{
				"level": 2,
				"code": 1814,
				"name": "Santa Comba Dão"
			},
			{
				"level": 2,
				"code": 1815,
				"name": "São João da Pesqueira"
			},
			{
				"level": 2,
				"code": 1816,
				"name": "São Pedro do Sul"
			},
			{
				"level": 2,
				"code": 1817,
				"name": "Sátão"
			},
			{
				"level": 2,
				"code": 1818,
				"name": "Sernancelhe"
			},
			{
				"level": 2,
				"code": 1819,
				"name": "Tabuaço"
			},
			{
				"level": 2,
				"code": 1820,
				"name": "Tarouca"
			},
			{
				"level": 2,
				"code": 1821,
				"name": "Tondela"
			},
			{
				"level": 2,
				"code": 1822,
				"name": "Vila Nova de Paiva"
			},
			{
				"level": 2,
				"code": 1823,
				"name": "Viseu"
			},
			{
				"level": 2,
				"code": 1824,
				"name": "Vouzela"
			}
		]
	},
	{
		"level": 1,
		"code": 31,
		"name": "Ilha da Madeira",
		"county": [
			{
				"level": 2,
				"code": 3101,
				"name": "Calheta"
			},
			{
				"level": 2,
				"code": 3102,
				"name": "Câmara de Lobos"
			},
			{
				"level": 2,
				"code": 3103,
				"name": "Funchal"
			},
			{
				"level": 2,
				"code": 3104,
				"name": "Machico"
			},
			{
				"level": 2,
				"code": 3105,
				"name": "Ponta do Sol"
			},
			{
				"level": 2,
				"code": 3106,
				"name": "Porto Moniz"
			},
			{
				"level": 2,
				"code": 3107,
				"name": "Ribeira Brava"
			},
			{
				"level": 2,
				"code": 3108,
				"name": "Santa Cruz"
			},
			{
				"level": 2,
				"code": 3109,
				"name": "Santana"
			},
			{
				"level": 2,
				"code": 3110,
				"name": "São Vicente"
			}
		]
	},
	{
		"level": 1,
		"code": 32,
		"name": "Ilha de Porto Santo",
		"county": [
			{
				"level": 2,
				"code": 3201,
				"name": "Porto Santo"
			}
		]
	},
	{
		"level": 1,
		"code": 41,
		"name": "Ilha de Santa Maria",
		"county": [
			{
				"level": 2,
				"code": 4101,
				"name": "Vila do Porto"
			}
		]
	},
	{
		"level": 1,
		"code": 42,
		"name": "Ilha de São Miguel",
		"county": [
			{
				"level": 2,
				"code": 4201,
				"name": "Lagoa"
			},
			{
				"level": 2,
				"code": 4202,
				"name": "Nordeste"
			},
			{
				"level": 2,
				"code": 4203,
				"name": "Ponta Delgada"
			},
			{
				"level": 2,
				"code": 4204,
				"name": "Povoação"
			},
			{
				"level": 2,
				"code": 4205,
				"name": "Ribeira Grande"
			},
			{
				"level": 2,
				"code": 4206,
				"name": "Vila Franca do Campo"
			}
		]
	},
	{
		"level": 1,
		"code": 43,
		"name": "Ilha Terceira",
		"county": [
			{
				"level": 2,
				"code": 4301,
				"name": "Angra do Heroísmo"
			},
			{
				"level": 2,
				"code": 4302,
				"name": "Vila da Praia da Vitória"
			}
		]
	},
	{
		"level": 1,
		"code": 44,
		"name": "Ilha Graciosa",
		"county": [
			{
				"level": 2,
				"code": 4401,
				"name": "Santa Cruz da Graciosa"
			}
		]
	},
	{
		"level": 1,
		"code": 45,
		"name": "Ilha de São Jorge",
		"county": [
			{
				"level": 2,
				"code": 4501,
				"name": "Calheta"
			},
			{
				"level": 2,
				"code": 4502,
				"name": "Velas"
			}
		]
	},
	{
		"level": 1,
		"code": 46,
		"name": "Ilha do Pico",
		"county": [
			{
				"level": 2,
				"code": 4601,
				"name": "Lajes do Pico"
			},
			{
				"level": 2,
				"code": 4602,
				"name": "Madalena"
			},
			{
				"level": 2,
				"code": 4603,
				"name": "São Roque do Pico"
			}
		]
	},
	{
		"level": 1,
		"code": 47,
		"name": "Ilha do Faial",
		"county": [
			{
				"level": 2,
				"code": 4701,
				"name": "Horta"
			}
		]
	},
	{
		"level": 1,
		"code": 48,
		"name": "Ilha das Flores",
		"county": [
			{
				"level": 2,
				"code": 4801,
				"name": "Lajes das Flores"
			},
			{
				"level": 2,
				"code": 4802,
				"name": "Santa Cruz das Flores"
			}
		]
	},
	{
		"level": 1,
		"code": 49,
		"name": "Ilha do Corvo",
		"county": [
			{
				"level": 2,
				"code": 4901,
				"name": "Corvo"
			}
		]
	}
]

const parishes = [
	{
		nome: "Abrantes",
		freguesias: [
			"Bemposta",
			"Carvalhal",
			"Fontes",
			"Martinchel",
			"Mouriscas",
			"Pego",
			"Rio de Moinhos",
			"Tramagal",
			"União das freguesias de Abrantes (São Vicente e São João) e Alferrarede",
			"União das freguesias de Aldeia do Mato e Souto",
			"União das freguesias de Alvega e Concavada",
			"União das freguesias de São Facundo e Vale das Mós",
			"União das freguesias de São Miguel do Rio Torto e Rossio ao Sul do Tejo"
		]
	},
	{
		nome: "Aguiar da Beira",
		freguesias: [
			"Carapito",
			"Cortiçada",
			"Dornelas",
			"Eirado",
			"Forninhos",
			"Pena Verde",
			"Pinheiro",
			"União das freguesias de Aguiar da Beira e Coruche",
			"União das freguesias de Sequeiros e Gradiz",
			"União das freguesias de Souto de Aguiar da Beira e Valverde"
		]
	},
	{
		nome: "Alandroal",
		freguesias: [
			"Capelins (Santo António)",
			"Santiago Maior",
			"Terena (São Pedro)",
			"União das freguesias de Alandroal (Nossa Senhora da Conceição), São Brás dos Matos (Mina do Bugalho) e Juromenha (Nossa Senhora do Loreto)"
		]
	},
	{
		nome: "Albergaria-a-Velha",
		freguesias: [
			"Albergaria-a-Velha e Valmaior",
			"Alquerubim",
			"Angeja",
			"Branca",
			"Ribeira de Fráguas",
			"São João de Loure e Frossos"
		]
	},
	{
		nome: "Albufeira",
		freguesias: [
			"Albufeira e Olhos de Água",
			"Ferreiras",
			"Guia",
			"Paderne"
		]
	},
	{
		nome: "Alcanena",
		freguesias: [
			"Bugalhos",
			"Minde",
			"Moitas Venda",
			"Monsanto",
			"Serra de Santo António",
			"União das freguesias de Alcanena e Vila Moreira",
			"União das freguesias de Malhou, Louriceira e Espinheiro"
		]
	},
	{
		nome: "Alcobaça",
		freguesias: [
			"Alfeizerão",
			"Aljubarrota",
			"Benedita",
			"Bárrio",
			"Cela",
			"Maiorga",
			"São Martinho do Porto",
			"Turquel",
			"União das freguesias de Alcobaça e Vestiaria",
			"União das freguesias de Coz, Alpedriz e Montes",
			"União das freguesias de Pataias e Martingança",
			"Vimeiro",
			"Évora de Alcobaça"
		]
	},
	{
		nome: "Alcochete",
		freguesias: [
			"Alcochete",
			"Samouco",
			"São Francisco"
		]
	},
	{
		nome: "Alcoutim",
		freguesias: [
			"Giões",
			"Martim Longo",
			"União das freguesias de Alcoutim e Pereiro",
			"Vaqueiros"
		]
	},
	{
		nome: "Alcácer do Sal",
		freguesias: [
			"Comporta",
			"São Martinho",
			"Torrão",
			"União das freguesias de Alcácer do Sal (Santa Maria do Castelo e Santiago) e Santa Susana"
		]
	},
	{
		nome: "Alenquer",
		freguesias: [
			"Carnota",
			"Meca",
			"Olhalvo",
			"Ota",
			"União das freguesias de Abrigada e Cabanas de Torres",
			"União das freguesias de Aldeia Galega da Merceana e Aldeia Gavinha",
			"União das freguesias de Alenquer (Santo Estêvão e Triana)",
			"União das freguesias de Carregado e Cadafais",
			"União das freguesias de Ribafria e Pereiro de Palhacana",
			"Ventosa",
			"Vila Verde dos Francos"
		]
	},
	{
		nome: "Alfândega da Fé",
		freguesias: [
			"Alfândega da Fé",
			"Cerejais",
			"Sambade",
			"União das freguesias de Agrobom, Saldonha e Vale Pereiro",
			"União das freguesias de Eucisia, Gouveia e Valverde",
			"União das freguesias de Ferradosa e Sendim da Serra",
			"União das freguesias de Gebelim e Soeima",
			"União das freguesias de Parada e Sendim da Ribeira",
			"União das freguesias de Pombal e Vales",
			"Vilar Chão",
			"Vilarelhos",
			"Vilares de Vilariça"
		]
	},
	{
		nome: "Alijó",
		freguesias: [
			"Alijó",
			"Favaios",
			"Pegarinhos",
			"Pinhão",
			"Sanfins do Douro",
			"Santa Eugénia",
			"São Mamede de Ribatua",
			"União das freguesias de Carlão e Amieiro",
			"União das freguesias de Castedo e Cotas",
			"União das freguesias de Pópulo e Ribalonga",
			"União das freguesias de Vale de Mendiz, Casal de Loivos e Vilarinho de Cotas",
			"Vila Chã",
			"Vila Verde",
			"Vilar de Maçada"
		]
	},
	{
		nome: "Aljezur",
		freguesias: [
			"Aljezur",
			"Bordeira",
			"Odeceixe",
			"Rogil"
		]
	},
	{
		nome: "Aljustrel",
		freguesias: [
			"Ervidel",
			"Messejana",
			"São João de Negrilhos",
			"União das freguesias de Aljustrel e Rio de Moinhos"
		]
	},
	{
		nome: "Almada",
		freguesias: [
			"Costa da Caparica",
			"União das freguesias de Almada, Cova da Piedade, Pragal e Cacilhas",
			"União das freguesias de Caparica e Trafaria",
			"União das freguesias de Charneca de Caparica e Sobreda",
			"União das freguesias de Laranjeiro e Feijó"
		]
	},
	{
		nome: "Almeida",
		freguesias: [
			"Almeida",
			"Castelo Bom",
			"Freineda",
			"Freixo",
			"Malhada Sorda",
			"Nave de Haver",
			"São Pedro de Rio Seco",
			"União das freguesias de Amoreira, Parada e Cabreira",
			"União das freguesias de Azinhal, Peva e Valverde",
			"União das freguesias de Castelo Mendo, Ade, Monteperobolso e Mesquitela",
			"União das freguesias de Junça e Naves",
			"União das freguesias de Leomil, Mido, Senouras e Aldeia Nova",
			"União das freguesias de Malpartida e Vale de Coelha",
			"União das freguesias de Miuzela e Porto de Ovelha",
			"Vale da Mula",
			"Vilar Formoso"
		]
	},
	{
		nome: "Almeirim",
		freguesias: [
			"Almeirim",
			"Benfica do Ribatejo",
			"Fazendas de Almeirim",
			"Raposa"
		]
	},
	{
		nome: "Almodôvar",
		freguesias: [
			"Aldeia dos Fernandes",
			"Rosário",
			"Santa Cruz",
			"São Barnabé",
			"União das freguesias de Almodôvar e Graça dos Padrões",
			"União das freguesias de Santa Clara-a-Nova e Gomes Aires"
		]
	},
	{
		nome: "Alpiarça",
		freguesias: [
			"Alpiarça"
		]
	},
	{
		nome: "Alter do Chão",
		freguesias: [
			"Alter do Chão",
			"Chancelaria",
			"Cunheira",
			"Seda"
		]
	},
	{
		nome: "Alvaiázere",
		freguesias: [
			"Almoster",
			"Alvaiázere",
			"Maçãs de Dona Maria",
			"Pelmá",
			"Pussos São Pedro"
		]
	},
	{
		nome: "Alvito",
		freguesias: [
			"Alvito",
			"Vila Nova da Baronia"
		]
	},
	{
		nome: "Amadora",
		freguesias: [
			"Alfragide",
			"Encosta do Sol",
			"Falagueira-Venda Nova",
			"Mina de Água",
			"Venteira",
			"Águas Livres"
		]
	},
	{
		nome: "Amarante",
		freguesias: [
			"Ansiães",
			"Candemil",
			"Fregim",
			"Fridão",
			"Gondar",
			"Gouveia (São Simão)",
			"Jazente",
			"Lomba",
			"Louredo",
			"Lufrei",
			"Mancelos",
			"Padronelo",
			"Rebordelo",
			"Salvador do Monte",
			"Telões",
			"Travanca",
			"União das freguesias de Aboadela, Sanche e Várzea",
			"União das freguesias de Amarante (São Gonçalo), Madalena, Cepelos e Gatão",
			"União das freguesias de Bustelo, Carneiro e Carvalho de Rei",
			"União das freguesias de Figueiró (Santiago e Santa Cristina)",
			"União das freguesias de Freixo de Cima e de Baixo",
			"União das freguesias de Olo e Canadelo",
			"União das freguesias de Vila Garcia, Aboim e Chapa",
			"Vila Caiz",
			"Vila Chã do Marão",
			"Vila Meã"
		]
	},
	{
		nome: "Amares",
		freguesias: [
			"Barreiros",
			"Bico",
			"Bouro (Santa Maria)",
			"Bouro (Santa Marta)",
			"Caires",
			"Carrazedo",
			"Dornelas",
			"Fiscal",
			"Goães",
			"Lago",
			"Rendufe",
			"União das freguesias de Amares e Figueiredo",
			"União das freguesias de Caldelas, Sequeiros e Paranhos",
			"União das freguesias de Ferreiros, Prozelo e Besteiros",
			"União das freguesias de Torre e Portela",
			"União das freguesias de Vilela, Seramil e Paredes Secas"
		]
	},
	{
		nome: "Anadia",
		freguesias: [
			"Avelãs de Caminho",
			"Avelãs de Cima",
			"Moita",
			"Sangalhos",
			"São Lourenço do Bairro",
			"União das freguesias de Amoreira da Gândara, Paredes do Bairro e Ancas",
			"União das freguesias de Arcos e Mogofores",
			"União das freguesias de Tamengos, Aguim e Óis do Bairro",
			"Vila Nova de Monsarros",
			"Vilarinho do Bairro"
		]
	},
	{
		nome: "Angra do Heroísmo",
		freguesias: [
			"Altares",
			"Angra (Nossa Senhora da Conceição)",
			"Angra (Santa Luzia)",
			"Angra (São Pedro)",
			"Angra (Sé)",
			"Cinco Ribeiras",
			"Doze Ribeiras",
			"Feteira",
			"Porto Judeu",
			"Posto Santo",
			"Raminho",
			"Ribeirinha",
			"Santa Bárbara",
			"Serreta",
			"São Bartolomeu de Regatos",
			"São Bento",
			"São Mateus da Calheta",
			"Terra Chã",
			"Vila de São Sebastião"
		]
	},
	{
		nome: "Ansião",
		freguesias: [
			"Alvorge",
			"Ansião",
			"Avelar",
			"Chão de Couce",
			"Pousaflores",
			"Santiago da Guarda"
		]
	},
	{
		nome: "Arcos de Valdevez",
		freguesias: [
			"Aboim das Choças",
			"Aguiã",
			"Cabana Maior",
			"Cabreiro",
			"Cendufe",
			"Couto",
			"Gavieira",
			"Gondoriz",
			"Jolda (São Paio)",
			"Miranda",
			"Monte Redondo",
			"Oliveira",
			"Padroso",
			"Paçô",
			"Prozelo",
			"Rio Frio",
			"Rio de Moinhos",
			"Sabadim",
			"Senharei",
			"Sistelo",
			"Soajo",
			"União das freguesias de Alvora e Loureda",
			"União das freguesias de Arcos de Valdevez (Salvador), Vila Fonche e Parada",
			"União das freguesias de Arcos de Valdevez (São Paio) e Giela",
			"União das freguesias de Eiras e Mei",
			"União das freguesias de Grade e Carralcova",
			"União das freguesias de Guilhadeses e Santar",
			"União das freguesias de Jolda (Madalena) e Rio Cabrão",
			"União das freguesias de Padreiro (Salvador e Santa Cristina)",
			"União das freguesias de Portela e Extremo",
			"União das freguesias de Souto e Tabaçô",
			"União das freguesias de São Jorge e Ermelo",
			"União das freguesias de Távora (Santa Maria e São Vicente)",
			"União das freguesias de Vilela, São Cosme e São Damião e Sá",
			"Vale",
			"Ázere"
		]
	},
	{
		nome: "Arganil",
		freguesias: [
			"Arganil",
			"Benfeita",
			"Celavisa",
			"Folques",
			"Piódão",
			"Pomares",
			"Pombeiro da Beira",
			"Sarzedo",
			"Secarias",
			"São Martinho da Cortiça",
			"União das freguesias de Cepos e Teixeira",
			"União das freguesias de Cerdeira e Moura da Serra",
			"União das freguesias de Côja e Barril de Alva",
			"União das freguesias de Vila Cova de Alva e Anseriz"
		]
	},
	{
		nome: "Armamar",
		freguesias: [
			"Aldeias",
			"Armamar",
			"Cimbres",
			"Folgosa",
			"Fontelo",
			"Queimada",
			"Queimadela",
			"Santa Cruz",
			"São Cosmado",
			"São Martinho das Chãs",
			"União das freguesias de Aricera e Goujoim",
			"União das freguesias de São Romão e Santiago",
			"União das freguesias de Vila Seca e Santo Adrião",
			"Vacalar"
		]
	},
	{
		nome: "Arouca",
		freguesias: [
			"Alvarenga",
			"Chave",
			"Escariz",
			"Fermedo",
			"Mansores",
			"Moldes",
			"Rossas",
			"Santa Eulália",
			"São Miguel do Mato",
			"Tropeço",
			"União das freguesias de Arouca e Burgo",
			"União das freguesias de Cabreiros e Albergaria da Serra",
			"União das freguesias de Canelas e Espiunca",
			"União das freguesias de Covelo de Paivó e Janarde",
			"Urrô",
			"Várzea"
		]
	},
	{
		nome: "Arraiolos",
		freguesias: [
			"Arraiolos",
			"Igrejinha",
			"União das freguesias de Gafanhoeira (São Pedro) e Sabugueiro",
			"União das freguesias de São Gregório e Santa Justa",
			"Vimieiro"
		]
	},
	{
		nome: "Arronches",
		freguesias: [
			"Assunção",
			"Esperança",
			"Mosteiros"
		]
	},
	{
		nome: "Arruda dos Vinhos",
		freguesias: [
			"Arranhó",
			"Arruda dos Vinhos",
			"Cardosas",
			"S. Tiago dos Velhos"
		]
	},
	{
		nome: "Aveiro",
		freguesias: [
			"Aradas",
			"Cacia",
			"Eixo e Eirol",
			"Esgueira",
			"Oliveirinha",
			"Requeixo, Nossa Senhora de Fátima e Nariz",
			"Santa Joana",
			"São Bernardo",
			"São Jacinto",
			"União das freguesias de Glória e Vera Cruz"
		]
	},
	{
		nome: "Avis",
		freguesias: [
			"Aldeia Velha",
			"Avis",
			"Ervedal",
			"Figueira e Barros",
			"União das freguesias de Alcórrego e Maranhão",
			"União das freguesias de Benavila e Valongo"
		]
	},
	{
		nome: "Azambuja",
		freguesias: [
			"Alcoentre",
			"Aveiras de Baixo",
			"Aveiras de Cima",
			"Azambuja",
			"União das freguesias de Manique do Intendente, Vila Nova de São Pedro e Maçussa",
			"Vale do Paraíso",
			"Vila Nova da Rainha"
		]
	},
	{
		nome: "Baião",
		freguesias: [
			"Frende",
			"Gestaçô",
			"Gove",
			"Grilo",
			"Loivos do Monte",
			"Santa Marinha do Zêzere",
			"União das freguesias de Ancede e Ribadouro",
			"União das freguesias de Baião (Santa Leocádia) e Mesquinhata",
			"União das freguesias de Campelo e Ovil",
			"União das freguesias de Loivos da Ribeira e Tresouras",
			"União das freguesias de Santa Cruz do Douro e São Tomé de Covelas",
			"União das freguesias de Teixeira e Teixeiró",
			"Valadares",
			"Viariz"
		]
	},
	{
		nome: "Barcelos",
		freguesias: [
			"Abade de Neiva",
			"Aborim",
			"Adães",
			"Airó",
			"Aldreu",
			"Alvelos",
			"Arcozelo",
			"Areias",
			"Balugães",
			"Barcelinhos",
			"Barqueiros",
			"Cambeses",
			"Carapeços",
			"Carvalhal",
			"Carvalhas",
			"Cossourado",
			"Cristelo",
			"Fornelos",
			"Fragoso",
			"Galegos (Santa Maria)",
			"Galegos (São Martinho)",
			"Gilmonde",
			"Lama",
			"Lijó",
			"Macieira de Rates",
			"Manhente",
			"Martim",
			"Moure",
			"Oliveira",
			"Palme",
			"Panque",
			"Paradela",
			"Pereira",
			"Perelhal",
			"Pousa",
			"Remelhe",
			"Rio Covo (Santa Eugénia)",
			"Roriz",
			"Silva",
			"Tamel (São Veríssimo)",
			"Ucha",
			"União das freguesias de Alheira e Igreja Nova",
			"União das freguesias de Alvito (São Pedro e São Martinho) e Couto",
			"União das freguesias de Areias de Vilar e Encourados",
			"União das freguesias de Barcelos, Vila Boa e Vila Frescainha (São Martinho e São Pedro)",
			"União das freguesias de Campo e Tamel (São Pedro Fins)",
			"União das freguesias de Carreira e Fonte Coberta",
			"União das freguesias de Chorente, Góios, Courel, Pedra Furada e Gueral",
			"União das freguesias de Creixomil e Mariz",
			"União das freguesias de Durrães e Tregosa",
			"União das freguesias de Gamil e Midões",
			"União das freguesias de Milhazes, Vilar de Figos e Faria",
			"União das freguesias de Negreiros e Chavão",
			"União das freguesias de Quintiães e Aguiar",
			"União das freguesias de Sequeade e Bastuço (São João e Santo Estevão)",
			"União das freguesias de Silveiros e Rio Covo (Santa Eulália)",
			"União das freguesias de Tamel (Santa Leocádia) e Vilar do Monte",
			"União das freguesias de Viatodos, Grimancelos, Minhotães e Monte de Fralães",
			"União das freguesias de Vila Cova e Feitos",
			"Vila Seca",
			"Várzea"
		]
	},
	{
		nome: "Barrancos",
		freguesias: [
			"Barrancos"
		]
	},
	{
		nome: "Barreiro",
		freguesias: [
			"Santo António da Charneca",
			"União das freguesias de Alto do Seixalinho, Santo André e Verderena",
			"União das freguesias de Barreiro e Lavradio",
			"União das freguesias de Palhais e Coina"
		]
	},
	{
		nome: "Batalha",
		freguesias: [
			"Batalha",
			"Golpilheira",
			"Reguengo do Fetal",
			"São Mamede"
		]
	},
	{
		nome: "Beja",
		freguesias: [
			"Baleizão",
			"Beringel",
			"Cabeça Gorda",
			"Nossa Senhora das Neves",
			"Santa Clara de Louredo",
			"São Matias",
			"União das freguesias de Albernoa e Trindade",
			"União das freguesias de Beja (Salvador e Santa Maria da Feira)",
			"União das freguesias de Beja (Santiago Maior e São João Baptista)",
			"União das freguesias de Salvada e Quintos",
			"União das freguesias de Santa Vitória e Mombeja",
			"União das freguesias de Trigaches e São Brissos"
		]
	},
	{
		nome: "Belmonte",
		freguesias: [
			"Caria",
			"Inguias",
			"Maçainhas",
			"União das freguesias de Belmonte e Colmeal da Torre"
		]
	},
	{
		nome: "Benavente",
		freguesias: [
			"Barrosa",
			"Benavente",
			"Samora Correia",
			"Santo Estêvão"
		]
	},
	{
		nome: "Bombarral",
		freguesias: [
			"Carvalhal",
			"Pó",
			"Roliça",
			"União das freguesias do Bombarral e Vale Covo"
		]
	},
	{
		nome: "Borba",
		freguesias: [
			"Borba (Matriz)",
			"Borba (São Bartolomeu)",
			"Orada",
			"Rio de Moinhos"
		]
	},
	{
		nome: "Boticas",
		freguesias: [
			"Alturas do Barroso e Cerdedo",
			"Ardãos e Bobadela",
			"Beça",
			"Boticas e Granja",
			"Codessoso, Curros e Fiães do Tâmega",
			"Covas do Barroso",
			"Dornelas",
			"Pinho",
			"Sapiãos",
			"Vilar e Viveiro"
		]
	},
	{
		nome: "Braga",
		freguesias: [
			"Adaúfe",
			"Braga (São Vicente)",
			"Braga (São Vítor)",
			"Espinho",
			"Esporões",
			"Figueiredo",
			"Gualtar",
			"Lamas",
			"Mire de Tibães",
			"Padim da Graça",
			"Palmeira",
			"Pedralva",
			"Priscos",
			"Ruilhe",
			"Sequeira",
			"Sobreposta",
			"Tadim",
			"Tebosa",
			"União das freguesias de Arentim e Cunha",
			"União das freguesias de Braga (Maximinos, Sé e Cividade)",
			"União das freguesias de Braga (São José de São Lázaro e São João do Souto)",
			"União das freguesias de Cabreiros e Passos (São Julião)",
			"União das freguesias de Celeirós, Aveleda e Vimieiro",
			"União das freguesias de Crespos e Pousada",
			"União das freguesias de Escudeiros e Penso (Santo Estêvão e São Vicente)",
			"União das freguesias de Este (São Pedro e São Mamede)",
			"União das freguesias de Ferreiros e Gondizalves",
			"União das freguesias de Guisande e Oliveira (São Pedro)",
			"União das freguesias de Lomar e Arcos",
			"União das freguesias de Merelim (São Paio), Panoias e Parada de Tibães",
			"União das freguesias de Merelim (São Pedro) e Frossos",
			"União das freguesias de Morreira e Trandeiras",
			"União das freguesias de Nogueira, Fraião e Lamaçães",
			"União das freguesias de Nogueiró e Tenões",
			"União das freguesias de Real, Dume e Semelhe",
			"União das freguesias de Santa Lucrécia de Algeriz e Navarra",
			"União das freguesias de Vilaça e Fradelos"
		]
	},
	{
		nome: "Bragança",
		freguesias: [
			"Alfaião",
			"Babe",
			"Baçal",
			"Carragosa",
			"Castro de Avelãs",
			"Coelhoso",
			"Donai",
			"Espinhosela",
			"França",
			"Gimonde",
			"Gondesende",
			"Gostei",
			"Grijó de Parada",
			"Macedo do Mato",
			"Mós",
			"Nogueira",
			"Outeiro",
			"Parâmio",
			"Pinela",
			"Quintanilha",
			"Quintela de Lampaças",
			"Rabal",
			"Rebordãos",
			"Salsas",
			"Samil",
			"Santa Comba de Rossas",
			"Sendas",
			"Serapicos",
			"Sortes",
			"São Pedro de Sarracenos",
			"União das freguesias de Aveleda e Rio de Onor",
			"União das freguesias de Castrelos e Carrazedo",
			"União das freguesias de Izeda, Calvelhe e Paradinha Nova",
			"União das freguesias de Parada e Faílde",
			"União das freguesias de Rebordainhos e Pombares",
			"União das freguesias de Rio Frio e Milhão",
			"União das freguesias de São Julião de Palácios e Deilão",
			"União das freguesias de Sé, Santa Maria e Meixedo",
			"Zoio"
		]
	},
	{
		nome: "Cabeceiras de Basto",
		freguesias: [
			"Abadim",
			"Basto",
			"Bucos",
			"Cabeceiras de Basto",
			"Cavez",
			"Faia",
			"Pedraça",
			"Rio Douro",
			"União das freguesias de Alvite e Passos",
			"União das freguesias de Arco de Baúlhe e Vila Nune",
			"União das freguesias de Gondiães e Vilar de Cunhas",
			"União das freguesias de Refojos de Basto, Outeiro e Painzela"
		]
	},
	{
		nome: "Cadaval",
		freguesias: [
			"Alguber",
			"Peral",
			"União das freguesias de Lamas e Cercal",
			"União das freguesias de Painho e Figueiros",
			"União das freguesias do Cadaval e Pêro Moniz",
			"Vermelha",
			"Vilar"
		]
	},
	{
		nome: "Caldas da Rainha",
		freguesias: [
			"A dos Francos",
			"Alvorninha",
			"Carvalhal Benfeito",
			"Foz do Arelho",
			"Landal",
			"Nadadouro",
			"Salir de Matos",
			"Santa Catarina",
			"União das freguesias de Caldas da Rainha - Nossa Senhora do Pópulo, Coto e São Gregório",
			"União das freguesias de Caldas da Rainha - Santo Onofre e Serra do Bouro",
			"União das freguesias de Tornada e Salir do Porto",
			"Vidais"
		]
	},
	{
		nome: "Calheta",
		freguesias: [
			"Arco da Calheta",
			"Calheta",
			"Estreito da Calheta",
			"Fajã da Ovelha",
			"Jardim do Mar",
			"Paul do Mar",
			"Ponta do Pargo",
			"Prazeres"
		]
	},
	{
		nome: "Calheta de São Jorge",
		freguesias: [
			"Calheta",
			"Norte Pequeno",
			"Ribeira Seca",
			"Santo Antão",
			"Topo (Nossa Senhora do Rosário)"
		]
	},
	{
		nome: "Caminha",
		freguesias: [
			"Argela",
			"Dem",
			"Lanhelas",
			"Riba de Âncora",
			"Seixas",
			"União das freguesias de Arga (Baixo, Cima e São João)",
			"União das freguesias de Caminha (Matriz) e Vilarelho",
			"União das freguesias de Gondar e Orbacém",
			"União das freguesias de Moledo e Cristelo",
			"União das freguesias de Venade e Azevedo",
			"Vila Praia de Âncora",
			"Vilar de Mouros",
			"Vile",
			"Âncora"
		]
	},
	{
		nome: "Campo Maior",
		freguesias: [
			"Nossa Senhora da Expectação",
			"Nossa Senhora da Graça dos Degolados",
			"São João Baptista"
		]
	},
	{
		nome: "Cantanhede",
		freguesias: [
			"Ançã",
			"Cadima",
			"Cordinhã",
			"Febres",
			"Murtede",
			"Ourentã",
			"Sanguinheira",
			"São Caetano",
			"Tocha",
			"União das freguesias de Cantanhede e Pocariça",
			"União das freguesias de Covões e Camarneira",
			"União das freguesias de Portunhos e Outil",
			"União das freguesias de Sepins e Bolho",
			"União das freguesias de Vilamar e Corticeiro de Cima"
		]
	},
	{
		nome: "Carrazeda de Ansiães",
		freguesias: [
			"Carrazeda de Ansiães",
			"Fonte Longa",
			"Linhares",
			"Marzagão",
			"Parambos",
			"Pereiros",
			"Pinhal do Norte",
			"Pombal",
			"Seixo de Ansiães",
			"União das freguesias de Amedo e Zedes",
			"União das freguesias de Belver e Mogo de Malta",
			"União das freguesias de Castanheiro do Norte e Ribalonga",
			"União das freguesias de Lavandeira, Beira Grande e Selores",
			"Vilarinho da Castanheira"
		]
	},
	{
		nome: "Carregal do Sal",
		freguesias: [
			"Beijós",
			"Cabanas de Viriato",
			"Carregal do Sal",
			"Oliveira do Conde",
			"Parada"
		]
	},
	{
		nome: "Cartaxo",
		freguesias: [
			"Pontével",
			"União das freguesias de Ereira e Lapa",
			"União das freguesias do Cartaxo e Vale da Pinta",
			"Valada",
			"Vale da Pedra",
			"Vila Chã de Ourique"
		]
	},
	{
		nome: "Cascais",
		freguesias: [
			"Alcabideche",
			"São Domingos de Rana",
			"União das freguesias de Carcavelos e Parede",
			"União das freguesias de Cascais e Estoril"
		]
	},
	{
		nome: "Castanheira de Pêra",
		freguesias: [
			"União das freguesias de Castanheira de Pêra e Coentral"
		]
	},
	{
		nome: "Castelo Branco",
		freguesias: [
			"Alcains",
			"Almaceda",
			"Benquerenças",
			"Castelo Branco",
			"Lardosa",
			"Louriçal do Campo",
			"Malpica do Tejo",
			"Monforte da Beira",
			"Salgueiro do Campo",
			"Santo André das Tojeiras",
			"Sarzedas",
			"São Vicente da Beira",
			"Tinalhas",
			"União das freguesias de Cebolais de Cima e Retaxo",
			"União das freguesias de Escalos de Baixo e Mata",
			"União das freguesias de Escalos de Cima e Lousa",
			"União das freguesias de Freixial e Juncal do Campo",
			"União das freguesias de Ninho do Açor e Sobral do Campo",
			"União das freguesias de Póvoa de Rio de Moinhos e Cafede"
		]
	},
	{
		nome: "Castelo de Paiva",
		freguesias: [
			"Fornos",
			"Real",
			"Santa Maria de Sardoura",
			"São Martinho de Sardoura",
			"União das freguesias de Raiva, Pedorido e Paraíso",
			"União das freguesias de Sobrado e Bairros"
		]
	},
	{
		nome: "Castelo de Vide",
		freguesias: [
			"Nossa Senhora da Graça de Póvoa e Meadas",
			"Santa Maria da Devesa",
			"Santiago Maior",
			"São João Baptista"
		]
	},
	{
		nome: "Castro Daire",
		freguesias: [
			"Almofala",
			"Cabril",
			"Castro Daire",
			"Cujó",
			"Gosende",
			"Moledo",
			"Monteiras",
			"Mões",
			"Pepim",
			"Pinheiro",
			"São Joaninho",
			"União das freguesias de Mamouros, Alva e Ribolhos",
			"União das freguesias de Mezio e Moura Morta",
			"União das freguesias de Parada de Ester e Ester",
			"União das freguesias de Picão e Ermida",
			"União das freguesias de Reriz e Gafanhão"
		]
	},
	{
		nome: "Castro Marim",
		freguesias: [
			"Altura",
			"Azinhal",
			"Castro Marim",
			"Odeleite"
		]
	},
	{
		nome: "Castro Verde",
		freguesias: [
			"Entradas",
			"Santa Bárbara de Padrões",
			"São Marcos da Ataboeira",
			"União das freguesias de Castro Verde e Casével"
		]
	},
	{
		nome: "Celorico da Beira",
		freguesias: [
			"Baraçal",
			"Carrapichana",
			"Casas do Soeiro",
			"Forno Telheiro",
			"Lajeosa do Mondego",
			"Linhares",
			"Maçal do Chão",
			"Mesquitela",
			"Minhocal",
			"Prados",
			"Ratoeira",
			"União das freguesias de Açores e Velosa",
			"União das freguesias de Celorico (São Pedro e Santa Maria) e Vila Boa do Mondego",
			"União das freguesias de Cortiçô da Serra, Vide entre Vinhas e Salgueirais",
			"União das freguesias de Rapa e Cadafaz",
			"Vale de Azares"
		]
	},
	{
		nome: "Celorico de Basto",
		freguesias: [
			"Agilde",
			"Arnóia",
			"Basto (São Clemente)",
			"Borba de Montanha",
			"Codeçoso",
			"Fervença",
			"Moreira do Castelo",
			"Rego",
			"Ribas",
			"União das freguesias de Britelo, Gémeos e Ourilhe",
			"União das freguesias de Canedo de Basto e Corgo",
			"União das freguesias de Carvalho e Basto (Santa Tecla)",
			"União das freguesias de Caçarilhe e Infesta",
			"União das freguesias de Veade, Gagos e Molares",
			"Vale de Bouro"
		]
	},
	{
		nome: "Chamusca",
		freguesias: [
			"Carregueira",
			"Ulme",
			"União das freguesias da Chamusca e Pinheiro Grande",
			"União das freguesias de Parreira e Chouto",
			"Vale de Cavalos"
		]
	},
	{
		nome: "Chaves",
		freguesias: [
			"Anelhe",
			"Bustelo",
			"Cimo de Vila da Castanheira",
			"Curalha",
			"Ervededo",
			"Faiões",
			"Lama de Arcos",
			"Mairos",
			"Moreiras",
			"Nogueira da Montanha",
			"Oura",
			"Outeiro Seco",
			"Paradela",
			"Planalto de Monforte (União das freguesias de Oucidres e Bobadela)",
			"Redondelo",
			"Sanfins",
			"Santa Leocádia",
			"Santa Maria Maior",
			"Santo António de Monforte",
			"Santo Estêvão",
			"São Pedro de Agostém",
			"São Vicente",
			"Tronco",
			"União das freguesias da Madalena e Samaiões",
			"União das freguesias das Eiras, São Julião de Montenegro e Cela",
			"União das freguesias de Calvão e Soutelinho da Raia",
			"União das freguesias de Loivos e Póvoa de Agrações",
			"União das freguesias de Santa Cruz/Trindade e Sanjurge",
			"União das freguesias de Soutelo e Seara Velha",
			"União das freguesias de Travancas e Roriz",
			"Vale de Anta",
			"Vidago (União das freguesias de Vidago, Arcossó, Selhariz e Vilarinho das Paranheiras)",
			"Vila Verde da Raia",
			"Vilar de Nantes",
			"Vilarelho da Raia",
			"Vilas Boas",
			"Vilela Seca",
			"Vilela do Tâmega",
			"Águas Frias"
		]
	},
	{
		nome: "Cinfães",
		freguesias: [
			"Cinfães",
			"Espadanedo",
			"Ferreiros de Tendais",
			"Fornelos",
			"Moimenta",
			"Nespereira",
			"Oliveira do Douro",
			"Santiago de Piães",
			"Souselo",
			"São Cristóvão de Nogueira",
			"Tarouquela",
			"Tendais",
			"Travanca",
			"União das freguesias de Alhões, Bustelo, Gralheira e Ramires"
		]
	},
	{
		nome: "Coimbra",
		freguesias: [
			"Almalaguês",
			"Brasfemes",
			"Ceira",
			"Cernache",
			"Santo António dos Olivais",
			"São João do Campo",
			"São Silvestre",
			"Torres do Mondego",
			"União das freguesias de Antuzede e Vil de Matos",
			"União das freguesias de Assafarge e Antanhol",
			"União das freguesias de Coimbra (Sé Nova, Santa Cruz, Almedina e São Bartolomeu)",
			"União das freguesias de Eiras e São Paulo de Frades",
			"União das freguesias de Santa Clara e Castelo Viegas",
			"União das freguesias de Souselas e Botão",
			"União das freguesias de São Martinho de Árvore e Lamarosa",
			"União das freguesias de São Martinho do Bispo e Ribeira de Frades",
			"União das freguesias de Taveiro, Ameal e Arzila",
			"União das freguesias de Trouxemil e Torre de Vilela"
		]
	},
	{
		nome: "Condeixa-a-Nova",
		freguesias: [
			"Anobra",
			"Ega",
			"Furadouro",
			"União das freguesias de Condeixa-a-Velha e Condeixa-a-Nova",
			"União das freguesias de Sebal e Belide",
			"União das freguesias de Vila Seca e Bem da Fé",
			"Zambujal"
		]
	},
	{
		nome: "Constância",
		freguesias: [
			"Constância",
			"Montalvo",
			"Santa Margarida da Coutada"
		]
	},
	{
		nome: "Coruche",
		freguesias: [
			"Biscainho",
			"Branca",
			"Couço",
			"Santana do Mato",
			"São José da Lamarosa",
			"União das freguesias de Coruche, Fajarda e Erra"
		]
	},
	{
		nome: "Corvo",
		freguesias: [
			"Corvo"
		]
	},
	{
		nome: "Covilhã",
		freguesias: [
			"Aldeia de São Francisco de Assis",
			"Boidobra",
			"Cortes do Meio",
			"Dominguizo",
			"Erada",
			"Ferro",
			"Orjais",
			"Paul",
			"Peraboa",
			"Sobral de São Miguel",
			"São Jorge da Beira",
			"Tortosendo",
			"Unhais da Serra",
			"União das freguesias de Barco e Coutada",
			"União das freguesias de Cantar-Galo e Vila do Carvalho",
			"União das freguesias de Casegas e Ourondo",
			"União das freguesias de Covilhã e Canhoso",
			"União das freguesias de Peso e Vales do Rio",
			"União das freguesias de Teixoso e Sarzedo",
			"União das freguesias de Vale Formoso e Aldeia do Souto",
			"Verdelhos"
		]
	},
	{
		nome: "Crato",
		freguesias: [
			"Aldeia da Mata",
			"Gáfete",
			"Monte da Pedra",
			"União das freguesias de Crato e Mártires, Flor da Rosa e Vale do Peso"
		]
	},
	{
		nome: "Cuba",
		freguesias: [
			"Cuba",
			"Faro do Alentejo",
			"Vila Alva",
			"Vila Ruiva"
		]
	},
	{
		nome: "Câmara de Lobos",
		freguesias: [
			"Curral das Freiras",
			"Câmara de Lobos",
			"Estreito de Câmara de Lobos",
			"Jardim da Serra",
			"Quinta Grande"
		]
	},
	{
		nome: "Elvas",
		freguesias: [
			"Assunção, Ajuda, Salvador e Santo Ildefonso",
			"Caia, São Pedro e Alcáçova",
			"Santa Eulália",
			"São Brás e São Lourenço",
			"São Vicente e Ventosa",
			"União das freguesias de Barbacena e Vila Fernando",
			"União das freguesias de Terrugem e Vila Boim"
		]
	},
	{
		nome: "Entroncamento",
		freguesias: [
			"Nossa Senhora de Fátima",
			"São João Baptista"
		]
	},
	{
		nome: "Espinho",
		freguesias: [
			"Espinho",
			"Paramos",
			"Silvalde",
			"União das freguesias de Anta e Guetim"
		]
	},
	{
		nome: "Esposende",
		freguesias: [
			"Antas",
			"Forjães",
			"Gemeses",
			"União das freguesias de Apúlia e Fão",
			"União das freguesias de Belinho e Mar",
			"União das freguesias de Esposende, Marinhas e Gandra",
			"União das freguesias de Fonte Boa e Rio Tinto",
			"União das freguesias de Palmeira de Faro e Curvos",
			"Vila Chã"
		]
	},
	{
		nome: "Estarreja",
		freguesias: [
			"Avanca",
			"Pardilhó",
			"Salreu",
			"União das freguesias de Beduído e Veiros",
			"União das freguesias de Canelas e Fermelã"
		]
	},
	{
		nome: "Estremoz",
		freguesias: [
			"Arcos",
			"Glória",
			"São Domingos de Ana Loura",
			"União das freguesias de Estremoz (Santa Maria e Santo André)",
			"União das freguesias de São Bento do Cortiço e Santo Estêvão",
			"União das freguesias de São Lourenço de Mamporcão e São Bento de Ana Loura",
			"União das freguesias do Ameixial (Santa Vitória e São Bento)",
			"Veiros",
			"Évora Monte (Santa Maria)"
		]
	},
	{
		nome: "Fafe",
		freguesias: [
			"Armil",
			"Arões (Santa Cristina)",
			"Arões (São Romão)",
			"Estorãos",
			"Fafe",
			"Fornelos",
			"Golães",
			"Medelo",
			"Paços",
			"Quinchães",
			"Regadas",
			"Revelhe",
			"Ribeiros",
			"Silvares (São Martinho)",
			"São Gens",
			"Travassós",
			"União de freguesias de Aboim, Felgueiras, Gontim e Pedraído",
			"União de freguesias de Agrela e Serafão",
			"União de freguesias de Antime e Silvares (São Clemente)",
			"União de freguesias de Ardegão, Arnozela e Seidões",
			"União de freguesias de Cepães e Fareja",
			"União de freguesias de Freitas e Vila Cova",
			"União de freguesias de Monte e Queimadela",
			"União de freguesias de Moreira do Rei e Várzea Cova",
			"Vinhós"
		]
	},
	{
		nome: "Faro",
		freguesias: [
			"Montenegro",
			"Santa Bárbara de Nexe",
			"União das freguesias de Conceição e Estoi",
			"União das freguesias de Faro (Sé e São Pedro)"
		]
	},
	{
		nome: "Felgueiras",
		freguesias: [
			"Airães",
			"Aião",
			"Friande",
			"Idães",
			"Jugueiros",
			"Penacova",
			"Pinheiro",
			"Pombeiro de Ribavizela",
			"Refontoura",
			"Regilde",
			"Revinhade",
			"Sendim",
			"União das freguesias de Macieira da Lixa e Caramos",
			"União das freguesias de Margaride (Santa Eulália), Várzea, Lagares, Varziela e Moure",
			"União das freguesias de Pedreira, Rande e Sernande",
			"União das freguesias de Torrados e Sousa",
			"União das freguesias de Unhão e Lordelo",
			"União das freguesias de Vila Cova da Lixa e Borba de Godim",
			"União das freguesias de Vila Fria e Vizela (São Jorge)",
			"União das freguesias de Vila Verde e Santão"
		]
	},
	{
		nome: "Ferreira do Alentejo",
		freguesias: [
			"Figueira dos Cavaleiros",
			"Odivelas",
			"União das freguesias de Alfundão e Peroguarda",
			"União das freguesias de Ferreira do Alentejo e Canhestros"
		]
	},
	{
		nome: "Ferreira do Zêzere",
		freguesias: [
			"Beco",
			"Chãos",
			"Ferreira do Zêzere",
			"Igreja Nova do Sobral",
			"Nossa Senhora do Pranto",
			"União das freguesias de Areias e Pias",
			"Águas Belas"
		]
	},
	{
		nome: "Figueira da Foz",
		freguesias: [
			"Alhadas",
			"Alqueidão",
			"Bom Sucesso",
			"Buarcos e São Julião",
			"Ferreira-a-Nova",
			"Lavos",
			"Maiorca",
			"Marinha das Ondas",
			"Moinhos da Gândara",
			"Paião",
			"Quiaios",
			"São Pedro",
			"Tavarede",
			"Vila Verde"
		]
	},
	{
		nome: "Figueira de Castelo Rodrigo",
		freguesias: [
			"Castelo Rodrigo",
			"Escalhão",
			"Figueira de Castelo Rodrigo",
			"Mata de Lobos",
			"União das freguesias de Algodres, Vale de Afonsinho e Vilar de Amargo",
			"União das freguesias de Almofala e Escarigo",
			"União das freguesias de Cinco Vilas e Reigada",
			"União das freguesias de Freixeda do Torrão, Quintã de Pêro Martins e Penha de Águia",
			"União das freguesias do Colmeal e Vilar Torpim",
			"Vermiosa"
		]
	},
	{
		nome: "Figueiró dos Vinhos",
		freguesias: [
			"Aguda",
			"Arega",
			"Campelo",
			"União das freguesias de Figueiró dos Vinhos e Bairradas"
		]
	},
	{
		nome: "Fornos de Algodres",
		freguesias: [
			"Algodres",
			"Casal Vasco",
			"Figueiró da Granja",
			"Fornos de Algodres",
			"Infias",
			"Maceira",
			"Matança",
			"Muxagata",
			"Queiriz",
			"União das freguesias de Cortiçô e Vila Chã",
			"União das freguesias de Juncais, Vila Ruiva e Vila Soeiro do Chão",
			"União das freguesias de Sobral Pichorro e Fuinhas"
		]
	},
	{
		nome: "Freixo de Espada à Cinta",
		freguesias: [
			"Ligares",
			"Poiares",
			"União das freguesias de Freixo de Espada à Cinta e Mazouco",
			"União das freguesias de Lagoaça e Fornos"
		]
	},
	{
		nome: "Fronteira",
		freguesias: [
			"Cabeço de Vide",
			"Fronteira",
			"São Saturnino"
		]
	},
	{
		nome: "Funchal",
		freguesias: [
			"Funchal (Santa Luzia)",
			"Funchal (Santa Maria Maior)",
			"Funchal (São Pedro)",
			"Funchal (Sé)",
			"Imaculado Coração de Maria",
			"Monte",
			"Santo António",
			"São Gonçalo",
			"São Martinho",
			"São Roque"
		]
	},
	{
		nome: "Fundão",
		freguesias: [
			"Alcaide",
			"Alcaria",
			"Alcongosta",
			"Alpedrinha",
			"Barroca",
			"Bogas de Cima",
			"Capinha",
			"Castelejo",
			"Castelo Novo",
			"Enxames",
			"Fatela",
			"Lavacolhos",
			"Orca",
			"Pêro Viseu",
			"Silvares",
			"Soalheira",
			"Souto da Casa",
			"Telhado",
			"Três Povos",
			"União das freguesias de Fundão, Valverde, Donas, Aldeia de Joanes e Aldeia Nova do Cabo",
			"União das freguesias de Janeiro de Cima e Bogas de Baixo",
			"União das freguesias de Póvoa de Atalaia e Atalaia do Campo",
			"União das freguesias de Vale de Prazeres e Mata da Rainha"
		]
	},
	{
		nome: "Gavião",
		freguesias: [
			"Belver",
			"Comenda",
			"Margem",
			"União das freguesias de Gavião e Atalaia"
		]
	},
	{
		nome: "Golegã",
		freguesias: [
			"Azinhaga",
			"Golegã",
			"Pombalinho"
		]
	},
	{
		nome: "Gondomar",
		freguesias: [
			"Baguim do Monte (Rio Tinto)",
			"Lomba",
			"Rio Tinto",
			"União das freguesias de Foz do Sousa e Covelo",
			"União das freguesias de Fânzeres e São Pedro da Cova",
			"União das freguesias de Gondomar (São Cosme), Valbom e Jovim",
			"União das freguesias de Melres e Medas"
		]
	},
	{
		nome: "Gouveia",
		freguesias: [
			"Arcozelo",
			"Cativelos",
			"Folgosinho",
			"Gouveia",
			"Nespereira",
			"Paços da Serra",
			"Ribamondego",
			"São Paio",
			"União das freguesias de Aldeias e Mangualde da Serra",
			"União das freguesias de Figueiró da Serra e Freixo da Serra",
			"União das freguesias de Melo e Nabais",
			"União das freguesias de Moimenta da Serra e Vinhó",
			"União das freguesias de Rio Torto e Lagarinhos",
			"Vila Cortês da Serra",
			"Vila Franca da Serra",
			"Vila Nova de Tazem"
		]
	},
	{
		nome: "Grândola",
		freguesias: [
			"Azinheira dos Barros e São Mamede do Sádão",
			"Carvalhal",
			"Melides",
			"União das freguesias de Grândola e Santa Margarida da Serra"
		]
	},
	{
		nome: "Guarda",
		freguesias: [
			"Adão",
			"Aldeia Viçosa",
			"Aldeia do Bispo",
			"Alvendre",
			"Arrifana",
			"Avelãs da Ribeira",
			"Benespera",
			"Casal de Cinza",
			"Castanheira",
			"Cavadoude",
			"Codesseiro",
			"Faia",
			"Famalicão",
			"Fernão Joanes",
			"Gonçalo",
			"Gonçalo Bocas",
			"Guarda",
			"Jarmelo São Miguel",
			"Jarmelo São Pedro",
			"João Antão",
			"Marmeleiro",
			"Maçainhas",
			"Meios",
			"Panoias de Cima",
			"Pega",
			"Porto da Carne",
			"Pêra do Moço",
			"Ramela",
			"Santana da Azinha",
			"Sobral da Serra",
			"União de freguesias de Avelãs de Ambom e Rocamondo",
			"União de freguesias de Corujeira e Trinta",
			"União de freguesias de Mizarela, Pêro Soares e Vila Soeiro",
			"União de freguesias de Pousade e Albardo",
			"União de freguesias de Rochoso e Monte Margarida",
			"Vale de Estrela",
			"Valhelhas",
			"Vela",
			"Videmonte",
			"Vila Cortês do Mondego",
			"Vila Fernando",
			"Vila Franca do Deão",
			"Vila Garcia"
		]
	},
	{
		nome: "Guimarães",
		freguesias: [
			"Aldão",
			"Azurém",
			"Barco",
			"Brito",
			"Caldelas",
			"Candoso (São Martinho)",
			"Costa",
			"Creixomil",
			"Fermentões",
			"Gondar",
			"Gonça",
			"Guardizela",
			"Infantas",
			"Longos",
			"Lordelo",
			"Mesão Frio",
			"Moreira de Cónegos",
			"Nespereira",
			"Pencelo",
			"Pinheiro",
			"Polvoreira",
			"Ponte",
			"Prazins (Santa Eufémia)",
			"Ronfe",
			"Sande (São Martinho)",
			"Selho (São Cristóvão)",
			"Selho (São Jorge)",
			"Serzedelo",
			"Silvares",
			"São Torcato",
			"União das freguesias de Abação e Gémeos",
			"União das freguesias de Airão Santa Maria, Airão São João e Vermil",
			"União das freguesias de Arosa e Castelões",
			"União das freguesias de Atães e Rendufe",
			"União das freguesias de Briteiros Santo Estêvão e Donim",
			"União das freguesias de Briteiros São Salvador e Briteiros Santa Leocádia",
			"União das freguesias de Candoso São Tiago e Mascotelos",
			"União das freguesias de Conde e Gandarela",
			"União das freguesias de Leitões, Oleiros e Figueiredo",
			"União das freguesias de Oliveira, São Paio e São Sebastião",
			"União das freguesias de Prazins Santo Tirso e Corvite",
			"União das freguesias de Sande São Lourenço e Balazar",
			"União das freguesias de Sande Vila Nova e Sande São Clemente",
			"União das freguesias de Selho São Lourenço e Gominhães",
			"União das freguesias de Serzedo e Calvos",
			"União das freguesias de Souto Santa Maria, Souto São Salvador e Gondomar",
			"União das freguesias de Tabuadelo e São Faustino",
			"Urgezes"
		]
	},
	{
		nome: "Góis",
		freguesias: [
			"Alvares",
			"Góis",
			"União das freguesias de Cadafaz e Colmeal",
			"Vila Nova do Ceira"
		]
	},
	{
		nome: "Horta",
		freguesias: [
			"Capelo",
			"Castelo Branco",
			"Cedros",
			"Feteira",
			"Flamengos",
			"Horta (Angústias)",
			"Horta (Conceição)",
			"Horta (Matriz)",
			"Pedro Miguel",
			"Praia do Almoxarife",
			"Praia do Norte",
			"Ribeirinha",
			"Salão"
		]
	},
	{
		nome: "Idanha-a-Nova",
		freguesias: [
			"Aldeia de Santa Margarida",
			"Ladoeiro",
			"Medelim",
			"Oledo",
			"Penha Garcia",
			"Proença-a-Velha",
			"Rosmaninhal",
			"São Miguel de Acha",
			"Toulões",
			"União das freguesias de Idanha-a-Nova e Alcafozes",
			"União das freguesias de Monfortinho e Salvaterra do Extremo",
			"União das freguesias de Monsanto e Idanha-a-Velha",
			"União das freguesias de Zebreira e Segura"
		]
	},
	{
		nome: "Lagoa",
		freguesias: [
			"Ferragudo",
			"Porches",
			"União das freguesias de Estômbar e Parchal",
			"União das freguesias de Lagoa e Carvoeiro"
		]
	},
	{
		nome: "Lagoa (Açores)",
		freguesias: [
			"Cabouco",
			"Lagoa (Nossa Senhora do Rosário)",
			"Lagoa (Santa Cruz)",
			"Ribeira Chã",
			"Água de Pau"
		]
	},
	{
		nome: "Lagos",
		freguesias: [
			"Luz",
			"Odiáxere",
			"São Gonçalo de Lagos",
			"União das freguesias de Bensafrim e Barão de São João"
		]
	},
	{
		nome: "Lajes das Flores",
		freguesias: [
			"Fajã Grande",
			"Fajãzinha",
			"Fazenda",
			"Lajedo",
			"Lajes das Flores",
			"Lomba",
			"Mosteiro"
		]
	},
	{
		nome: "Lajes do Pico",
		freguesias: [
			"Calheta de Nesquim",
			"Lajes do Pico",
			"Piedade",
			"Ribeiras",
			"Ribeirinha",
			"São João"
		]
	},
	{
		nome: "Lamego",
		freguesias: [
			"Avões",
			"Britiande",
			"Cambres",
			"Ferreirim",
			"Ferreiros de Avões",
			"Figueira",
			"Lalim",
			"Lamego (Almacave e Sé)",
			"Lazarim",
			"Penajóia",
			"Penude",
			"Samodães",
			"Sande",
			"União das freguesias de Bigorne, Magueija e Pretarouca",
			"União das freguesias de Cepões, Meijinhos e Melcões",
			"União das freguesias de Parada do Bispo e Valdigem",
			"Vila Nova de Souto d'El-Rei",
			"Várzea de Abrunhais"
		]
	},
	{
		nome: "Leiria",
		freguesias: [
			"Amor",
			"Arrabal",
			"Bajouca",
			"Bidoeira de Cima",
			"Caranguejeira",
			"Coimbrão",
			"Maceira",
			"Milagres",
			"Regueira de Pontes",
			"União das freguesias de Colmeias e Memória",
			"União das freguesias de Leiria, Pousos, Barreira e Cortes",
			"União das freguesias de Marrazes e Barosa",
			"União das freguesias de Monte Real e Carvide",
			"União das freguesias de Monte Redondo e Carreira",
			"União das freguesias de Parceiros e Azoia",
			"União das freguesias de Santa Catarina da Serra e Chainça",
			"União das freguesias de Santa Eufémia e Boa Vista",
			"União das freguesias de Souto da Carpalhosa e Ortigosa"
		]
	},
	{
		nome: "Lisboa",
		freguesias: [
			"Ajuda",
			"Alcântara",
			"Alvalade",
			"Areeiro",
			"Arroios",
			"Avenidas Novas",
			"Beato",
			"Belém",
			"Benfica",
			"Campo de Ourique",
			"Campolide",
			"Carnide",
			"Estrela",
			"Lumiar",
			"Marvila",
			"Misericórdia",
			"Olivais",
			"Parque das Nações",
			"Penha de França",
			"Santa Clara",
			"Santa Maria Maior",
			"Santo António",
			"São Domingos de Benfica",
			"São Vicente"
		]
	},
	{
		nome: "Loulé",
		freguesias: [
			"Almancil",
			"Alte",
			"Ameixial",
			"Boliqueime",
			"Loulé (São Clemente)",
			"Loulé (São Sebastião)",
			"Quarteira",
			"Salir",
			"União de freguesias de Querença, Tôr e Benafim"
		]
	},
	{
		nome: "Loures",
		freguesias: [
			"Bucelas",
			"Fanhões",
			"Loures",
			"Lousa",
			"União das freguesias de Camarate, Unhos e Apelação",
			"União das freguesias de Moscavide e Portela",
			"União das freguesias de Sacavém e Prior Velho",
			"União das freguesias de Santa Iria de Azoia, São João da Talha e Bobadela",
			"União das freguesias de Santo Antão e São Julião do Tojal",
			"União das freguesias de Santo António dos Cavaleiros e Frielas"
		]
	},
	{
		nome: "Lourinhã",
		freguesias: [
			"Moita dos Ferreiros",
			"Reguengo Grande",
			"Ribamar",
			"Santa Bárbara",
			"União das freguesias de Lourinhã e Atalaia",
			"União das freguesias de Miragaia e Marteleira",
			"União das freguesias de São Bartolomeu dos Galegos e Moledo",
			"Vimeiro"
		]
	},
	{
		nome: "Lousada",
		freguesias: [
			"Aveleda",
			"Caíde de Rei",
			"Lodares",
			"Macieira",
			"Meinedo",
			"Nevogilde",
			"Sousela",
			"Torno",
			"União das freguesias de Cernadelo e Lousada (São Miguel e Santa Margarida)",
			"União das freguesias de Cristelos, Boim e Ordem",
			"União das freguesias de Figueiras e Covas",
			"União das freguesias de Lustosa e Barrosas (Santo Estêvão)",
			"União das freguesias de Nespereira e Casais",
			"União das freguesias de Silvares, Pias, Nogueira e Alvarenga",
			"Vilar do Torno e Alentém"
		]
	},
	{
		nome: "Lousã",
		freguesias: [
			"Gândaras",
			"Serpins",
			"União das freguesias de Foz de Arouce e Casal de Ermio",
			"União das freguesias de Lousã e Vilarinho"
		]
	},
	{
		nome: "Macedo de Cavaleiros",
		freguesias: [
			"Amendoeira",
			"Arcas",
			"Carrapatas",
			"Chacim",
			"Cortiços",
			"Corujas",
			"Ferreira",
			"Grijó",
			"Lagoa",
			"Lamalonga",
			"Lamas",
			"Lombo",
			"Macedo de Cavaleiros",
			"Morais",
			"Olmos",
			"Peredo",
			"Salselas",
			"Sezulfe",
			"Talhas",
			"União das freguesias de Ala e Vilarinho do Monte",
			"União das freguesias de Bornes e Burga",
			"União das freguesias de Castelãos e Vilar do Monte",
			"União das freguesias de Espadanedo, Edroso, Murçós e Soutelo Mourisco",
			"União das freguesias de Podence e Santa Combinha",
			"União das freguesias de Talhinhas e Bagueixe",
			"Vale Benfeito",
			"Vale da Porca",
			"Vale de Prados",
			"Vilarinho de Agrochão",
			"Vinhas"
		]
	},
	{
		nome: "Machico",
		freguesias: [
			"Caniçal",
			"Machico",
			"Porto da Cruz",
			"Santo António da Serra",
			"Água de Pena"
		]
	},
	{
		nome: "Madalena",
		freguesias: [
			"Bandeiras",
			"Candelária",
			"Criação Velha",
			"Madalena",
			"São Caetano",
			"São Mateus"
		]
	},
	{
		nome: "Mafra",
		freguesias: [
			"Carvoeira",
			"Encarnação",
			"Ericeira",
			"Mafra",
			"Milharado",
			"Santo Isidoro",
			"União das freguesias de Azueira e Sobral da Abelheira",
			"União das freguesias de Enxara do Bispo, Gradil e Vila Franca do Rosário",
			"União das freguesias de Igreja Nova e Cheleiros",
			"União das freguesias de Malveira e São Miguel de Alcainça",
			"União das freguesias de Venda do Pinheiro e Santo Estêvão das Galés"
		]
	},
	{
		nome: "Maia",
		freguesias: [
			"Castêlo da Maia",
			"Cidade da Maia",
			"Folgosa",
			"Milheirós",
			"Moreira",
			"Nogueira e Silva Escura",
			"Pedrouços",
			"São Pedro Fins",
			"Vila Nova da Telha",
			"Águas Santas"
		]
	},
	{
		nome: "Mangualde",
		freguesias: [
			"Abrunhosa-a-Velha",
			"Alcafache",
			"Cunha Baixa",
			"Espinho",
			"Fornos de Maceira Dão",
			"Freixiosa",
			"Quintela de Azurara",
			"São João da Fresta",
			"União das freguesias de Mangualde, Mesquitela e Cunha Alta",
			"União das freguesias de Moimenta de Maceira Dão e Lobelhe do Mato",
			"União das freguesias de Santiago de Cassurrães e Póvoa de Cervães",
			"União das freguesias de Tavares (Chãs, Várzea e Travanca)"
		]
	},
	{
		nome: "Manteigas",
		freguesias: [
			"Manteigas (Santa Maria)",
			"Manteigas (São Pedro)",
			"Sameiro",
			"Vale de Amoreira"
		]
	},
	{
		nome: "Marco de Canaveses",
		freguesias: [
			"Alpendorada, Várzea e Torrão",
			"Avessadas e Rosém",
			"Banho e Carvalhosa",
			"Bem Viver",
			"Constance",
			"Marco",
			"Paredes de Viadores e Manhuncelos",
			"Penha Longa e Paços de Gaiolo",
			"Sande e São Lourenço do Douro",
			"Santo Isidoro e Livração",
			"Soalhães",
			"Sobretâmega",
			"Tabuado",
			"Vila Boa de Quires e Maureles",
			"Vila Boa do Bispo",
			"Várzea, Aliviada e Folhada"
		]
	},
	{
		nome: "Marinha Grande",
		freguesias: [
			"Marinha Grande",
			"Moita",
			"Vieira de Leiria"
		]
	},
	{
		nome: "Marvão",
		freguesias: [
			"Beirã",
			"Santa Maria de Marvão",
			"Santo António das Areias",
			"São Salvador da Aramenha"
		]
	},
	{
		nome: "Matosinhos",
		freguesias: [
			"União das freguesias de Custóias, Leça do Balio e Guifões",
			"União das freguesias de Matosinhos e Leça da Palmeira",
			"União das freguesias de Perafita, Lavra e Santa Cruz do Bispo",
			"União das freguesias de São Mamede de Infesta e Senhora da Hora"
		]
	},
	{
		nome: "Mação",
		freguesias: [
			"Amêndoa",
			"Cardigos",
			"Carvoeiro",
			"Envendos",
			"Ortiga",
			"União das freguesias de Mação, Penhascoso e Aboboreira"
		]
	},
	{
		nome: "Mealhada",
		freguesias: [
			"Barcouço",
			"Casal Comba",
			"Luso",
			"Pampilhosa",
			"União das freguesias da Mealhada, Ventosa do Bairro e Antes",
			"Vacariça"
		]
	},
	{
		nome: "Melgaço",
		freguesias: [
			"Alvaredo",
			"Cousso",
			"Cristoval",
			"Fiães",
			"Gave",
			"Paderne",
			"Penso",
			"São Paio",
			"União das freguesias de Castro Laboreiro e Lamas de Mouro",
			"União das freguesias de Chaviães e Paços",
			"União das freguesias de Parada do Monte e Cubalhão",
			"União das freguesias de Prado e Remoães",
			"União das freguesias de Vila e Roussas"
		]
	},
	{
		nome: "Mesão Frio",
		freguesias: [
			"Barqueiros",
			"Cidadelhe",
			"Mesão Frio (Santo André)",
			"Oliveira",
			"Vila Marim"
		]
	},
	{
		nome: "Mira",
		freguesias: [
			"Carapelhos",
			"Mira",
			"Praia de Mira",
			"Seixo"
		]
	},
	{
		nome: "Miranda do Corvo",
		freguesias: [
			"Lamas",
			"Miranda do Corvo",
			"União das freguesias de Semide e Rio Vide",
			"Vila Nova"
		]
	},
	{
		nome: "Miranda do Douro",
		freguesias: [
			"Duas Igrejas",
			"Genísio",
			"Malhadas",
			"Miranda do Douro",
			"Palaçoulo",
			"Picote",
			"Póvoa",
			"São Martinho de Angueira",
			"União das freguesias de Constantim e Cicouro",
			"União das freguesias de Ifanes e Paradela",
			"União das freguesias de Sendim e Atenor",
			"União das freguesias de Silva e Águas Vivas",
			"Vila Chã de Braciosa"
		]
	},
	{
		nome: "Mirandela",
		freguesias: [
			"Abambres",
			"Abreiro",
			"Aguieiras",
			"Alvites",
			"Bouça",
			"Cabanelas",
			"Caravelas",
			"Carvalhais",
			"Cedães",
			"Cobro",
			"Fradizela",
			"Frechas",
			"Lamas de Orelhão",
			"Mascarenhas",
			"Mirandela",
			"Múrias",
			"Passos",
			"Suçães",
			"São Pedro Velho",
			"São Salvador",
			"Torre de Dona Chama",
			"União das freguesias de Avantos e Romeu",
			"União das freguesias de Avidagos, Navalho e Pereira",
			"União das freguesias de Barcel, Marmelos e Valverde da Gestosa",
			"União das freguesias de Franco e Vila Boa",
			"União das freguesias de Freixeda e Vila Verde",
			"Vale de Asnes",
			"Vale de Gouvinhas",
			"Vale de Salgueiro",
			"Vale de Telhas"
		]
	},
	{
		nome: "Mogadouro",
		freguesias: [
			"Azinhoso",
			"Bemposta",
			"Brunhoso",
			"Bruçó",
			"Castelo Branco",
			"Castro Vicente",
			"Meirinhos",
			"Paradela",
			"Penas Roias",
			"Peredo da Bemposta",
			"Saldanha",
			"São Martinho do Peso",
			"Travanca",
			"Tó",
			"União das freguesias de Brunhozinho, Castanheira e Sanhoane",
			"União das freguesias de Mogadouro, Valverde, Vale de Porco e Vilar de Rei",
			"União das freguesias de Remondes e Soutelo",
			"União das freguesias de Vilarinho dos Galegos e Ventozelo",
			"Urrós",
			"Vale da Madre",
			"Vila de Ala"
		]
	},
	{
		nome: "Moimenta da Beira",
		freguesias: [
			"Alvite",
			"Arcozelos",
			"Baldos",
			"Cabaços",
			"Caria",
			"Castelo",
			"Leomil",
			"Moimenta da Beira",
			"Passô",
			"Sarzedo",
			"Sever",
			"União das freguesias de Paradinha e Nagosa",
			"União das freguesias de Peva e Segões",
			"União das freguesias de Pêra Velha, Aldeia de Nacomba e Ariz",
			"Vila da Rua",
			"Vilar"
		]
	},
	{
		nome: "Moita",
		freguesias: [
			"Alhos Vedros",
			"Moita",
			"União das freguesias de Baixa da Banheira e Vale da Amoreira",
			"União das freguesias de Gaio-Rosário e Sarilhos Pequenos"
		]
	},
	{
		nome: "Monchique",
		freguesias: [
			"Alferce",
			"Marmelete",
			"Monchique"
		]
	},
	{
		nome: "Mondim de Basto",
		freguesias: [
			"Atei",
			"Bilhó",
			"São Cristóvão de Mondim de Basto",
			"União das freguesias de Campanhó e Paradança",
			"União das freguesias de Ermelo e Pardelhas",
			"Vilar de Ferreiros"
		]
	},
	{
		nome: "Monforte",
		freguesias: [
			"Assumar",
			"Monforte",
			"Santo Aleixo",
			"Vaiamonte"
		]
	},
	{
		nome: "Montalegre",
		freguesias: [
			"Cabril",
			"Cervos",
			"Chã",
			"Covelo do Gerês",
			"Ferral",
			"Gralhas",
			"Morgade",
			"Negrões",
			"Outeiro",
			"Pitões das Junias",
			"Reigoso",
			"Salto",
			"Santo André",
			"Sarraquinhos",
			"Solveira",
			"Tourém",
			"União das freguesias de Cambeses do Rio, Donões e Mourilhe",
			"União das freguesias de Meixedo e Padornelos",
			"União das freguesias de Montalegre e Padroso",
			"União das freguesias de Paradela, Contim e Fiães",
			"União das freguesias de Sezelhe e Covelães",
			"União das freguesias de Venda Nova e Pondras",
			"União das freguesias de Viade de Baixo e Fervidelas",
			"União das freguesias de Vilar de Perdizes e Meixide",
			"Vila da Ponte"
		]
	},
	{
		nome: "Montemor-o-Novo",
		freguesias: [
			"Cabrela",
			"Ciborro",
			"Foros de Vale de Figueira",
			"Santiago do Escoural",
			"São Cristóvão",
			"União das freguesias de Cortiçadas de Lavre e Lavre",
			"União das freguesias de Nossa Senhora da Vila, Nossa Senhora do Bispo e Silveiras"
		]
	},
	{
		nome: "Montemor-o-Velho",
		freguesias: [
			"Arazede",
			"Carapinheira",
			"Ereira",
			"Liceia",
			"Meãs do Campo",
			"Pereira",
			"Santo Varão",
			"Seixo de Gatões",
			"Tentúgal",
			"União das freguesias de Abrunheira, Verride e Vila Nova da Barca",
			"União das freguesias de Montemor-o-Velho e Gatões"
		]
	},
	{
		nome: "Montijo",
		freguesias: [
			"Canha",
			"Sarilhos Grandes",
			"União das freguesias de Atalaia e Alto Estanqueiro-Jardia",
			"União das freguesias de Montijo e Afonsoeiro",
			"União das freguesias de Pegões"
		]
	},
	{
		nome: "Monção",
		freguesias: [
			"Abedim",
			"Barbeita",
			"Barroças e Taias",
			"Bela",
			"Cambeses",
			"Lara",
			"Longos Vales",
			"Merufe",
			"Moreira",
			"Pias",
			"Pinheiros",
			"Podame",
			"Portela",
			"Riba de Mouro",
			"Segude",
			"Tangil",
			"Trute",
			"União das freguesias de Anhões e Luzio",
			"União das freguesias de Ceivães e Badim",
			"União das freguesias de Mazedo e Cortes",
			"União das freguesias de Messegães, Valadares e Sá",
			"União das freguesias de Monção e Troviscoso",
			"União das freguesias de Sago, Lordelo e Parada",
			"União das freguesias de Troporiz e Lapela"
		]
	},
	{
		nome: "Mora",
		freguesias: [
			"Brotas",
			"Cabeção",
			"Mora",
			"Pavia"
		]
	},
	{
		nome: "Mortágua",
		freguesias: [
			"Cercosa",
			"Espinho",
			"Marmeleira",
			"Pala",
			"Sobral",
			"Trezói",
			"União das freguesias de Mortágua, Vale de Remígio, Cortegaça e Almaça"
		]
	},
	{
		nome: "Moura",
		freguesias: [
			"Amareleja",
			"Póvoa de São Miguel",
			"Sobral da Adiça",
			"União das freguesias de Moura (Santo Agostinho e São João Baptista) e Santo Amador",
			"União das freguesias de Safara e Santo Aleixo da Restauração"
		]
	},
	{
		nome: "Mourão",
		freguesias: [
			"Granja",
			"Luz",
			"Mourão"
		]
	},
	{
		nome: "Murtosa",
		freguesias: [
			"Bunheiro",
			"Monte",
			"Murtosa",
			"Torreira"
		]
	},
	{
		nome: "Murça",
		freguesias: [
			"Candedo",
			"Fiolhoso",
			"Jou",
			"Murça",
			"União das freguesias de Carva e Vilares",
			"União das freguesias de Noura e Palheiros",
			"Valongo de Milhais"
		]
	},
	{
		nome: "Mértola",
		freguesias: [
			"Alcaria Ruiva",
			"Corte do Pinto",
			"Espírito Santo",
			"Mértola",
			"Santana de Cambas",
			"São João dos Caldeireiros",
			"União das freguesias de São Miguel do Pinheiro, São Pedro de Solis e São Sebastião dos Carros"
		]
	},
	{
		nome: "Mêda",
		freguesias: [
			"Aveloso",
			"Barreira",
			"Coriscada",
			"Longroiva",
			"Marialva",
			"Mêda, Outeiro de Gatos e Fonte Longa",
			"Poço do Canto",
			"Prova e Casteição",
			"Rabaçal",
			"Ranhados",
			"União das freguesias de Vale Flor, Carvalhal e Pai Penela"
		]
	},
	{
		nome: "Nazaré",
		freguesias: [
			"Famalicão",
			"Nazaré",
			"Valado dos Frades"
		]
	},
	{
		nome: "Nelas",
		freguesias: [
			"Canas de Senhorim",
			"Lapa do Lobo",
			"Nelas",
			"Senhorim",
			"União das freguesias de Carvalhal Redondo e Aguieira",
			"União das freguesias de Santar e Moreira",
			"Vilar Seco"
		]
	},
	{
		nome: "Nisa",
		freguesias: [
			"Alpalhão",
			"Montalvão",
			"Santana",
			"São Matias",
			"Tolosa",
			"União das freguesias de Arez e Amieira do Tejo",
			"União das freguesias de Espírito Santo, Nossa Senhora da Graça e São Simão"
		]
	},
	{
		nome: "Nordeste",
		freguesias: [
			"Achada",
			"Achadinha",
			"Algarvia",
			"Lomba da Fazenda",
			"Nordeste",
			"Salga",
			"Santana",
			"Santo António de Nordestinho",
			"São Pedro de Nordestinho"
		]
	},
	{
		nome: "Odemira",
		freguesias: [
			"Boavista dos Pinheiros",
			"Colos",
			"Longueira/Almograve",
			"Luzianes-Gare",
			"Relíquias",
			"Sabóia",
			"Santa Clara-a-Velha",
			"São Luís",
			"São Martinho das Amoreiras",
			"São Salvador e Santa Maria",
			"São Teotónio",
			"Vale de Santiago",
			"Vila Nova de Milfontes"
		]
	},
	{
		nome: "Odivelas",
		freguesias: [
			"Odivelas",
			"União das freguesias de Pontinha e Famões",
			"União das freguesias de Póvoa de Santo Adrião e Olival Basto",
			"União das freguesias de Ramada e Caneças"
		]
	},
	{
		nome: "Oeiras",
		freguesias: [
			"Barcarena",
			"Porto Salvo",
			"União das freguesias de Algés, Linda-a-Velha e Cruz Quebrada-Dafundo",
			"União das freguesias de Carnaxide e Queijas",
			"União das freguesias de Oeiras e São Julião da Barra, Paço de Arcos e Caxias"
		]
	},
	{
		nome: "Oleiros",
		freguesias: [
			"Cambas",
			"Estreito-Vilar Barroco",
			"Isna",
			"Madeirã",
			"Mosteiro",
			"Oleiros-Amieira",
			"Orvalho",
			"Sarnadas de São Simão",
			"Sobral",
			"Álvaro"
		]
	},
	{
		nome: "Olhão",
		freguesias: [
			"Olhão",
			"Pechão",
			"Quelfes",
			"União das freguesias de Moncarapacho e Fuseta"
		]
	},
	{
		nome: "Oliveira de Azeméis",
		freguesias: [
			"Carregosa",
			"Cesar",
			"Fajões",
			"Loureiro",
			"Macieira de Sarnes",
			"Ossela",
			"São Martinho da Gândara",
			"São Roque",
			"União das freguesias de Nogueira do Cravo e Pindelo",
			"União das freguesias de Oliveira de Azeméis, Santiago de Riba-Ul, Ul, Macinhata da Seixa e Madail",
			"União das freguesias de Pinheiro da Bemposta, Travanca e Palmaz",
			"Vila de Cucujães"
		]
	},
	{
		nome: "Oliveira de Frades",
		freguesias: [
			"Arcozelo das Maias",
			"Pinheiro",
			"Ribeiradio",
			"São João da Serra",
			"São Vicente de Lafões",
			"União das freguesias de Arca e Varzielas",
			"União das freguesias de Destriz e Reigoso",
			"União das freguesias de Oliveira de Frades, Souto de Lafões e Sejães"
		]
	},
	{
		nome: "Oliveira do Bairro",
		freguesias: [
			"Oiã",
			"Oliveira do Bairro",
			"Palhaça",
			"União das freguesias de Bustos, Troviscal e Mamarrosa"
		]
	},
	{
		nome: "Oliveira do Hospital",
		freguesias: [
			"Aldeia das Dez",
			"Alvoco das Várzeas",
			"Avô",
			"Bobadela",
			"Lagares",
			"Lourosa",
			"Meruge",
			"Nogueira do Cravo",
			"Seixo da Beira",
			"São Gião",
			"Travanca de Lagos",
			"União das freguesias de Ervedal e Vila Franca da Beira",
			"União das freguesias de Lagos da Beira e Lajeosa",
			"União das freguesias de Oliveira do Hospital e São Paio de Gramaços",
			"União das freguesias de Penalva de Alva e São Sebastião da Feira",
			"União das freguesias de Santa Ovaia e Vila Pouca da Beira"
		]
	},
	{
		nome: "Ourique",
		freguesias: [
			"Ourique",
			"Santana da Serra",
			"União das freguesias de Garvão e Santa Luzia",
			"União das freguesias de Panoias e Conceição"
		]
	},
	{
		nome: "Ourém",
		freguesias: [
			"Alburitel",
			"Atouguia",
			"Caxarias",
			"Espite",
			"Fátima",
			"Nossa Senhora da Piedade",
			"Nossa Senhora das Misericórdias",
			"Seiça",
			"União das freguesias de Freixianda, Ribeira do Fárrio e Formigais",
			"União das freguesias de Gondemaria e Olival",
			"União das freguesias de Matas e Cercal",
			"União das freguesias de Rio de Couros e Casal dos Bernardos",
			"Urqueira"
		]
	},
	{
		nome: "Ovar",
		freguesias: [
			"Cortegaça",
			"Esmoriz",
			"Maceda",
			"União das freguesias de Ovar, São João, Arada e São Vicente de Pereira Jusã",
			"Válega"
		]
	},
	{
		nome: "Palmela",
		freguesias: [
			"Palmela",
			"Pinhal Novo",
			"Quinta do Anjo",
			"União das freguesias de Poceirão e Marateca"
		]
	},
	{
		nome: "Pampilhosa da Serra",
		freguesias: [
			"Cabril",
			"Dornelas do Zêzere",
			"Fajão-Vidual",
			"Janeiro de Baixo",
			"Pampilhosa da Serra",
			"Pessegueiro",
			"Portela do Fojo-Machio",
			"Unhais-o-Velho"
		]
	},
	{
		nome: "Paredes",
		freguesias: [
			"Aguiar de Sousa",
			"Astromil",
			"Baltar",
			"Beire",
			"Cete",
			"Cristelo",
			"Duas Igrejas",
			"Gandra",
			"Lordelo",
			"Louredo",
			"Parada de Todeia",
			"Paredes",
			"Rebordosa",
			"Recarei",
			"Sobreira",
			"Sobrosa",
			"Vandoma",
			"Vilela"
		]
	},
	{
		nome: "Paredes de Coura",
		freguesias: [
			"Agualonga",
			"Castanheira",
			"Coura",
			"Cunha",
			"Infesta",
			"Mozelos",
			"Padornelo",
			"Parada",
			"Romarigães",
			"Rubiães",
			"União das freguesias de Bico e Cristelo",
			"União das freguesias de Cossourado e Linhares",
			"União das freguesias de Formariz e Ferreira",
			"União das freguesias de Insalde e Porreiras",
			"União das freguesias de Paredes de Coura e Resende",
			"Vascões"
		]
	},
	{
		nome: "Paços de Ferreira",
		freguesias: [
			"Carvalhosa",
			"Eiriz",
			"Ferreira",
			"Figueiró",
			"Frazão Arreigada",
			"Freamunde",
			"Meixomil",
			"Paços de Ferreira",
			"Penamaior",
			"Raimonda",
			"Sanfins Lamoso Codessos",
			"Seroa"
		]
	},
	{
		nome: "Pedrógão Grande",
		freguesias: [
			"Graça",
			"Pedrógão Grande",
			"Vila Facaia"
		]
	},
	{
		nome: "Penacova",
		freguesias: [
			"Carvalho",
			"Figueira de Lorvão",
			"Lorvão",
			"Penacova",
			"Sazes do Lorvão",
			"União das freguesias de Friúmes e Paradela",
			"União das freguesias de Oliveira do Mondego e Travanca do Mondego",
			"União das freguesias de São Pedro de Alva e São Paio de Mondego"
		]
	},
	{
		nome: "Penafiel",
		freguesias: [
			"Abragão",
			"Boelhe",
			"Bustelo",
			"Cabeça Santa",
			"Canelas",
			"Capela",
			"Castelões",
			"Croca",
			"Duas Igrejas",
			"Eja",
			"Fonte Arcada",
			"Galegos",
			"Guilhufe e Urrô",
			"Irivo",
			"Lagares e Figueira",
			"Luzim e Vila Cova",
			"Oldrões",
			"Paço de Sousa",
			"Penafiel",
			"Perozelo",
			"Rans",
			"Recezinhos (São Mamede)",
			"Recezinhos (São Martinho)",
			"Rio Mau",
			"Rio de Moinhos",
			"Sebolido",
			"Termas de São Vicente",
			"Valpedre"
		]
	},
	{
		nome: "Penalva do Castelo",
		freguesias: [
			"Castelo de Penalva",
			"Esmolfe",
			"Germil",
			"Lusinde",
			"Pindo",
			"Real",
			"Sezures",
			"Trancozelos",
			"União das freguesias de Antas e Matela",
			"União das freguesias de Vila Cova do Covelo/Mareco",
			"Ínsua"
		]
	},
	{
		nome: "Penamacor",
		freguesias: [
			"Aranhas",
			"Benquerença",
			"Meimoa",
			"Meimão",
			"Penamacor",
			"Salvador",
			"União das freguesias de Aldeia do Bispo, Águas e Aldeia de João Pires",
			"União das freguesias de Pedrógão de São Pedro e Bemposta",
			"Vale da Senhora da Póvoa"
		]
	},
	{
		nome: "Penedono",
		freguesias: [
			"Beselga",
			"Castainço",
			"Penela da Beira",
			"Póvoa de Penela",
			"Souto",
			"União das freguesias de Antas e Ourozinho",
			"União das freguesias de Penedono e Granja"
		]
	},
	{
		nome: "Penela",
		freguesias: [
			"Cumeeira",
			"Espinhal",
			"Podentes",
			"União das freguesias de São Miguel, Santa Eufémia e Rabaçal"
		]
	},
	{
		nome: "Peniche",
		freguesias: [
			"Atouguia da Baleia",
			"Ferrel",
			"Peniche",
			"Serra d'El-Rei"
		]
	},
	{
		nome: "Peso da Régua",
		freguesias: [
			"Fontelas",
			"Loureiro",
			"Sedielos",
			"União das freguesias de Galafura e Covelinhas",
			"União das freguesias de Moura Morta e Vinhós",
			"União das freguesias de Peso da Régua e Godim",
			"União das freguesias de Poiares e Canelas",
			"Vilarinho dos Freires"
		]
	},
	{
		nome: "Pinhel",
		freguesias: [
			"Agregação das freguesias Sul de Pinhel",
			"Alto do Palurdo",
			"Alverca da Beira/Bouça Cova",
			"Ervedosa",
			"Freixedas",
			"Lamegal",
			"Lameiras",
			"Manigoto",
			"Pala",
			"Pinhel",
			"Pínzio",
			"Souro Pires",
			"Terras de Massueime",
			"União das freguesias de Atalaia e Safurdão",
			"Valbom/Bogalhal",
			"Vale do Côa",
			"Vale do Massueime",
			"Vascoveiro"
		]
	},
	{
		nome: "Pombal",
		freguesias: [
			"Abiul",
			"Almagreira",
			"Carnide",
			"Carriço",
			"Louriçal",
			"Meirinhas",
			"Pelariga",
			"Pombal",
			"Redinha",
			"União das freguesias de Guia, Ilha e Mata Mourisca",
			"União das freguesias de Santiago e São Simão de Litém e Albergaria dos Doze",
			"Vermoil",
			"Vila Cã"
		]
	},
	{
		nome: "Ponta Delgada",
		freguesias: [
			"Ajuda da Bretanha",
			"Arrifes",
			"Candelária",
			"Capelas",
			"Covoada",
			"Fajã de Baixo",
			"Fajã de Cima",
			"Fenais da Luz",
			"Feteiras",
			"Ginetes",
			"Mosteiros",
			"Pilar da Bretanha",
			"Ponta Delgada (São José)",
			"Ponta Delgada (São Pedro)",
			"Ponta Delgada (São Sebastião)",
			"Relva",
			"Remédios",
			"Rosto do Cão (Livramento)",
			"Rosto do Cão (São Roque)",
			"Santa Bárbara",
			"Santa Clara",
			"Santo António",
			"Sete Cidades",
			"São Vicente Ferreira"
		]
	},
	{
		nome: "Ponta do Sol",
		freguesias: [
			"Canhas",
			"Madalena do Mar",
			"Ponta do Sol"
		]
	},
	{
		nome: "Ponte da Barca",
		freguesias: [
			"Azias",
			"Boivães",
			"Bravães",
			"Britelo",
			"Cuide de Vila Verde",
			"Lavradas",
			"Lindoso",
			"Nogueira",
			"Oleiros",
			"Sampriz",
			"União das freguesias de Crasto, Ruivos e Grovelas",
			"União das freguesias de Entre Ambos-os-Rios, Ermida e Germil",
			"União das freguesias de Ponte da Barca, Vila Nova de Muía e Paço Vedro de Magalhães",
			"União das freguesias de Touvedo (São Lourenço e Salvador)",
			"União das freguesias de Vila Chã (São João Baptista e Santiago)",
			"Vade (São Pedro)",
			"Vade (São Tomé)"
		]
	},
	{
		nome: "Ponte de Lima",
		freguesias: [
			"Anais",
			"Arca e Ponte de Lima",
			"Arcozelo",
			"Ardegão, Freixo e Mato",
			"Associação de freguesias do Vale do Neiva",
			"Beiral do Lima",
			"Bertiandos",
			"Boalhosa",
			"Brandara",
			"Bárrio e Cepões",
			"Cabaços e Fojo Lobal",
			"Cabração e Moreira do Lima",
			"Calheiros",
			"Calvelo",
			"Correlhã",
			"Estorãos",
			"Facha",
			"Feitosa",
			"Fontão",
			"Fornelos e Queijada",
			"Friastelas",
			"Gandra",
			"Gemieira",
			"Gondufe",
			"Labruja",
			"Labrujó, Rendufe e Vilar do Monte",
			"Navió e Vitorino dos Piães",
			"Poiares",
			"Rebordões (Santa Maria)",
			"Rebordões (Souto)",
			"Refóios do Lima",
			"Ribeira",
			"Santa Comba",
			"Santa Cruz do Lima",
			"Seara",
			"Serdedelo",
			"Sá",
			"São Pedro d'Arcos",
			"Vitorino das Donas"
		]
	},
	{
		nome: "Ponte de Sor",
		freguesias: [
			"Foros de Arrão",
			"Galveias",
			"Longomel",
			"Montargil",
			"União das freguesias de Ponte de Sor, Tramaga e Vale de Açor"
		]
	},
	{
		nome: "Portalegre",
		freguesias: [
			"Alagoa",
			"Alegrete",
			"Fortios",
			"União das freguesias da Sé e São Lourenço",
			"União das freguesias de Reguengo e São Julião",
			"União das freguesias de Ribeira de Nisa e Carreiras",
			"Urra"
		]
	},
	{
		nome: "Portel",
		freguesias: [
			"Monte do Trigo",
			"Portel",
			"Santana",
			"União das freguesias de Amieira e Alqueva",
			"União das freguesias de São Bartolomeu do Outeiro e Oriola",
			"Vera Cruz"
		]
	},
	{
		nome: "Portimão",
		freguesias: [
			"Alvor",
			"Mexilhoeira Grande",
			"Portimão"
		]
	},
	{
		nome: "Porto",
		freguesias: [
			"Bonfim",
			"Campanhã",
			"Paranhos",
			"Ramalde",
			"União das freguesias de Aldoar, Foz do Douro e Nevogilde",
			"União das freguesias de Cedofeita, Santo Ildefonso, Sé, Miragaia, São Nicolau e Vitória",
			"União das freguesias de Lordelo do Ouro e Massarelos"
		]
	},
	{
		nome: "Porto de Mós",
		freguesias: [
			"Alqueidão da Serra",
			"Calvaria de Cima",
			"Juncal",
			"Mira de Aire",
			"Pedreiras",
			"Porto de Mós - São João Baptista e São Pedro",
			"Serro Ventoso",
			"São Bento",
			"União das freguesias de Alvados e Alcaria",
			"União das freguesias de Arrimal e Mendiga"
		]
	},
	{
		nome: "Porto Moniz",
		freguesias: [
			"Achadas da Cruz",
			"Porto Moniz",
			"Ribeira da Janela",
			"Seixal"
		]
	},
	{
		nome: "Porto Santo",
		freguesias: [
			"Porto Santo"
		]
	},
	{
		nome: "Povoação",
		freguesias: [
			"Faial da Terra",
			"Furnas",
			"Nossa Senhora dos Remédios",
			"Povoação",
			"Ribeira Quente",
			"Água Retorta"
		]
	},
	{
		nome: "Praia da Vitória",
		freguesias: [
			"Agualva",
			"Biscoitos",
			"Cabo da Praia",
			"Fonte do Bastardo",
			"Fontinhas",
			"Lajes",
			"Porto Martins",
			"Praia da Vitória (Santa Cruz)",
			"Quatro Ribeiras",
			"São Brás",
			"Vila Nova"
		]
	},
	{
		nome: "Proença-a-Nova",
		freguesias: [
			"Montes da Senhora",
			"São Pedro do Esteval",
			"União das freguesias de Proença-a-Nova e Peral",
			"União das freguesias de Sobreira Formosa e Alvito da Beira"
		]
	},
	{
		nome: "Póvoa de Lanhoso",
		freguesias: [
			"Covelas",
			"Ferreiros",
			"Galegos",
			"Garfe",
			"Geraz do Minho",
			"Lanhoso",
			"Monsul",
			"Póvoa de Lanhoso (Nossa Senhora do Amparo)",
			"Rendufinho",
			"Santo Emilião",
			"Serzedelo",
			"Sobradelo da Goma",
			"São João de Rei",
			"Taíde",
			"Travassos",
			"União das freguesias de Calvos e Frades",
			"União das freguesias de Campos e Louredo",
			"União das freguesias de Esperança e Brunhais",
			"União das freguesias de Fonte Arcada e Oliveira",
			"União das freguesias de Verim, Friande e Ajude",
			"União das freguesias de Águas Santas e Moure",
			"Vilela"
		]
	},
	{
		nome: "Póvoa de Varzim",
		freguesias: [
			"Balazar",
			"Estela",
			"Laundos",
			"Rates",
			"União das freguesias da Póvoa de Varzim, Beiriz e Argivai",
			"União das freguesias de Aguçadoura e Navais",
			"União das freguesias de Aver-o-Mar, Amorim e Terroso"
		]
	},
	{
		nome: "Redondo",
		freguesias: [
			"Montoito",
			"Redondo"
		]
	},
	{
		nome: "Reguengos de Monsaraz",
		freguesias: [
			"Corval",
			"Monsaraz",
			"Reguengos de Monsaraz",
			"União das freguesias de Campo e Campinho"
		]
	},
	{
		nome: "Resende",
		freguesias: [
			"Barrô",
			"Cárquere",
			"Paus",
			"Resende",
			"São Cipriano",
			"São João de Fontoura",
			"São Martinho de Mouros",
			"União das freguesias de Anreade e São Romão de Aregos",
			"União das freguesias de Felgueiras e Feirão",
			"União das freguesias de Freigil e Miomães",
			"União das freguesias de Ovadas e Panchorra"
		]
	},
	{
		nome: "Ribeira Brava",
		freguesias: [
			"Campanário",
			"Ribeira Brava",
			"Serra de Água",
			"Tabua"
		]
	},
	{
		nome: "Ribeira de Pena",
		freguesias: [
			"Alvadia",
			"Canedo",
			"Santa Marinha",
			"União das freguesias de Cerva e Limões",
			"União das freguesias de Ribeira de Pena (Salvador) e Santo Aleixo de Além-Tâmega"
		]
	},
	{
		nome: "Ribeira Grande",
		freguesias: [
			"Calhetas",
			"Fenais da Ajuda",
			"Lomba da Maia",
			"Lomba de São Pedro",
			"Maia",
			"Pico da Pedra",
			"Porto Formoso",
			"Rabo de Peixe",
			"Ribeira Grande (Conceição)",
			"Ribeira Grande (Matriz)",
			"Ribeira Seca",
			"Ribeirinha",
			"Santa Bárbara",
			"São Brás"
		]
	},
	{
		nome: "Rio Maior",
		freguesias: [
			"Alcobertas",
			"Arrouquelas",
			"Asseiceira",
			"Fráguas",
			"Rio Maior",
			"São Sebastião",
			"União das freguesias de Azambujeira e Malaqueijo",
			"União das freguesias de Marmeleira e Assentiz",
			"União das freguesias de Outeiro da Cortiçada e Arruda dos Pisões",
			"União das freguesias de São João da Ribeira e Ribeira de São João"
		]
	},
	{
		nome: "Sabrosa",
		freguesias: [
			"Celeirós",
			"Covas do Douro",
			"Gouvinhas",
			"Parada de Pinhão",
			"Paços",
			"Sabrosa",
			"Souto Maior",
			"São Lourenço de Ribapinhão",
			"Torre do Pinhão",
			"União das freguesias de Provesende, Gouvães do Douro e São Cristóvão do Douro",
			"União das freguesias de São Martinho de Antas e Paradela de Guiães",
			"Vilarinho de São Romão"
		]
	},
	{
		nome: "Sabugal",
		freguesias: [
			"Aldeia Velha",
			"Aldeia da Ponte",
			"Aldeia do Bispo",
			"Alfaiates",
			"Baraçal",
			"Bendada",
			"Bismula",
			"Casteleiro",
			"Cerdeira",
			"Fóios",
			"Malcata",
			"Nave",
			"Quadrazais",
			"Quintas de São Bartolomeu",
			"Rapoula do Côa",
			"Rebolosa",
			"Rendo",
			"Sortelha",
			"Souto",
			"União das freguesias de Aldeia da Ribeira, Vilar Maior e Badamalos",
			"União das freguesias de Lajeosa e Forcalhos",
			"União das freguesias de Pousafoles do Bispo, Pena Lobo e Lomba",
			"União das freguesias de Ruvina, Ruivós e Vale das Éguas",
			"União das freguesias de Santo Estêvão e Moita",
			"União das freguesias de Seixo do Côa e Vale Longo",
			"União das freguesias do Sabugal e Aldeia de Santo António",
			"Vale de Espinho",
			"Vila Boa",
			"Vila do Touro",
			"Águas Belas"
		]
	},
	{
		nome: "Salvaterra de Magos",
		freguesias: [
			"Marinhais",
			"Muge",
			"União das freguesias de Glória do Ribatejo e Granho",
			"União das freguesias de Salvaterra de Magos e Foros de Salvaterra"
		]
	},
	{
		nome: "Santa Comba Dão",
		freguesias: [
			"Pinheiro de Ázere",
			"São Joaninho",
			"São João de Areias",
			"União das freguesias de Ovoa e Vimieiro",
			"União das freguesias de Santa Comba Dão e Couto do Mosteiro",
			"União das freguesias de Treixedo e Nagozela"
		]
	},
	{
		nome: "Santa Cruz",
		freguesias: [
			"Camacha",
			"Caniço",
			"Gaula",
			"Santa Cruz",
			"Santo António da Serra"
		]
	},
	{
		nome: "Santa Cruz da Graciosa",
		freguesias: [
			"Guadalupe",
			"Luz",
			"Santa Cruz da Graciosa",
			"São Mateus"
		]
	},
	{
		nome: "Santa Cruz das Flores",
		freguesias: [
			"Caveira",
			"Cedros",
			"Ponta Delgada",
			"Santa Cruz das Flores"
		]
	},
	{
		nome: "Santa Maria da Feira",
		freguesias: [
			"Argoncilhe",
			"Arrifana",
			"Escapães",
			"Fiães",
			"Fornos",
			"Lourosa",
			"Milheirós de Poiares",
			"Mozelos",
			"Nogueira da Regedoura",
			"Paços de Brandão",
			"Rio Meão",
			"Romariz",
			"Sanguedo",
			"Santa Maria de Lamas",
			"São João de Ver",
			"São Paio de Oleiros",
			"União das freguesias de Caldas de São Jorge e Pigeiros",
			"União das freguesias de Canedo, Vale e Vila Maior",
			"União das freguesias de Lobão, Gião, Louredo e Guisande",
			"União das freguesias de Santa Maria da Feira, Travanca, Sanfins e Espargo",
			"União de freguesias de São Miguel de Souto e Mosteirô"
		]
	},
	{
		nome: "Santa Marta de Penaguião",
		freguesias: [
			"Alvações do Corgo",
			"Cumieira",
			"Fontes",
			"Medrões",
			"Sever",
			"União das freguesias de Lobrigos (São Miguel e São João Baptista) e Sanhoane",
			"União das freguesias de Louredo e Fornelos"
		]
	},
	{
		nome: "Santana",
		freguesias: [
			"Arco de São Jorge",
			"Faial",
			"Ilha",
			"Santana",
			"São Jorge",
			"São Roque do Faial"
		]
	},
	{
		nome: "Santarém",
		freguesias: [
			"Abitureiras",
			"Abrã",
			"Alcanede",
			"Alcanhões",
			"Almoster",
			"Amiais de Baixo",
			"Arneiro das Milhariças",
			"Gançaria",
			"Moçarria",
			"Pernes",
			"Póvoa da Isenta",
			"União das freguesias de Achete, Azoia de Baixo e Póvoa de Santarém",
			"União das freguesias de Azoia de Cima e Tremês",
			"União das freguesias de Casével e Vaqueiros",
			"União das freguesias de Romeira e Várzea",
			"União das freguesias de São Vicente do Paul e Vale de Figueira",
			"União de freguesias da cidade de Santarém",
			"Vale de Santarém"
		]
	},
	{
		nome: "Santiago do Cacém",
		freguesias: [
			"Abela",
			"Alvalade",
			"Cercal",
			"Ermidas-Sado",
			"Santo André",
			"São Francisco da Serra",
			"União das freguesias de Santiago do Cacém, Santa Cruz e São Bartolomeu da Serra",
			"União das freguesias de São Domingos e Vale de Água"
		]
	},
	{
		nome: "Santo Tirso",
		freguesias: [
			"Agrela",
			"Aves",
			"Monte Córdova",
			"Negrelos (São Tomé)",
			"Rebordões",
			"Reguenga",
			"Roriz",
			"União das freguesias de Areias, Sequeiró, Lama e Palmeira",
			"União das freguesias de Carreira e Refojos de Riba de Ave",
			"União das freguesias de Lamelas e Guimarei",
			"União das freguesias de Santo Tirso, Couto (Santa Cristina e São Miguel) e Burgães",
			"Vila Nova do Campo",
			"Vilarinho",
			"Água Longa"
		]
	},
	{
		nome: "Sardoal",
		freguesias: [
			"Alcaravela",
			"Santiago de Montalegre",
			"Sardoal",
			"Valhascos"
		]
	},
	{
		nome: "Seia",
		freguesias: [
			"Alvoco da Serra",
			"Girabolhos",
			"Loriga",
			"Paranhos",
			"Pinhanços",
			"Sabugueiro",
			"Sandomil",
			"Santa Comba",
			"Santiago",
			"Sazes da Beira",
			"Teixeira",
			"Travancinha",
			"União das freguesias de Carragozela e Várzea de Meruge",
			"União das freguesias de Sameice e Santa Eulália",
			"União das freguesias de Santa Marinha e São Martinho",
			"União das freguesias de Seia, São Romão e Lapa dos Dinheiros",
			"União das freguesias de Torrozelo e Folhadosa",
			"União das freguesias de Tourais e Lajes",
			"União das freguesias de Vide e Cabeça",
			"Valezim",
			"Vila Cova à Coelheira"
		]
	},
	{
		nome: "Seixal",
		freguesias: [
			"Amora",
			"Corroios",
			"Fernão Ferro",
			"União das freguesias do Seixal, Arrentela e Aldeia de Paio Pires"
		]
	},
	{
		nome: "Sernancelhe",
		freguesias: [
			"Arnas",
			"Carregal",
			"Chosendo",
			"Cunha",
			"Faia",
			"Granjal",
			"Lamosa",
			"Quintela",
			"União das freguesias de Ferreirim e Macieira",
			"União das freguesias de Fonte Arcada e Escurquela",
			"União das freguesias de Penso e Freixinho",
			"União das freguesias de Sernancelhe e Sarzeda",
			"Vila da Ponte"
		]
	},
	{
		nome: "Serpa",
		freguesias: [
			"Brinches",
			"Pias",
			"União das freguesias de Serpa (Salvador e Santa Maria)",
			"União das freguesias de Vila Nova de São Bento e Vale de Vargo",
			"Vila Verde de Ficalho"
		]
	},
	{
		nome: "Sertã",
		freguesias: [
			"Cabeçudo",
			"Carvalhal",
			"Castelo",
			"Pedrógão Pequeno",
			"Sertã",
			"Troviscal",
			"União das freguesias de Cernache do Bonjardim, Nesperal e Palhais",
			"União das freguesias de Cumeada e Marmeleiro",
			"União das freguesias de Ermida e Figueiredo",
			"Várzea dos Cavaleiros"
		]
	},
	{
		nome: "Sesimbra",
		freguesias: [
			"Quinta do Conde",
			"Sesimbra (Castelo)",
			"Sesimbra (Santiago)"
		]
	},
	{
		nome: "Setúbal",
		freguesias: [
			"Gâmbia-Pontes-Alto da Guerra",
			"Sado",
			"Setúbal (São Sebastião)",
			"União das freguesias de Azeitão (São Lourenço e São Simão)",
			"União das freguesias de Setúbal (São Julião, Nossa Senhora da Anunciada e Santa Maria da Graça)"
		]
	},
	{
		nome: "Sever do Vouga",
		freguesias: [
			"Couto de Esteves",
			"Pessegueiro do Vouga",
			"Rocas do Vouga",
			"Sever do Vouga",
			"Talhadas",
			"União das freguesias de Cedrim e Paradela",
			"União das freguesias de Silva Escura e Dornelas"
		]
	},
	{
		nome: "Silves",
		freguesias: [
			"Armação de Pêra",
			"Silves",
			"São Bartolomeu de Messines",
			"São Marcos da Serra",
			"União das freguesias de Alcantarilha e Pêra",
			"União das freguesias de Algoz e Tunes"
		]
	},
	{
		nome: "Sines",
		freguesias: [
			"Porto Covo",
			"Sines"
		]
	},
	{
		nome: "Sintra",
		freguesias: [
			"Algueirão-Mem Martins",
			"Casal de Cambra",
			"Colares",
			"Rio de Mouro",
			"União das freguesias de Agualva e Mira-Sintra",
			"União das freguesias de Almargem do Bispo, Pêro Pinheiro e Montelavar",
			"União das freguesias de Massamá e Monte Abraão",
			"União das freguesias de Queluz e Belas",
			"União das freguesias de Sintra (Santa Maria e São Miguel, São Martinho e São Pedro de Penaferrim)",
			"União das freguesias de São João das Lampas e Terrugem",
			"União das freguesias do Cacém e São Marcos"
		]
	},
	{
		nome: "Sobral de Monte Agraço",
		freguesias: [
			"Santo Quintino",
			"Sapataria",
			"Sobral de Monte Agraço"
		]
	},
	{
		nome: "Soure",
		freguesias: [
			"Alfarelos",
			"Figueiró do Campo",
			"Granja do Ulmeiro",
			"Samuel",
			"Soure",
			"Tapéus",
			"União das freguesias de Degracias e Pombalinho",
			"União das freguesias de Gesteira e Brunhós",
			"Vila Nova de Anços",
			"Vinha da Rainha"
		]
	},
	{
		nome: "Sousel",
		freguesias: [
			"Cano",
			"Casa Branca",
			"Santo Amaro",
			"Sousel"
		]
	},
	{
		nome: "Sátão",
		freguesias: [
			"Avelal",
			"Ferreira de Aves",
			"Mioma",
			"Rio de Moinhos",
			"Silvã de Cima",
			"Sátão",
			"São Miguel de Vila Boa",
			"União das freguesias de Romãs, Decermilo e Vila Longa",
			"União das freguesias de Águas Boas e Forles"
		]
	},
	{
		nome: "São Brás de Alportel",
		freguesias: [
			"São Brás de Alportel"
		]
	},
	{
		nome: "São João da Madeira",
		freguesias: [
			"São João da Madeira"
		]
	},
	{
		nome: "São João da Pesqueira",
		freguesias: [
			"Castanheiro do Sul",
			"Ervedosa do Douro",
			"Nagozelo do Douro",
			"Paredes da Beira",
			"Riodades",
			"Soutelo do Douro",
			"União das freguesias de São João da Pesqueira e Várzea de Trevões",
			"União das freguesias de Trevões e Espinhosa",
			"União das freguesias de Vilarouco e Pereiros",
			"Vale de Figueira",
			"Valongo dos Azeites"
		]
	},
	{
		nome: "São Pedro do Sul",
		freguesias: [
			"Bordonhos",
			"Figueiredo de Alva",
			"Manhouce",
			"Pindelo dos Milagres",
			"Pinho",
			"Serrazes",
			"Sul",
			"São Félix",
			"União das freguesias de Carvalhais e Candal",
			"União das freguesias de Santa Cruz da Trapa e São Cristóvão de Lafões",
			"União das freguesias de São Martinho das Moitas e Covas do Rio",
			"União das freguesias de São Pedro do Sul, Várzea e Baiões",
			"Valadares",
			"Vila Maior"
		]
	},
	{
		nome: "São Roque do Pico",
		freguesias: [
			"Prainha",
			"Santa Luzia",
			"Santo Amaro",
			"Santo António",
			"São Roque do Pico"
		]
	},
	{
		nome: "São Vicente",
		freguesias: [
			"Boaventura",
			"Ponta Delgada",
			"São Vicente"
		]
	},
	{
		nome: "Tabuaço",
		freguesias: [
			"Adorigo",
			"Arcos",
			"Chavães",
			"Desejosa",
			"Granja do Tedo",
			"Longa",
			"Sendim",
			"Tabuaço",
			"União das freguesias de Barcos e Santa Leocádia",
			"União das freguesias de Paradela e Granjinha",
			"União das freguesias de Pinheiros e Vale de Figueira",
			"União das freguesias de Távora e Pereiro",
			"Valença do Douro"
		]
	},
	{
		nome: "Tarouca",
		freguesias: [
			"Mondim da Beira",
			"Salzedas",
			"São João de Tarouca",
			"União das freguesias de Gouviães e Ucanha",
			"União das freguesias de Granja Nova e Vila Chã da Beira",
			"União das freguesias de Tarouca e Dálvares",
			"Várzea da Serra"
		]
	},
	{
		nome: "Tavira",
		freguesias: [
			"Cachopo",
			"Santa Catarina da Fonte do Bispo",
			"Santa Luzia",
			"União das freguesias de Conceição e Cabanas de Tavira",
			"União das freguesias de Luz de Tavira e Santo Estêvão",
			"União das freguesias de Tavira (Santa Maria e Santiago)"
		]
	},
	{
		nome: "Terras de Bouro",
		freguesias: [
			"Balança",
			"Campo do Gerês",
			"Carvalheira",
			"Covide",
			"Gondoriz",
			"Moimenta",
			"Ribeira",
			"Rio Caldo",
			"Souto",
			"União das freguesias de Chamoim e Vilar",
			"União das freguesias de Chorense e Monte",
			"União das freguesias de Cibões e Brufe",
			"Valdosende",
			"Vilar da Veiga"
		]
	},
	{
		nome: "Tomar",
		freguesias: [
			"Asseiceira",
			"Carregueiros",
			"Olalhas",
			"Paialvo",
			"Sabacheira",
			"São Pedro de Tomar",
			"União das freguesias de Além da Ribeira e Pedreira",
			"União das freguesias de Casais e Alviobeira",
			"União das freguesias de Madalena e Beselga",
			"União das freguesias de Serra e Junceira",
			"União das freguesias de Tomar (São João Baptista) e Santa Maria dos Olivais"
		]
	},
	{
		nome: "Tondela",
		freguesias: [
			"Campo de Besteiros",
			"Canas de Santa Maria",
			"Castelões",
			"Dardavaz",
			"Ferreirós do Dão",
			"Guardão",
			"Lajeosa do Dão",
			"Lobão da Beira",
			"Molelos",
			"Parada de Gonta",
			"Santiago de Besteiros",
			"Tonda",
			"União das freguesias de Barreiro de Besteiros e Tourigo",
			"União das freguesias de Caparrosa e Silvares",
			"União das freguesias de Mouraz e Vila Nova da Rainha",
			"União das freguesias de São João do Monte e Mosteirinho",
			"União das freguesias de São Miguel do Outeiro e Sabugosa",
			"União das freguesias de Tondela e Nandufe",
			"União das freguesias de Vilar de Besteiros e Mosteiro de Fráguas"
		]
	},
	{
		nome: "Torre de Moncorvo",
		freguesias: [
			"Açoreira",
			"Cabeça Boa",
			"Carviçais",
			"Castedo",
			"Horta da Vilariça",
			"Larinho",
			"Lousa",
			"Mós",
			"Torre de Moncorvo",
			"União das freguesias de Adeganha e Cardanha",
			"União das freguesias de Felgar e Souto da Velha",
			"União das freguesias de Felgueiras e Maçores",
			"União das freguesias de Urros e Peredo dos Castelhanos"
		]
	},
	{
		nome: "Torres Novas",
		freguesias: [
			"Assentiz",
			"Chancelaria",
			"Meia Via",
			"Pedrógão",
			"Riachos",
			"União das freguesias de Brogueira, Parceiros de Igreja e Alcorochel",
			"União das freguesias de Olaia e Paço",
			"União das freguesias de Torres Novas (Santa Maria, Salvador e Santiago)",
			"União das freguesias de Torres Novas (São Pedro), Lapas e Ribeira Branca",
			"Zibreira"
		]
	},
	{
		nome: "Torres Vedras",
		freguesias: [
			"Freiria",
			"Ponte do Rol",
			"Ramalhal",
			"Santa Maria, São Pedro e Matacães",
			"Silveira",
			"São Pedro da Cadeira",
			"Turcifal",
			"União das freguesias de A dos Cunhados e Maceira",
			"União das freguesias de Campelos e Outeiro da Cabeça",
			"União das freguesias de Carvoeira e Carmões",
			"União das freguesias de Dois Portos e Runa",
			"União das freguesias de Maxial e Monte Redondo",
			"Ventosa"
		]
	},
	{
		nome: "Trancoso",
		freguesias: [
			"Aldeia Nova",
			"Castanheira",
			"Cogula",
			"Cótimos",
			"Fiães",
			"Granja",
			"Guilheiro",
			"Moimentinha",
			"Moreira de Rei",
			"Palhais",
			"Póvoa do Concelho",
			"Reboleiro",
			"Rio de Mel",
			"Tamanhos",
			"União das freguesias de Freches e Torres",
			"União das freguesias de Torre do Terrenho, Sebadelhe da Serra e Terrenho",
			"União das freguesias de Trancoso (São Pedro e Santa Maria) e Souto Maior",
			"União das freguesias de Vale do Seixo e Vila Garcia",
			"União das freguesias de Vila Franca das Naves e Feital",
			"União das freguesias de Vilares e Carnicães",
			"Valdujo"
		]
	},
	{
		nome: "Trofa",
		freguesias: [
			"Covelas",
			"Muro",
			"União das freguesias de Alvarelhos e Guidões",
			"União das freguesias de Bougado (São Martinho e Santiago)",
			"União das freguesias de Coronado (São Romão e São Mamede)"
		]
	},
	{
		nome: "Tábua",
		freguesias: [
			"Candosa",
			"Carapinha",
			"Midões",
			"Mouronho",
			"Póvoa de Midões",
			"São João da Boa Vista",
			"Tábua",
			"União das freguesias de Covas e Vila Nova de Oliveirinha",
			"União das freguesias de Espariz e Sinde",
			"União das freguesias de Pinheiro de Coja e Meda de Mouros",
			"União das freguesias de Ázere e Covelo"
		]
	},
	{
		nome: "Vagos",
		freguesias: [
			"Calvão",
			"Gafanha da Boa Hora",
			"Ouca",
			"Santo André de Vagos",
			"Sosa",
			"União das freguesias de Fonte de Angeão e Covão do Lobo",
			"União das freguesias de Ponte de Vagos e Santa Catarina",
			"União das freguesias de Vagos e Santo António"
		]
	},
	{
		nome: "Vale de Cambra",
		freguesias: [
			"Arões",
			"Cepelos",
			"Junqueira",
			"Macieira de Cambra",
			"Roge",
			"São Pedro de Castelões",
			"União das freguesias de Vila Chã, Codal e Vila Cova de Perrinho"
		]
	},
	{
		nome: "Valença",
		freguesias: [
			"Boivão",
			"Cerdal",
			"Fontoura",
			"Friestas",
			"Ganfei",
			"São Pedro da Torre",
			"União das freguesias de Gandra e Taião",
			"União das freguesias de Gondomil e Sanfins",
			"União das freguesias de São Julião e Silva",
			"União das freguesias de Valença, Cristelo Covo e Arão",
			"Verdoejo"
		]
	},
	{
		nome: "Valongo",
		freguesias: [
			"Alfena",
			"Ermesinde",
			"União das freguesias de Campo e Sobrado",
			"Valongo"
		]
	},
	{
		nome: "Valpaços",
		freguesias: [
			"Algeriz",
			"Bouçoães",
			"Canaveses",
			"Carrazedo de Montenegro e Curros",
			"Ervões",
			"Fornos do Pinhal",
			"Friões",
			"Lebução, Fiães e Nozelos",
			"Padrela e Tazem",
			"Possacos",
			"Rio Torto",
			"Santa Maria de Emeres",
			"Santa Valha",
			"Santiago da Ribeira de Alhariz",
			"Serapicos",
			"Sonim e Barreiros",
			"São João da Corveira",
			"São Pedro de Veiga de Lila",
			"Tinhela e Alvarelhos",
			"Vales",
			"Valpaços e Sanfins",
			"Vassal",
			"Veiga de Lila",
			"Vilarandelo",
			"Água Revés e Crasto"
		]
	},
	{
		nome: "Velas",
		freguesias: [
			"Manadas (Santa Bárbara)",
			"Norte Grande (Neves)",
			"Rosais",
			"Santo Amaro",
			"Urzelina (São Mateus)",
			"Velas (São Jorge)"
		]
	},
	{
		nome: "Vendas Novas",
		freguesias: [
			"Landeira",
			"Vendas Novas"
		]
	},
	{
		nome: "Viana do Alentejo",
		freguesias: [
			"Aguiar",
			"Alcáçovas",
			"Viana do Alentejo"
		]
	},
	{
		nome: "Viana do Castelo",
		freguesias: [
			"Afife",
			"Alvarães",
			"Amonde",
			"Anha",
			"Areosa",
			"Carreço",
			"Castelo do Neiva",
			"Chafé",
			"Darque",
			"Freixieiro de Soutelo",
			"Lanheses",
			"Montaria",
			"Mujães",
			"Outeiro",
			"Perre",
			"Santa Marta de Portuzelo",
			"São Romão de Neiva",
			"União das freguesias de Barroselas e Carvoeiro",
			"União das freguesias de Cardielos e Serreleis",
			"União das freguesias de Geraz do Lima (Santa Maria, Santa Leocádia e Moreira) e Deão",
			"União das freguesias de Mazarefes e Vila Fria",
			"União das freguesias de Nogueira, Meixedo e Vilar de Murteda",
			"União das freguesias de Subportela, Deocriste e Portela Susã",
			"União das freguesias de Torre e Vila Mou",
			"União das freguesias de Viana do Castelo (Santa Maria Maior e Monserrate) e Meadela",
			"Vila Franca",
			"Vila de Punhe"
		]
	},
	{
		nome: "Vidigueira",
		freguesias: [
			"Pedrógão",
			"Selmes",
			"Vidigueira",
			"Vila de Frades"
		]
	},
	{
		nome: "Vieira do Minho",
		freguesias: [
			"Cantelães",
			"Eira Vedra",
			"Guilhofrei",
			"Louredo",
			"Mosteiro",
			"Parada de Bouro",
			"Pinheiro",
			"Rossas",
			"Salamonde",
			"Tabuaças",
			"União das freguesias de Anissó e Soutelo",
			"União das freguesias de Anjos e Vilar do Chão",
			"União das freguesias de Caniçada e Soengas",
			"União das freguesias de Ruivães e Campos",
			"União das freguesias de Ventosa e Cova",
			"Vieira do Minho"
		]
	},
	{
		nome: "Vila de Rei",
		freguesias: [
			"Fundada",
			"São João do Peso",
			"Vila de Rei"
		]
	},
	{
		nome: "Vila do Bispo",
		freguesias: [
			"Barão de São Miguel",
			"Budens",
			"Sagres",
			"Vila do Bispo e Raposeira"
		]
	},
	{
		nome: "Vila do Conde",
		freguesias: [
			"Aveleda",
			"Azurara",
			"Fajozes",
			"Gião",
			"Guilhabreu",
			"Junqueira",
			"Labruge",
			"Macieira da Maia",
			"Mindelo",
			"Modivas",
			"União das freguesias de Bagunte, Ferreiró, Outeiro Maior e Parada",
			"União das freguesias de Fornelo e Vairão",
			"União das freguesias de Malta e Canidelo",
			"União das freguesias de Retorta e Tougues",
			"União das freguesias de Rio Mau e Arcos",
			"União das freguesias de Touguinha e Touguinhó",
			"União das freguesias de Vilar e Mosteiró",
			"Vila Chã",
			"Vila do Conde",
			"Vilar de Pinheiro",
			"Árvore"
		]
	},
	{
		nome: "Vila do Porto",
		freguesias: [
			"Almagreira",
			"Santa Bárbara",
			"Santo Espírito",
			"São Pedro",
			"Vila do Porto"
		]
	},
	{
		nome: "Vila Flor",
		freguesias: [
			"Benlhevai",
			"Freixiel",
			"Roios",
			"Sampaio",
			"Samões",
			"Santa Comba de Vilariça",
			"Seixo de Manhoses",
			"Trindade",
			"União das freguesias de Assares e Lodões",
			"União das freguesias de Candoso e Carvalho de Egas",
			"União das freguesias de Valtorno e Mourão",
			"União das freguesias de Vila Flor e Nabo",
			"União das freguesias de Vilas Boas e Vilarinho das Azenhas",
			"Vale Frechoso"
		]
	},
	{
		nome: "Vila Franca de Xira",
		freguesias: [
			"União das freguesias de Alhandra, São João dos Montes e Calhandriz",
			"União das freguesias de Alverca do Ribatejo e Sobralinho",
			"União das freguesias de Castanheira do Ribatejo e Cachoeiras",
			"União das freguesias de Póvoa de Santa Iria e Forte da Casa",
			"Vialonga",
			"Vila Franca de Xira"
		]
	},
	{
		nome: "Vila Franca do Campo",
		freguesias: [
			"Ponta Garça",
			"Ribeira Seca",
			"Ribeira das Tainhas",
			"Vila Franca do Campo (São Miguel)",
			"Vila Franca do Campo (São Pedro)",
			"Água de Alto"
		]
	},
	{
		nome: "Vila Nova da Barquinha",
		freguesias: [
			"Atalaia",
			"Praia do Ribatejo",
			"Tancos",
			"Vila Nova da Barquinha"
		]
	},
	{
		nome: "Vila Nova de Cerveira",
		freguesias: [
			"Cornes",
			"Covas",
			"Gondarém",
			"Loivo",
			"Mentrestido",
			"Sapardos",
			"Sopo",
			"União das freguesias de Campos e Vila Meã",
			"União das freguesias de Candemil e Gondar",
			"União das freguesias de Reboreda e Nogueira",
			"União das freguesias de Vila Nova de Cerveira e Lovelhe"
		]
	},
	{
		nome: "Vila Nova de Famalicão",
		freguesias: [
			"Bairro",
			"Brufe",
			"Castelões",
			"Cruz",
			"Delães",
			"Fradelos",
			"Gavião",
			"Joane",
			"Landim",
			"Louro",
			"Lousado",
			"Mogege",
			"Nine",
			"Oliveira (Santa Maria)",
			"Oliveira (São Mateus)",
			"Pedome",
			"Pousada de Saramagos",
			"Requião",
			"Riba de Ave",
			"Ribeirão",
			"União das freguesias de Antas e Abade de Vermoim",
			"União das freguesias de Arnoso (Santa Maria e Santa Eulália) e Sezures",
			"União das freguesias de Avidos e Lagoa",
			"União das freguesias de Carreira e Bente",
			"União das freguesias de Esmeriz e Cabeçudos",
			"União das freguesias de Gondifelos, Cavalões e Outiz",
			"União das freguesias de Lemenhe, Mouquim e Jesufrei",
			"União das freguesias de Ruivães e Novais",
			"União das freguesias de Seide",
			"União das freguesias de Vale (São Cosme), Telhado e Portela",
			"União das freguesias de Vila Nova de Famalicão e Calendário",
			"Vale (São Martinho)",
			"Vermoim",
			"Vilarinho das Cambas"
		]
	},
	{
		nome: "Vila Nova de Foz Côa",
		freguesias: [
			"Almendra",
			"Castelo Melhor",
			"Cedovim",
			"Chãs",
			"Custóias",
			"Freixo de Numão",
			"Horta",
			"Muxagata",
			"Numão",
			"Santa Comba",
			"Sebadelhe",
			"Seixas",
			"Touça",
			"Vila Nova de Foz Côa"
		]
	},
	{
		nome: "Vila Nova de Gaia",
		freguesias: [
			"Arcozelo",
			"Avintes",
			"Canelas",
			"Canidelo",
			"Madalena",
			"Oliveira do Douro",
			"São Félix da Marinha",
			"União das freguesias de Grijó e Sermonde",
			"União das freguesias de Gulpilhares e Valadares",
			"União das freguesias de Mafamude e Vilar do Paraíso",
			"União das freguesias de Pedroso e Seixezelo",
			"União das freguesias de Sandim, Olival, Lever e Crestuma",
			"União das freguesias de Santa Marinha e São Pedro da Afurada",
			"União das freguesias de Serzedo e Perosinho",
			"Vilar de Andorinho"
		]
	},
	{
		nome: "Vila Nova de Paiva",
		freguesias: [
			"Pendilhe",
			"Queiriga",
			"Touro",
			"União das freguesias de Vila Nova de Paiva, Alhais e Fráguas",
			"Vila Cova à Coelheira"
		]
	},
	{
		nome: "Vila Nova de Poiares",
		freguesias: [
			"Arrifana",
			"Lavegadas",
			"Poiares (Santo André)",
			"São Miguel de Poiares"
		]
	},
	{
		nome: "Vila Pouca de Aguiar",
		freguesias: [
			"Alfarela de Jales",
			"Alvão",
			"Bornes de Aguiar",
			"Bragado",
			"Capeludos",
			"Sabroso de Aguiar",
			"Soutelo de Aguiar",
			"Telões",
			"Tresminas",
			"União das freguesias de Pensalvos e Parada de Monteiros",
			"Valoura",
			"Vila Pouca de Aguiar",
			"Vreia de Bornes",
			"Vreia de Jales"
		]
	},
	{
		nome: "Vila Real",
		freguesias: [
			"Abaças",
			"Andrães",
			"Arroios",
			"Campeã",
			"Folhadela",
			"Guiães",
			"Lordelo",
			"Mateus",
			"Mondrões",
			"Parada de Cunhos",
			"Torgueda",
			"União das freguesias de Adoufe e Vilarinho de Samardã",
			"União das freguesias de Borbela e Lamas de Olo",
			"União das freguesias de Constantim e Vale de Nogueiras",
			"União das freguesias de Mouçós e Lamares",
			"União das freguesias de Nogueira e Ermida",
			"União das freguesias de Pena, Quintã e Vila Cova",
			"União das freguesias de São Tomé do Castelo e Justes",
			"Vila Marim",
			"Vila Real"
		]
	},
	{
		nome: "Vila Real de Santo António",
		freguesias: [
			"Monte Gordo",
			"Vila Nova de Cacela",
			"Vila Real de Santo António"
		]
	},
	{
		nome: "Vila Velha de Ródão",
		freguesias: [
			"Fratel",
			"Perais",
			"Sarnadas de Ródão",
			"Vila Velha de Ródão"
		]
	},
	{
		nome: "Vila Verde",
		freguesias: [
			"Aboim da Nóbrega e Gondomar",
			"Atiães",
			"Cabanelas",
			"Cervães",
			"Coucieiro",
			"Dossãos",
			"Freiriz",
			"Gême",
			"Lage",
			"Lanhas",
			"Loureira",
			"Moure",
			"Oleiros",
			"Parada de Gatim",
			"Pico",
			"Ponte",
			"Prado (São Miguel)",
			"Sabariz",
			"Soutelo",
			"Turiz",
			"União das freguesias da Ribeira do Neiva",
			"União das freguesias de Carreiras (São Miguel) e Carreiras (Santiago)",
			"União das freguesias de Escariz (São Mamede) e Escariz (São Martinho)",
			"União das freguesias de Esqueiros, Nevogilde e Travassós",
			"União das freguesias de Marrancos e Arcozelo",
			"União das freguesias de Oriz (Santa Marinha) e Oriz (São Miguel)",
			"União das freguesias de Pico de Regalados, Gondiães e Mós",
			"União das freguesias de Sande, Vilarinho, Barros e Gomide",
			"União das freguesias de Valbom (São Pedro), Passô e Valbom (São Martinho)",
			"União das freguesias do Vade",
			"Valdreu",
			"Vila Verde e Barbudo",
			"Vila de Prado"
		]
	},
	{
		nome: "Vila Viçosa",
		freguesias: [
			"Bencatel",
			"Ciladas",
			"Nossa Senhora da Conceição e São Bartolomeu",
			"Pardais"
		]
	},
	{
		nome: "Vimioso",
		freguesias: [
			"Argozelo",
			"Carção",
			"Matela",
			"Pinelo",
			"Santulhão",
			"União das freguesias de Algoso, Campo de Víboras e Uva",
			"União das freguesias de Caçarelhos e Angueira",
			"União das freguesias de Vale de Frades e Avelanoso",
			"Vilar Seco",
			"Vimioso"
		]
	},
	{
		nome: "Vinhais",
		freguesias: [
			"Agrochão",
			"Candedo",
			"Celas",
			"Edral",
			"Edrosa",
			"Ervedosa",
			"Paçó",
			"Penhas Juntas",
			"Rebordelo",
			"Santalha",
			"Tuizelo",
			"União das freguesias de Curopos e Vale de Janeiro",
			"União das freguesias de Moimenta e Montouto",
			"União das freguesias de Nunes e Ousilhão",
			"União das freguesias de Quirás e Pinheiro Novo",
			"União das freguesias de Sobreiro de Baixo e Alvaredos",
			"União das freguesias de Soeira, Fresulfe e Mofreita",
			"União das freguesias de Travanca e Santa Cruz",
			"União das freguesias de Vilar de Lomba e São Jomil",
			"Vale das Fontes",
			"Vila Boa de Ousilhão",
			"Vila Verde",
			"Vilar Seco de Lomba",
			"Vilar de Ossos",
			"Vilar de Peregrinos",
			"Vinhais"
		]
	},
	{
		nome: "Viseu",
		freguesias: [
			"Abraveses",
			"Bodiosa",
			"Calde",
			"Campo",
			"Cavernães",
			"Cota",
			"Coutos de Viseu",
			"Fragosela",
			"Freguesia de Fail e Vila Chã de Sá",
			"Lordosa",
			"Mundão",
			"Orgens",
			"Povolide",
			"Ranhados",
			"Repeses e São Salvador",
			"Ribafeita",
			"Rio de Loba",
			"Santos Evos",
			"Silgueiros",
			"São Cipriano e Vil de Souto",
			"São João de Lourosa",
			"São Pedro de France",
			"União das freguesias de Barreiros e Cepões",
			"União das freguesias de Boa Aldeia, Farminhão e Torredeita",
			"Viseu"
		]
	},
	{
		nome: "Vizela",
		freguesias: [
			"Infias",
			"Santa Eulália",
			"União das freguesias de Caldas de Vizela (São Miguel e São João)",
			"União das freguesias de Tagilde e Vizela (São Paio)",
			"Vizela (Santo Adrião)"
		]
	},
	{
		nome: "Vouzela",
		freguesias: [
			"Alcofra",
			"Campia",
			"Fornelo do Monte",
			"Queirã",
			"São Miguel do Mato",
			"União das freguesias de Cambra e Carvalhal de Vermilhas",
			"União das freguesias de Fataunços e Figueiredo das Donas",
			"União das freguesias de Vouzela e Paços de Vilharigues",
			"Ventosa"
		]
	},
	{
		nome: "Águeda",
		freguesias: [
			"Aguada de Cima",
			"Fermentelos",
			"Macinhata do Vouga",
			"União das freguesias de Barrô e Aguada de Baixo",
			"União das freguesias de Belazaima do Chão, Castanheira do Vouga e Agadão",
			"União das freguesias de Recardães e Espinhel",
			"União das freguesias de Travassô e Óis da Ribeira",
			"União das freguesias de Trofa, Segadães e Lamas do Vouga",
			"União das freguesias de Águeda e Borralha",
			"União das freguesias do Préstimo e Macieira de Alcoba",
			"Valongo do Vouga"
		]
	},
	{
		nome: "Évora",
		freguesias: [
			"Canaviais",
			"Nossa Senhora da Graça do Divor",
			"Nossa Senhora de Machede",
			"São Bento do Mato",
			"São Miguel de Machede",
			"Torre de Coelheiros",
			"União das freguesias de Bacelo e Senhora da Saúde",
			"União das freguesias de Malagueira e Horta das Figueiras",
			"União das freguesias de Nossa Senhora da Tourega e Nossa Senhora de Guadalupe",
			"União das freguesias de São Manços e São Vicente do Pigeiro",
			"União das freguesias de São Sebastião da Giesteira e Nossa Senhora da Boa Fé",
			"União das freguesias de Évora (São Mamede, Sé, São Pedro e Santo Antão)"
		]
	},
	{
		nome: "Ílhavo",
		freguesias: [
			"Gafanha da Encarnação",
			"Gafanha da Nazaré",
			"Gafanha do Carmo",
			"Ílhavo (São Salvador)"
		]
	},
	{
		nome: "Óbidos",
		freguesias: [
			"A dos Negros",
			"Amoreira",
			"Gaeiras",
			"Olho Marinho",
			"Santa Maria, São Pedro e Sobral da Lagoa",
			"Usseira",
			"Vau"
		]
	}
];

export default { districts, parishes };
