import { Switch, Route, Redirect, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Input, DatePicker, Button, Select, Alert } from "antd";
import { Steps } from 'antd';
import axios from 'axios';
import moment from 'moment';
import logoUSF from '../../../assets/images/USF-AN.svg';
import logoUSFBackground from '../../../assets/images/logo-usg-login-criar-conta.svg';
import config from '../../../utils/config';
import endpoints from '../../../utils/endoinpts';
import axiosInit from '../../../utils/axiosInit';

import './Login.css';
import axiosApi from "../../../utils/axiosInit";

function Login({ handleLogin }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [formLogin] = Form.useForm();
  const navigate = useNavigate();

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!',
      placement: 'top',
    });
  }

  function handleSubmit() {
    setIsButtonLoading(true);
    let valuesLogin = formLogin.getFieldsValue(true);

    let data = {
      email: valuesLogin.email,
      password: valuesLogin.password,
    }

    axios.post(endpoints.auth.login, {
      data
    }).then((res) => {
      if (res.data.user_exists) {
        if (res.data.user_password) {
          notification.success({
            message: `Bem-vindo ${res.data.data.name}`,
            description: 'O seu login foi feito com sucesso.',
            placement: 'top',
            duration: 1.5
          });
          axiosApi.axiosInit(res.data.token).then(() => {
            setIsButtonLoading(false);
            localStorage.setItem('token', res.data.token);

            if (res.data.data.is_admin || res.data.data.is_viewer) {
              navigate('/admin');
            } else {
              navigate('/');
            }

            handleLogin(res.data.data)
          }).catch((err) => {
            setIsButtonLoading(false);
            console.log('something went wrong');
          })
        } else {
          notification.error({
            message: `Password errada`,
            description: "A password que está associada ao e-mail inserido não " +
              "corresponde àquela que colocou, por favor verifique se está a colocar a password correta",
            placement: 'top',
            duration: 1.5
          });
          setIsButtonLoading(false);
        }
      } else {
        notification.error({
          message: `Utilizador errado`,
          description: "Não existe nenhum utilizador com este e-mail. Verifique se está a colocar o e-mail correto.",
          placement: 'top',
          duration: 1.5
        });
        setIsButtonLoading(false);
      }
    }).catch((err) => {
      console.log(err);
      setIsButtonLoading(false);
    })
  }

  return (
    <Row className="d-flex jc-center ai-center">
      <Col xs={24} sm={24} md={13} lg={13} xl={13} >
        <Form
          name="basic"
          form={formLogin}
          className="mt-30"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleWarningMissingFields}
        >
          <h1 className="text-center mb-0" style={{ color: '#4A4C4D' }}>Login & Registo</h1>
          <p className="text-center" style={{ marginTop: '5px', color: '#BDBCBC' }}>Coloque abaixo o seu email e password</p>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input type="email" size="large" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
          <p className="grey">Esqueceu-se da <a className="grey underline" onClick={() => navigate('/resetPasswordEmail')}>password</a>?</p>
          <Form.Item noStyle>
            <Button
              className="w-100"
              type="primary"
              size="large"
              loading={isButtonLoading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>
          <p className="grey">Ainda não tenho conta. <a className="grey underline" onClick={() => navigate('/register')}>Registe-se aqui</a></p>
        </Form>
      </Col>
    </Row>
  );
}

export default Login;
