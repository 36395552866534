import { Spin } from 'antd';
import logoUSF from '../../assets/images/USF-AN.svg';

export default function Loading({ isLoading }) {
  return (
    <div id="loading-div"
      style={{ backgroundColor: '#fff', opacity: isLoading ? '1' : '0', pointerEvents: isLoading ? 'auto' : 'none' }}>
      <div>
        <img src={logoUSF} />
        <Spin spinning={isLoading}></Spin>
      </div>
    </div>
  )
}