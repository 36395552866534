import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation
} from "react-router-dom";
import { ConfigProvider, Row, Col, Spin } from 'antd';
import axios from 'axios';
import ptPT from 'antd/locale/pt_BR';

import Home from './pages/app/Home/Home';
import Admin from './pages/app/Admin/Admin';
import Settings from './pages/app/Settings/Settings';
import Profile from './pages/app/Profile/Profile';
import NotFound from './pages/app/NotFound/NotFound';
import FormDepartment from './pages/app/FormDepartment/FormDepartment';
import EventDetails from './pages/app/EventDetails/EventDetails';

import Login from './pages/auth/Login/Login';
import Register from './pages/auth/Register/Register';
import ResetPasswordEmail from "./pages/auth/ResetPasswordEmail/ResetPasswordEmail";
import ResetPassword from "./pages/auth/ResetPassword/ResetPassword";

import Guest from "./pages/app/Guest/Guest";

import config from './utils/config';
import axiosInit from "./utils/axiosInit";
import logoUSF from './assets/images/USF-AN.svg';
import endpoints from "./utils/endoinpts";
import GuestEventDetails from "./pages/app/GuestEventDetails/GuestEventDetails";
import Loading from "./layout/Loading/Loading";
import dayjs from "dayjs";
import MainLayout from "./layout/MainLayout/MainLayout";
import AdminAuth from "./layout/AdminAuth/AdminAuth";
import AuthLayout from "./layout/AuthLayout/AuthLayout";
import axiosApi from "./utils/axiosInit";
import GuestLayout from "./layout/GuestLayout/GuestLayout";
import UserAuth from "./layout/UserAuth/UserAuth";
import TermsAndConditionsEvents from "./pages/app/TermsAndConditionsEvents/TermsAndConditionsEvents";

axiosApi.axiosCreate();

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let shoppingCart = localStorage.getItem('shopping_cart');
    if (!shoppingCart) {
      localStorage.setItem('shopping_cart', JSON.stringify({ voucher: {}, items: [], }));
    }
  }, []);

  function handleLogin(userData) {
    setIsLoggedIn(true);
    setUser(userData);
  }

  return (
    <ConfigProvider
      locale={ptPT}
      theme={{
        token: {
          fontFamily: 'Poppins',
          colorPrimary: '#73A91B'
        },
      }}
    >
      <Routes>
        <Route element={<MainLayout />}>
          <Route element={<AdminAuth />}>
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/adminProfile" element={<Profile />} />
          </Route>
          <Route element={<UserAuth />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/formulario" element={<FormDepartment />} />
            <Route exact path="/eventDetails/:id" element={<EventDetails />} />
          </Route>
        </Route>


        <Route element={<AuthLayout />}>
          <Route exact path="/login" element={<Login handleLogin={handleLogin} />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/resetPasswordEmail" element={<ResetPasswordEmail />} />
          <Route exact path="/resetPassword" element={<ResetPassword />} />
        </Route>


        <Route element={<GuestLayout />}>
          <Route exact path="/guest" element={<Guest />} />
          <Route exact path="/guestEventDetails/:id" element={<GuestEventDetails />} />
        </Route>
        <Route path='/termos-e-condicoes-eventos' element={<TermsAndConditionsEvents />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </ConfigProvider >
  );
}

export default App;